import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { ActiveFields, ActiveFields_fields_rotationAreas_plots, CropDetails, CropTimingsForCrop_cropTimings, CultivationDetails, CultivationDetails_cultivation, CultivationsForPlot_cultivations, FieldType, GetPlotInfo, GetPlotInfo_plot, PlantOrSeed, UpsertCultivation21Variables, UpsertCultivationDenseVariables } from "../../../../../__generated__/types";
import { SEASONS, usePlanning } from "../../../planning21-context";
import { chain, compact, get, last, map, omit, uniq, without } from 'lodash';
import { asIso8601, momentFromIso8601, momentFromYyyyWE } from "../../../../../shared/utils/date.utils";
import { SelectButton } from "primereact/selectbutton";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import moment, { Moment } from "moment";
import AmountSelection from "../planning21-add-cultivation-amount";
import useDeleteCultivationMutation from "../../../../../hooks/useDeleteCultivationMutation";
import { ActiveFieldsQuery, CultivationDetailsQuery } from "./AddCultivation.gql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { PlotQuery } from "../../../planning21.gql";
import LocationDropDown from "./components/LocationDropDown/LocationDropDown";
import { Checkbox } from "primereact/checkbox";
import { ConfirmDialog } from "primereact/confirmdialog";
import { calculateHarvestWindow } from "../../planning21-mutations";
import CropTimings from "../../../crop-management/crop-details/components/CropTimings/CropTimings";
import CropDropDown from "./components/CropDropDown/CropDropDown";
import VarietiesSelection from './components/VarietiesSelection/VarietiesSelection';
import useUpsertCultivationMutation from "../../../../../hooks/useUpsertCultivationMutation";
import { calculateVarietiesCreate, calculateVarietiesUpdate } from "../../../../../utils/varieties.util";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import CultivationDropDown from "./components/CultivationDropDown/CultivationDropDown";
import EditableValue from './components/EditableValue';
import { useUserObject } from "../../../../../context/UserContext";
import { nameForPlot } from "../../../../../utils/plot.util";
import { humanReadableCultivationType } from "../../../../../shared/utils/domain/cultivation.utils";
import { CropDetailsQuery } from "../../../crop-management/CropManagement.gql";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import config from "../../../../../config";
import { formatAbs, formatDoubleDigit } from "../../../../../shared/utils/currency.utils";
import { plantAmount } from "../../../../../utils/planting.util";
import { TabMenu } from "primereact/tabmenu";
import CropDetailsView from "../../../crop-management/crop-details/CropDetails";
import QuantityValues from "./components/QuantityValues";
import Remarks from './components/Remarks';

interface AddCultivationProps {
  plotId?: string
  cultivationId?: string,
  crop?: { id: string };
  onClose: () => void;
  cultivations?: CultivationsForPlot_cultivations[] | undefined;
}

export const CultivationDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: start;
  grid-gap: 8px;

  > span {
    justify-self: right;
  }

  > div {
    width: 100%;
    display: grid;
    //grid-template-columns: 1fr max-content;
    //align-items: center;
  }
`;

enum Mode {
  EDIT = 'edit',
  ADD = 'add',
  COPY = 'copy'
}

export const CultivationField = ({children, label}: { label: string, children?: React.ReactNode }) =>
  <>
    <span>{label}</span>
    <div>
      {children}
    </div>
  </>;

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  margin-top: 16px;

  > div {
    button {
      margin-right: 4px;
    }
  }
`;

const modeTexts = {
  add: {
    title: 'Teelt toevoegen',
    saveButton: 'Toevoegen',
  },
  edit: {
    title: 'Teelt wijzigen',
    saveButton: 'Wijzigen',
  },
  copy: {
    title: 'Gekopieerde teelt toevoegen',
    saveButton: 'Kopieer naar volgend seizoen',
  }
};

export const calculateCultivationDates = (year: number, upsertingCultivation: {
  startDate?: string,
  harvestEndDate?: string,
}) => {
  const isoWeek = upsertingCultivation.startDate && momentFromIso8601(upsertingCultivation.startDate).isoWeek();
  const startDateMoment = momentFromYyyyWE(`${year}-${isoWeek}`).startOf('isoWeek');
  const startDate = asIso8601(startDateMoment);
  const isoWeekHarvestEnd = upsertingCultivation.harvestEndDate && momentFromIso8601(upsertingCultivation.harvestEndDate).isoWeek();
  const harvestEndDate = upsertingCultivation.harvestEndDate
    ? asIso8601(momentFromYyyyWE(`${year}-${isoWeekHarvestEnd}`).endOf('isoWeek'))
    : undefined;

  return {startDate, harvestEndDate, season: SEASONS[momentFromIso8601(startDate).year()]};
};

export interface UpsertVariables extends Partial<UpsertCultivation21Variables> {
}

interface PlotOption extends ActiveFields_fields_rotationAreas_plots {
  type: FieldType;
}

const AddCultivation = (props: AddCultivationProps) => {
  const {activeFarm} = useUserObject();
  const {years} = usePlanning();
  const [deleteCultivationMutation] = useDeleteCultivationMutation();
  // const {data: plotData} = useQuery<GetPlotInfo>(PlotQuery, {
  //   variables: {
  //     id: props.plotId || ""
  //   }
  // });

  const [getPlotInfo, {data: plotData}] = useLazyQuery<GetPlotInfo>(PlotQuery,);

  const {data: cultivationData} = useQuery<CultivationDetails>(CultivationDetailsQuery, {
    variables: {
      id: props.cultivationId || ""
    }
  });

  const [upsertCultivationMutation] = useUpsertCultivationMutation();

  // is this still needed, it exists on upsertingCultivation?
  const [selectedCropTimingId, setSelectedCropTimingId] = useState<string>();
  const [plantOrSeed, setPlantOrSeed] = useState<PlantOrSeed>(PlantOrSeed.PLANT);
  const [mode, setMode] = useState<Mode>(Mode.ADD);

  const [listingYear, setListingYear] = useState<number>(config.currentPlanningYear);
  const [plot, setPlot] = useState<GetPlotInfo_plot>();
  const [cultivation, setCultivation] = useState<CultivationDetails_cultivation>();
  const {data: fieldsData} = useQuery<ActiveFields>(ActiveFieldsQuery, {variables: {farm: activeFarm?.id}});
  const [plotOptions, setPlotOptions] = useState<PlotOption[]>([]);
  const [confirmPlotChangeTo, setConfirmPlotChangeTo] = useState<string>();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [selectedVarieties, setSelectedVarieties] = useState<{
    varietyId: string,
    percentage: number,
  }[]>([]);

  const [currentCropId, setCurrentCropId] = useState<string>();

  const DEFAULT_CULTIVATION = {
    length: plot ? plot.rotationArea.field.bedLength : undefined,
    plotId: props.plotId,
    //cropId: props.crop ? props.crop.id : 'ck4e4q18o00pcd962nwgry2tb',
    cropId: props.crop ? props.crop.id : undefined,
    transplant: true,
    pinned: false,
  };

  const [upsertingCultivation, setUpsertingCultivation] = useState<UpsertVariables>(DEFAULT_CULTIVATION);

  useEffect(() => {
    setPlotOptions(!fieldsData ? [] : chain(fieldsData.fields)
      .compact()
      .map(field => {
          const rotationAreas = compact(field.rotationAreas);
          return rotationAreas
            .map(r =>
              compact(r.plots).map(plot => {
                return ({
                  ...plot,
                  type: field.type,
                });
              })
            );
        }
      )
      .flattenDeep()
      .value());
  }, [fieldsData]);

  useEffect(() => {
    if (cultivationData && cultivationData.cultivation) {
      setCultivation(cultivationData.cultivation);
    }
  }, [cultivationData]);

  useEffect(() => {
    if (plotData && plotData.plot) {
      setPlot(plotData.plot);
    }
  }, [plotData]);

  useEffect(() => {
    if (cultivation && cultivation.startDate) {
      setListingYear(momentFromIso8601(cultivation.startDate).year())
    }
  }, [cultivation]);

  const [getCropDetails, {data, loading}] = useLazyQuery<CropDetails>(CropDetailsQuery);
  useEffect(() => {
    if (upsertingCultivation.cropId) {
      setCurrentCropId(upsertingCultivation.cropId);
    }
    getCropDetails({variables: {id: upsertingCultivation.cropId || ""}});
  }, [upsertingCultivation.cropId]);

  useEffect(() => {
    if (data?.crop && selectedVarieties.length === 0) {

      let cropVarieties = compact(data.crop.varieties);
      // suggest the only variety if there is one only of De Koster
      let filteredVarieties = cropVarieties.filter(variety => variety.supplier?.name === "De Koster");

      if (filteredVarieties.length === 1) {
        setSelectedVarieties([{
          varietyId: filteredVarieties[0].id,
          percentage: 1,
        }]);
      }

      console.log("Suggestion:");
    }
  }, [data]);


  useEffect(() => {
    let upsertCultivation: UpsertVariables = {...upsertingCultivation};
    if (cultivation) {
      setMode(Mode.EDIT);
      const actualCultivation = cultivation;
      const plantSchemaValue = () => {
        if (actualCultivation.plantSchema) {
          const connect = {id: actualCultivation.plantSchema.id};
          return {
            connect
          }
        } else {
          return {};
        }
      };

      upsertCultivation = {
        ...upsertCultivation,
        cultivationId: cultivation.id,
        cropId: actualCultivation.crop.id,
        pinned: actualCultivation.pinned,
        cropTimingId: actualCultivation.cropTiming.id,
        startDate: actualCultivation.startDate,
        season: actualCultivation.season || SEASONS[momentFromIso8601(actualCultivation.startDate).year()],
        harvestEndDate: actualCultivation.harvestEndDate,
        transplant: actualCultivation.transplant,
        interPlanting: !!actualCultivation.interPlanting,
        remark: actualCultivation.remark,
        length: actualCultivation.length,
        plantSchemaUpdate: plantSchemaValue(),
        parentId: actualCultivation.parent?.id,
      };

      if (actualCultivation.varieties) {
        setSelectedVarieties(map(actualCultivation.varieties, variety => ({
          varietyId: variety.variety.id,
          percentage: variety.percentage,
        })));
      }

      if (actualCultivation.notes) {
        upsertCultivation = {
          ...upsertCultivation,
          notesUpdate: {
            upsert: actualCultivation.notes.map(note => {
              return {
                update: {...note},
                create: {...note, id: null},
                where: {id: note.id},
              };
            })
          }
        }
      }

      setCurrentCropId(actualCultivation.crop.id);

      setSelectedCropTimingId(actualCultivation.cropTiming.id);
      setPlantOrSeed(actualCultivation.cropTiming.type);
      // if(actualCultivation.plot.id) {
      getPlotInfo({variables: {id: actualCultivation.plot.id}});
      // }
    }
    setUpsertingCultivation(upsertCultivation);
  }, [cultivation]);

  const updateCultivation = (update: Partial<UpsertVariables>) => {
    const updatedCultivation = {...upsertingCultivation, ...update};
    if (update.plotId !== plot?.id && update.plotId) {
      getPlotInfo({variables: {id: update.plotId}});
    }
    setUpsertingCultivation(updatedCultivation);
  };

  const activePlot = plotOptions.find(plot => upsertingCultivation.plotId === plot.id);

  const requestPlotChange = (plotId: string) => {
    if (upsertingCultivation.pinned) {
      setConfirmPlotChangeTo(plotId);
    } else {
      updateCultivation({plotId});
    }
  };

  let onSave = () => {

    let cultivationId = (cultivation && upsertingCultivation.cultivationId === cultivation.id) ? cultivation.id : 'new';
    // let tasksUpdate, tasksCreate;
    // if (cultivationId === 'new') {
    //   tasksCreate = calculateTasksCreate(selectedCropTiming, upsertingCultivation.startDate, upsertingCultivation.harvestEndDate);
    // } else {
    //   tasksUpdate = calculateTasksUpdate(selectedCropTiming, cultivation, upsertingCultivation.startDate);
    // }

    let varietiesUpdate, varietiesCreate;
    if (cultivationId === 'new') {
      varietiesCreate = calculateVarietiesCreate(selectedVarieties);
    } else {
      varietiesUpdate = calculateVarietiesUpdate(selectedVarieties, compact(cultivation?.varieties).map(v => v.id));
    }

    let upsertingCultivationFields: any = upsertingCultivation as UpsertCultivation21Variables;
    upsertingCultivationFields = omit(upsertingCultivationFields,
      'notesUpdate', 'notesCreate',
      'plotId', 'cropId', 'cropTimingId',
      'plantSchemaUpdate', 'plantSchemaCreate',
      'cultivationId',
      'parentId',
    )


    let parentUpdate;
    if (upsertingCultivation.parentId) {
      parentUpdate = {
        connect: {id: upsertingCultivation.parentId}
      };
    } else if (cultivationData?.cultivation?.parent !== null) {
      parentUpdate = {
        disconnect: true,
      };
    }

    let variablesDense: UpsertCultivationDenseVariables = {
      cultivationId: cultivationId,
      update: {
        ...upsertingCultivationFields,
        varieties: varietiesUpdate,
        plantSchema: upsertingCultivation.plantSchemaUpdate,
        plot: {
          connect: {id: upsertingCultivation.plotId}
        },
        cropTiming: {
          connect: {id: upsertingCultivation.cropTimingId}
        },
        crop: {
          connect: {id: upsertingCultivation.cropId}
        },
        parent: parentUpdate,
      },
      create: {
        ...upsertingCultivationFields,
        varieties: varietiesCreate,
        plantSchema: upsertingCultivation.plantSchemaCreate,
        plot: {
          connect: {id: upsertingCultivation.plotId}
        },
        cropTiming: {
          connect: {id: upsertingCultivation.cropTimingId}
        },
        crop: {
          connect: {id: upsertingCultivation.cropId}
        },
        parent: omit(parentUpdate, 'disconnect')
      },
    };


    upsertCultivationMutation({
      variables: variablesDense,
      refetchQueries: "active",
    }).then(props.onClose);
  };

  // const x = !fieldsData ? [] : chain(fieldsData.fields)
  //   .compact()
  //   .sortBy(['type'], ['DESC'])
  //   .map(field => {
  //       const rotationAreas = compact(field.rotationAreas);
  //       return rotationAreas
  //         .map(r => {
  //             let rotationSchema = last(r.rotationSchema);
  //             if (props.season) {
  //               rotationSchema = r.rotationSchema?.find(rs => rs.season === props.season);
  //             }
  //
  //             return compact(r.plots).map(plot => {
  //               return ({
  //                 ...plot,
  //                 name: `${field.name}-${r.number} (${rotationSchema?.rotationGroup.name})`,
  //               });
  //             });
  //           }
  //         );
  //     }
  //   )
  //   .flattenDeep()
  //   .value();

  //const plot = x.find(p => p.id === upsertingCultivation.plotId);

  const items = [
    {label: 'Teelten', icon: 'pi pi-fw pi-home', className: 'ml-6'},
    {label: 'Gewasinfo', icon: 'pi pi-fw pi-calendar'},
  ];

  const disableSubmit = (!upsertingCultivation.length || upsertingCultivation.length === 0)
    || upsertingCultivation.cropId === undefined;
  return <Sidebar
    visible={true}
    blockScroll={false}
    header={<div className="w-full flex align-items-center justify-content-between">
      <div className="py-1 font-medium text-lg">{modeTexts[mode].title}</div>
      <div className="mr-2">
        <Button
          label={'Annuleren'}
          className={'p-button-text p-button-secondary'}
          onClick={props.onClose}
        />

        <Button
          disabled={disableSubmit}
          label={modeTexts[mode].saveButton}
          onClick={onSave}
        />
      </div>
    </div>}
    showCloseIcon={false}
    position="bottom"
    className="w-full h-full sm:h-75vh md:h-50vh"
    onHide={() => {
      props.onClose();
    }}
  >
    <div className="mx-auto p-2" style={{maxWidth: '1600px'}}>
      <TabMenu className="pt-3 pb-2" model={items} activeIndex={activeTabIndex}
               onTabChange={(e) => {
                 setActiveTabIndex(e.index);
               }}/>
      {props.crop && activeTabIndex === 1 && <div>
        <CropDetailsView cropId={props.crop.id}/>
      </div>}

      {activeTabIndex === 0 && <>
        <ConfirmDialog visible={confirmPlotChangeTo !== undefined}
                       onHide={() => setConfirmPlotChangeTo(undefined)}
                       message={`Ben je zeker dat je deze teelt wil verplaatsen?`}
                       header="Bevestig verplaatsing" icon="pi pi-exclamation-triangle"
                       acceptLabel={'Ja'}
                       rejectLabel={'Nee'}
                       accept={() => {
                         updateCultivation({plotId: confirmPlotChangeTo});
                       }}/>

        <div className="grid">
          <div className="col-12 lg:col-fixed lg:w-14rem">
            <div className="formgrid grid align-items-stretch p-1">
              <EditableValue
                label="Seizoen"
                classname="col-3 sm:col-3 md:col-3 lg:col-12"
                value={listingYear ? `${listingYear}` : null}>
                {(leaveEditMode: () => void) => (
                  <Dropdown
                    optionLabel={'name'}
                    optionValue={'id'}
                    value={listingYear}
                    options={years.map(y => ({name: y, id: y}))}
                    onChange={(e) => {
                      setListingYear(e.value);
                      if (upsertingCultivation.startDate) {
                        updateCultivation(calculateCultivationDates(e.value, upsertingCultivation));
                      }
                      leaveEditMode();
                    }}
                  />)}
              </EditableValue>

              <EditableValue
                label="locatie"
                classname="col-4 sm:col-4 md:col-4 lg:col-12"
                value={plot ? nameForPlot(plot) : null}>
                {(leaveEditMode: () => void) => (
                  <>
                    <LocationDropDown
                      value={plot?.id}
                      season={upsertingCultivation.season}
                      onChange={(plotId) => {
                        requestPlotChange(plotId);
                        leaveEditMode();
                        //updateCultivation({plotId});
                      }}
                    />
                  </>
                )}
              </EditableValue>

              <div
                className="flex align-items-center hover:bg-primary-900 border-1 border-primary-900 col-5 sm:col-5 md:col-5 lg:col-12 p-1">
                <Checkbox
                  checked={upsertingCultivation.pinned || false}
                  inputId={'pinned'}
                  onChange={e => updateCultivation({pinned: e.checked})}
                />
                <label className="pl-1 text-xs uppercase text-color-secondary" htmlFor={'pinned'}>Niet
                  verplaatsen</label>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col">
            <div className="formgrid grid p-1">
              <EditableValue
                label="Gewas"
                classname="col-6 sm:col-4 md:col-3"
                value={data?.crop?.name}>
                {leaveEditMode => (
                  <div>
                    <CropDropDown
                      value={upsertingCultivation.cropId}
                      onChange={(cropId) => {
                        if (cropId !== currentCropId) {
                          setSelectedVarieties([]);
                          setCurrentCropId(cropId);
                          updateCultivation({cropId});
                          leaveEditMode();
                        }
                      }}
                    />
                    <div className="flex align-items-center">
                      <Checkbox
                        checked={upsertingCultivation.interPlanting || false}
                        inputId={'interPlanting'}
                        onChange={e => {
                          let update: Partial<UpsertVariables> = {interPlanting: e.checked};
                          if (!e.value) {
                            update = {...update, parentId: null};
                          }
                          updateCultivation(update);
                        }}
                      />
                      <label className="pl-1 text-xs uppercase text-color-secondary"
                             htmlFor={'interPlanting'}>Combinatieteelt</label>
                    </div>

                    {upsertingCultivation.interPlanting && <CultivationDropDown
                      options={props.cultivations}
                      onChange={(value) => {
                        updateCultivation({parentId: value});
                      }}
                      value={upsertingCultivation.parentId}
                    />}
                  </div>
                )}
              </EditableValue>

              <EditableValue
                label="Variëteit"
                classname="col-6 sm:col-6 md:col-4 lg:col-5"
                value={selectedVarieties.length === 0 ? null : () => {
                  let varietyIds = map(selectedVarieties, 'varietyId');
                  let cultivationCropPlantSchemaId = get(upsertingCultivation, 'plantSchemaUpdate.connect.id');
                  let plantSchema = compact(data?.crop?.plantSchemas)
                    .find(plantSchema => cultivationCropPlantSchemaId
                      ? cultivationCropPlantSchemaId === plantSchema.id
                      : plantSchema.default);
                  return <div className="w-full flex flex-column">
                    {compact(data?.crop?.varieties)
                      .filter(variety => varietyIds.includes(variety.id))
                      .map(variety => {
                          const percentage = selectedVarieties.find(v => v.varietyId === variety.id)?.percentage;
                          return <div key={`variety-${variety.id}`}
                                      className="flex flex-row align-items-center flex-wrap">
                            <div
                              className="py-1 mr-1 font-bold text-overflow-ellipsis overflow-hidden white-space-nowrap">
                              {(variety.alternativeCropName
                                ? variety.alternativeCropName
                                : variety?.name)}
                            </div>
                            <div
                              className="flex-grow-1 italic text-overflow-ellipsis overflow-hidden white-space-nowrap text-xs text-color-secondary">
                              {variety.alternativeCropName ? variety?.name : ''}
                            </div>
                            <div className="ml-1 flex flex-column justify-content-end">
                              <div>{
                                upsertingCultivation.length
                                && plot?.rotationArea.field.bedWidth
                                && !!percentage
                                && formatAbs(
                                  plantAmount(plantSchema?.plantSchema,
                                    upsertingCultivation.length,
                                    plot.rotationArea.field.bedWidth,
                                    // plantSchemaForParent?.plantSchema
                                    undefined
                                  ) * percentage) + 'pl'
                              }
                              </div>
                              {!!percentage &&
                                <div
                                  className="text-xs text-color-secondary">{formatDoubleDigit(percentage * 100)}%</div>}
                            </div>
                          </div>;
                        }
                      )}

                  </div>;
                }}
              >
                {leaveEditMode => <VarietiesSelection
                  parentId={upsertingCultivation.parentId}
                  length={upsertingCultivation.length || get(plot, 'field.bedLength', 0)}
                  bedWidth={plot?.rotationArea.field.bedWidth || 0}
                  cultivationCropPlantSchemaId={get(upsertingCultivation, 'plantSchemaUpdate.connect.id')}
                  cropId={currentCropId}
                  selectedVarieties={selectedVarieties}
                  onChangeVarietyPercentage={((varietyId, percentage) => {
                      setSelectedVarieties(value => {
                        return value.map(variety => {
                          if (variety.varietyId === varietyId) {
                            return {...variety, percentage};
                          }
                          return variety;
                        });
                      });
                      leaveEditMode();
                    }
                  )}
                  onAddVariety={(varietyId => {
                      setSelectedVarieties(value => {
                        const varietyIds = uniq([...value.map(v => v.varietyId), varietyId]);
                        return varietyIds.map(varietyId => ({
                          varietyId,
                          percentage: 1 / varietyIds.length,
                        }));
                      });
                      leaveEditMode();
                    }
                  )}
                  onDeleteVariety={(varietyId => {
                      setSelectedVarieties(value => {
                        const varietyIds = without(value.map(v => v.varietyId), varietyId);
                        return varietyIds.map(varietyId => ({varietyId, percentage: 1 / varietyIds.length}));
                      });
                      leaveEditMode();
                    }
                  )}
                />}
              </EditableValue>

              <EditableValue
                label="Combinatieteelt"
                classname="col-6 sm:col-6 md:col-4"
                value={upsertingCultivation.interPlanting ? 'Ja' : 'Nee'}
              >
                {leaveEditMode => <div className="flex align-items-center">
                  <Checkbox
                    checked={upsertingCultivation.interPlanting || false}
                    inputId={'interPlanting'}
                    onChange={e => {
                      updateCultivation({interPlanting: e.checked});
                      leaveEditMode();
                    }}
                  />
                  <label className="pl-1 text-xs uppercase text-color-secondary"
                         htmlFor={'interPlanting'}>Combinatieteelt</label>
                </div>}
              </EditableValue>
            </div>
            <div className="formgrid grid p-1">
              <EditableValue
                label={"Planten/Zaaien"}
                classname="col-12 sm:col-4 md:col-3"
                value={<div>
                  <div>{humanReadableCultivationType(plantOrSeed)}</div>
                  {!upsertingCultivation.transplant && <div className="text-xs">(eigen opkweek)</div>}
                </div>}>
                {leaveEditMode => <>
                  <SelectButton
                    value={plantOrSeed}
                    options={[{label: 'Planten', value: PlantOrSeed.PLANT}, {label: 'Zaaien', value: PlantOrSeed.SEED}]}
                    onChange={(e) => {
                      setPlantOrSeed(e.value);
                      if (e.value === PlantOrSeed.SEED) {
                        setUpsertingCultivation(value => ({
                          ...value,
                          transplant: true,
                        }))
                      }
                      leaveEditMode();
                    }}/>
                  {plantOrSeed === PlantOrSeed.PLANT &&
                    <div className="pt-1 md:pl-1 md:pt-0 flex align-items-center">
                      <Checkbox
                        checked={!upsertingCultivation.transplant}
                        onChange={(e) => updateCultivation({transplant: !e.checked})}/>
                      <label className='text-xs uppercase text-color-secondary pl-1'>Eigen opkweek</label>
                    </div>
                  }
                </>}
              </EditableValue>
              <EditableValue
                label={"Hoeveelheden"}
                classname="col-12 sm:col-4 md:col-4"
                value={() => {
                  const defaultPlantSchemaId = compact(data?.crop?.plantSchemas).find(p => p.default)?.id;
                  return <QuantityValues
                    selectedVarieties={selectedVarieties.map(selectedVariety => ({
                      ...selectedVariety,
                      variety: compact(data?.crop?.varieties).find(v => v.id === selectedVariety.varietyId),
                    }))}
                    cultivationType={plantOrSeed}
                    transplant={upsertingCultivation.transplant}
                    parentId={upsertingCultivation.parentId}
                    cropId={currentCropId}
                    length={upsertingCultivation.length || get(plot, 'field.bedLength', 0)}
                    bedWidth={plot?.rotationArea.field.bedWidth || 0}
                    cultivationCropPlantSchemaId={get(upsertingCultivation, 'plantSchemaUpdate.connect.id') || defaultPlantSchemaId}
                  />;
                }
                }>
                {leaveEditMode => {
                  console.log(plot);
                  return <>
                    <AmountSelection
                      cultivationCropPlantSchemaId={get(upsertingCultivation, 'plantSchemaUpdate.connect.id')}
                      parentId={upsertingCultivation.parentId}
                      cropId={currentCropId}
                      onChange={(length) => {
                        updateCultivation({length});
                        leaveEditMode();
                      }}
                      onCropPlantSchemaChange={cropPlantSchemaId => {
                        if (cropPlantSchemaId) {
                          updateCultivation({
                            plantSchemaUpdate: {
                              connect: {id: cropPlantSchemaId}
                            }
                          })
                        } else {
                          updateCultivation({
                            plantSchemaUpdate: {
                              disconnect: true
                            }
                          })
                        }

                      }}
                      bedLength={get(plot, 'rotationArea.field.bedLength', 0)}
                      length={upsertingCultivation.length || get(plot, 'rotationArea.field.bedLength', 0)}
                      bedWidth={plot?.rotationArea.field.bedWidth || 0}
                    />
                  </>;
                }}
              </EditableValue>
              <EditableValue
                label={"Notities"}
                classname="col-12 sm:col-4 md:col-4"
                value={upsertingCultivation.remark || ''}>
                {leaveEditMode => <>
                  <Remarks
                    value={upsertingCultivation.remark || ''}
                    onChange={(value) => {
                      leaveEditMode();
                      updateCultivation({remark: value});
                    }}/>

                  {/*<InputTextarea*/}
                  {/*  style={{width: '100%'}}*/}
                  {/*  onChange={(e: any) => {*/}
                  {/*    leaveEditMode();*/}
                  {/*    updateCultivation({remark: e.target.value});*/}
                  {/*  }}*/}
                  {/*/>*/}


                  {/*<CultivationNotes*/}
                  {/*  upsertCultivation={upsertingCultivation}*/}
                  {/*  notesUpdate={upsertingCultivation.notesUpdate}*/}
                  {/*/>*/}
                </>}
              </EditableValue>
            </div>
          </div>
        </div>
        {/*<div className="p-2">
          <CultivationDetailsWrapper>
            <AmountSelection
              parentId={upsertingCultivation.parentId}
              cropId={currentCropId}
              onChange={(length) => {
                updateCultivation({length});
              }}
              onCropPlantSchemaChange={cropPlantSchemaId => {
                if (cropPlantSchemaId) {
                  updateCultivation({
                    plantSchemaUpdate: {
                      connect: {id: cropPlantSchemaId}
                    }
                  })
                } else {
                  updateCultivation({
                    plantSchemaUpdate: {
                      disconnect: true
                    }
                  })
                }

              }}
              length={upsertingCultivation.length || get(plot, 'field.bedLength', 0)}
              bedWidth={plot?.rotationArea.field.bedWidth || 0}
              cultivationCropPlantSchemaId={get(upsertingCultivation, 'plantSchemaUpdate.connect.id')}
            />

          </CultivationDetailsWrapper>
        </div>*/}

        <div>
          {currentCropId &&
            <CropTimings
              cropId={currentCropId}
              years={[listingYear, listingYear + 1]}
              year={listingYear}
              allowWeekSelection
              hideFilters={true}
              filters={{
                type: activePlot ? activePlot.type : undefined,
                plantOrSeed,
              }}
              showPreseeding={plantOrSeed === PlantOrSeed.PLANT && !upsertingCultivation.transplant}
              value={upsertingCultivation.cropTimingId}
              weekValues={
                {
                  startWeek: upsertingCultivation.startDate ? momentFromIso8601(upsertingCultivation.startDate) : undefined,
                  harvestEndWeek: upsertingCultivation.harvestEndDate ? momentFromIso8601(upsertingCultivation.harvestEndDate) : undefined
                }}
              onStartWeekClick={(cropTimingId, cropTimings: CropTimingsForCrop_cropTimings[], startWeek: Moment) => {
                const cropTiming = cropTimings.find(c => c.id === cropTimingId);
                if (cropTiming) {

                  let harvestWindow = calculateHarvestWindow(cropTiming, asIso8601(startWeek), undefined, listingYear);
                  let harvestEndDate =
                    (
                      momentFromIso8601(upsertingCultivation.harvestEndDate).isBetween(harvestWindow.firstDay, harvestWindow.lastDay, undefined, "[]")
                      && upsertingCultivation.cropTimingId === cropTiming.id
                    )

                      ? asIso8601(momentFromIso8601(upsertingCultivation.harvestEndDate).endOf('isoWeek'))
                      : asIso8601(harvestWindow.lastDay);

                  const startDate = asIso8601(startWeek);
                  updateCultivation({
                    startDate,
                    harvestEndDate,
                    cropTimingId: cropTimingId,
                    season: SEASONS[momentFromIso8601(startDate).year()],
                  });
                  setSelectedCropTimingId(cropTimingId);
                }
              }}
              onHarvestEndWeekClick={(cropTimingId, cropTimings: CropTimingsForCrop_cropTimings[], harvestEndWeek) => {

                const cropTiming = cropTimings.find(c => c.id === cropTimingId);
                if (cropTiming) {
                  const startDate = cropTimingId !== upsertingCultivation.cropTimingId
                    ? moment(listingYear, 'YYYY').startOf('isoWeek').add(cropTiming.startWeekNumberFrom, 'w')
                    : upsertingCultivation.startDate;

                  updateCultivation({
                    harvestEndDate: harvestEndWeek.endOf('isoWeek'),
                    startDate,
                    cropTimingId: cropTimingId,
                    season: SEASONS[momentFromIso8601(startDate).year()],
                  });
                  setSelectedCropTimingId(cropTimingId);
                }
              }}
              onChange={(cropTiming) => {

                if (cropTiming) {
                  const startOfYear = moment(listingYear, 'YYYY').startOf('isoWeek');
                  const startDate = startOfYear.clone().add(cropTiming.startWeekNumberFrom, 'w');
                  const harvestEndDate = startOfYear.clone().add(cropTiming.endWeekNumberUntil, 'w').endOf('isoWeek');

                  updateCultivation({
                    startDate: asIso8601(startDate),
                    cropTimingId: cropTiming.id,
                    harvestEndDate: asIso8601(harvestEndDate),
                    season: SEASONS[startDate.year()],
                  });
                }

                setSelectedCropTimingId(cropTiming?.id);
              }}
            />}
        </div>
        <Actions>
          <div>
            {mode === Mode.EDIT &&
              <>
                <ConfirmPopup/>
                <Button
                  icon="pi pi-trash"
                  iconPos="right"
                  className={'p-button-danger p-button-rounded p-button-outlined'}
                  onClick={(event) => {
                    confirmPopup({
                      target: event.currentTarget,
                      message: 'Ben je zeker dat je deze teelt wil verwijderen',
                      icon: 'pi pi-exclamation-triangle',
                      acceptClassName: 'p-button-danger',
                      acceptLabel: 'Ja',
                      rejectLabel: 'Nee',
                      accept: () => {
                        const id = get(upsertingCultivation, 'cultivationId');
                        if (id) {
                          deleteCultivationMutation({variables: {id}})
                            .then(() => props.onClose());
                        }
                      }
                    });
                  }}
                />
                <Button
                  icon="pi pi-clone"
                  iconPos="right"
                  tooltip={'Kopieer'}
                  tooltipOptions={{position: 'top'}}
                  className={'p-button-secondary p-button-rounded p-button-outlined'}
                  onClick={() => {
                    setMode(Mode.COPY);
                    setListingYear(momentFromIso8601(upsertingCultivation.startDate).year());
                    const plantSchemaId = get(props, 'cultivation.plantSchema.id');
                    const plantSchemaCreate = plantSchemaId ? {
                      connect: {
                        id: plantSchemaId
                      }
                    } : null;
                    let update: Partial<UpsertVariables> = {
                      cultivationId: 'copy',
                      plantSchemaCreate: plantSchemaCreate,
                      remark: '',
                    };
                    update = {
                      ...update,
                    };

                    updateCultivation(update);
                  }}
                />
                {momentFromIso8601(upsertingCultivation.startDate).year() !== last(years) && <Button
                  icon="pi pi-reply"
                  iconPos="right"
                  tooltip={'Kopieer naar volgend seizoen'}
                  tooltipOptions={{position: 'top'}}
                  className={'p-button-secondary p-button-rounded p-button-outlined'}
                  onClick={() => {
                    setMode(Mode.COPY);
                    const currentPlot = plotOptions.find(p => p.id === upsertingCultivation.plotId);

                    if (currentPlot) {
                      const year = momentFromIso8601(upsertingCultivation.startDate).add(1, 'y').year();
                      setListingYear(year);
                      let plotOptionsOfSameType = plotOptions.filter(po => po.type === currentPlot.type);
                      const currentPlotIndex = plotOptionsOfSameType.findIndex(plot => plot.id === currentPlot.id);
                      const nextPlotIndex = (plotOptionsOfSameType[currentPlotIndex === plotOptionsOfSameType.length - 1 ? 0 : currentPlotIndex + 1]).id;

                      let newCultivation = {
                        cultivationId: 'copy',
                        remark: '',
                        plotId: nextPlotIndex,
                      };
                      if (selectedCropTimingId) {
                        const cultivationDates = calculateCultivationDates(year, upsertingCultivation);

                        newCultivation = {
                          ...newCultivation,
                          ...cultivationDates,
                        };
                      }
                      updateCultivation(newCultivation);
                    }
                  }}
                />}
              </>
            }
          </div>
          <div>
            <Button
              label={'Annuleren'}
              className={'p-button-text p-button-secondary'}
              onClick={props.onClose}
            />

            <Button
              disabled={disableSubmit}
              label={modeTexts[mode].saveButton}
              onClick={onSave}
            />
          </div>
        </Actions>
      </>}
    </div>
  </Sidebar>;
};

export default AddCultivation;
