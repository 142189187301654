import React, { ReactNode, useEffect, useRef } from "react";

function useOnClickRectOutside(ref: any, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      const targetEl = ref.current;
      if (targetEl) {
        const clickedX = event.clientX;
        const clickedY = event.clientY;
        const rect = targetEl.getBoundingClientRect();
        const targetElTop = rect.top;
        const targetElBottom = rect.top + rect.height;
        const targetElLeft = rect.left;
        const targetElRight = rect.left + rect.width;

        if (
          // check X Coordinate
          targetElLeft < clickedX &&
          clickedX < targetElRight &&
          // check Y Coordinate
          targetElTop < clickedY &&
          clickedY < targetElBottom
        ) {
          return
        }

        // trigger event when the clickedX,Y is outside of the targetEl
        handler(event)
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

interface OutsideAlerterProps {
  children: ReactNode;
  onOutsideClicked: () => void;
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter(props: OutsideAlerterProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickRectOutside(wrapperRef, props.onOutsideClicked);

  return <div ref={wrapperRef}>{props.children}</div>;
}
