import React, { useState } from 'react';
import { chain, compact, first } from "lodash";
import { formatAbs, formatDoubleDigit } from "../../../../../../../shared/utils/currency.utils";
import { CropDetails, CultivationDetails, PlantOrSeed } from "../../../../../../../__generated__/types";
import { useQuery } from "@apollo/client";
import { CropDetailsQuery } from "../../../../../crop-management/CropManagement.gql";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import DeleteButton from "./DeleteButton";
import styled from 'styled-components';
import AddVariety from "../../../../../crop-management/crop-details/components/Varieties/AddVariety";
import { Button } from 'primereact/button';
import { plantAmount } from "../../../../../../../utils/planting.util";
import { CultivationDetailsQuery } from "../../AddCultivation.gql";
import { plantSchemaForCalculations } from "../../../../../planning21-context";
import { soilBlockSizes } from "../../../../../../../config";

interface VarietiesSelectionProps {
  cropId: string | undefined;
  parentId: string | undefined | null;
  selectedVarieties: { varietyId: string, percentage: number }[];
  onAddVariety: (varietyId: string) => void;
  onChangeVarietyPercentage: (varietyId: string, percentage: number) => void;
  onDeleteVariety: (varietyId: string) => void;
  length: number;
  bedWidth: number;
  cultivationCropPlantSchemaId?: string;
}

const VarietyName = styled.div`
  display: grid;
  align-items: center;

  span:last-child {
    text-transform: uppercase;
    color: #748F56;
    font-size: 10px;
  }
`;

const DropdownGroupItem = styled.div`
  text-transform: uppercase;
  border-bottom: 1px solid #748F56;
  font-size: 20px;
  color: #748F56;
`;

const VarietiesSelection = (props: VarietiesSelectionProps) => {
  const {data} = useQuery<CropDetails>(CropDetailsQuery, {
    variables: {id: props.cropId || ""}
  });
  const {data: parentCultivationData} = useQuery<CultivationDetails>(CultivationDetailsQuery, {
    variables: {id: props.parentId || ""}
  });
  const [addingVariety, setAddingVariety] = useState<boolean>(false);

  const currentCrop = data?.crop;

  let plantSchema = compact(currentCrop?.plantSchemas).find(plantSchema => props.cultivationCropPlantSchemaId ? props.cultivationCropPlantSchemaId === plantSchema.id : plantSchema.default);

  let selectedVarietyRows = props.selectedVarieties.map(selectedVariety => {
    let currentVariety = compact(currentCrop?.varieties).find(v => v.id === selectedVariety.varietyId);
    if (!currentVariety) {
      return {
        name: 'ONBEKEND'
      }
    }

    let plantSchemaForParent = parentCultivationData?.cultivation && plantSchemaForCalculations(parentCultivationData.cultivation);
    return {
      id: currentVariety.id,
      cropName: currentVariety.alternativeCropName || currentCrop?.name,
      name: currentVariety.name,
      code: currentVariety.code,
      supplierName: currentVariety.supplier?.name,
      percentage: selectedVariety.percentage * 100,
      soilBlockType: currentVariety.soilBlockType,
      type: currentVariety.type,
      nrOfPlants: formatAbs(
        plantAmount(plantSchema?.plantSchema, props.length, props.bedWidth, plantSchemaForParent?.plantSchema) * selectedVariety.percentage) + 'pl',
    };
  });

  let options = chain(currentCrop?.varieties)
    .compact()
    .sortBy('supplier.name')
    .groupBy('supplier.id')
    .mapValues(value => ({
      label: first(value)?.supplier?.name,
      items: chain(value)
        .map(variety => ({...variety, alternativeCropName: variety.alternativeCropName || currentCrop?.name}))
        .sortBy(['alternativeCropName'], ['asc'])
        .value(),
    }))
    .values()
    .value();


  return <div>
    {selectedVarietyRows.length > 0 && <DataTable
      responsiveLayout={'scroll'}

      size={'small'}
      emptyMessage={'Geen rassen geselecteerd'}
      value={selectedVarietyRows}
    >
      <Column header={'Ras'} field={'name'}
              body={(variety) => {
                return <VarietyName>
                  {variety.cropName && <span>{variety.cropName}</span>}
                  <span>
                    {variety.name} <br/>
                    {soilBlockSizes.find(s => s.value === variety.soilBlockType)?.label}
                  </span>
                </VarietyName>
              }}
      />
      {selectedVarietyRows.length > 0 && <Column header={'%'}
                                                 field={'percentage'}
                                                 body={(variety) => formatDoubleDigit(variety.percentage)}
      />}
      {/*<Column header={'%'}*/}
      {/*        field={'percentage'}*/}
      {/*        body={(variety) => {*/}
      {/*          return <InputNumber*/}
      {/*            value={variety.percentage}*/}
      {/*            onChange={(e) => {*/}
      {/*              props.onChangeVarietyPercentage(variety.id, e.value/100)*/}
      {/*            }}*/}
      {/*          />*/}
      {/*        }}*/}
      {/*/>*/}
      <Column header={'#'} field={'nrOfPlants'}/>
      <Column header={''} body={(variety) => <DeleteButton varietyId={variety.id} onConfirm={props.onDeleteVariety}/>}
      />
    </DataTable>}

    <Dropdown
      filter
      filterBy={'name,alternativeCropName,code'}
      options={options}
      placeholder={"Kies (nog) een variëteit"}
      optionGroupLabel="label" optionGroupChildren="items"
      optionGroupTemplate={(group) => {
        return <DropdownGroupItem>{group.label}</DropdownGroupItem>
      }}
      optionLabel={'name'}
      optionValue={'id'}
      onChange={(e) => props.onAddVariety(e.target.value)}
      itemTemplate={(variety) => {
        return <VarietyName>
          {variety.alternativeCropName && <span>{variety.alternativeCropName}</span>}
          <span>{variety.name} {variety.code && `(${variety.code})`}</span>
        </VarietyName>
      }}
    />

    {props.cropId && !addingVariety &&
      <Button className={'p-button-link'} label={'Maak een nieuwe variëteit aan'}
              onClick={() => setAddingVariety(true)}/>}
    {props.cropId && addingVariety &&
      <AddVariety onClose={() => setAddingVariety(false)} cropId={props.cropId}/>}
  </div>;
};

export default VarietiesSelection;
