import { Season } from "../../../__generated__/types";
import { Moment } from "moment";

export type TimelineWindow = {
  firstDay: Moment,
  lastDay: Moment,
};

export interface TimelineData {
  sowingOrPlantingWindow: TimelineWindow;
  harvestWindow: TimelineWindow;
}

export interface Cultivation extends TimelineData {
  startDate: Moment;
  startDateUnix: number;
  occupationRatio?: number;
}

export interface Cultivation2 extends TimelineData {
  startDate: Moment;
  season: Season;
  harvestEndDate?: Moment;
  rotationGroup?: { id: string, name: string },
  startDateUnix: number;
  occupationRatio?: number;
  nrOfPlants?: number,
  plantSchemaForCalculations?: { plantSchema: { distanceInRow: number, distanceBetweenRows: number } };
  plot: { id: string };
}

// export interface Plot {
//   id: string;
//   name: string;
//   prefixName: string;
//   order: string;
//   rotationArea: Partial<{}>;
//   field: Partial<{}>;
// }

export interface GardenFilterValues {
  crops: string[],
  //plots: string[],
  rotationGroups: string[],
  years: number[],
  weeks: number[],
  fields: string[],
}

export interface FilterOptionValues {
  //plots: { name: string, id: string }[];
  crops: { name: string, id: string }[];
  rotationGroups: { name: string, id: string }[];
  years: { name: string, id: number }[];
  weeks: { name: string, id: number }[];
  fields: { name: string, id: string }[];
}

export interface GardenSortingGrouping {
  [key: string]: 'asc' | 'desc' | undefined;
}

export const DEFAULT_SORTING: GardenSortingGrouping = {
  'plot.order': 'asc',
  'cultivation.startDateUnix': 'asc',
};

export const DEFAULT_FILTER_OPTIONS = {
  crops: [],
  // plots: [],
  rotationGroups: [],
  years: [],
  weeks: [],
  fields: [],
};

export const EMPTY_GARDEN_FILTERS = {
  crops: [],
  // plots: [],
  rotationGroups: [],
  years: [],
  weeks: [],
  fields: [],
};

export const DEFAULT_FILTERING = {
  crops: [],
  // plots: [],
  fields: [],
  weeks: [],
  plots: [],
  rotationGroups: [],
  years: [2024],
};

