import React, { useEffect, useState } from 'react';
import { chain, compact, sortBy, values } from 'lodash';
import { PlantOrSeed, SeedsData } from "../../../__generated__/types";
import { useQuery } from '@apollo/client';
import { SeedsDataQuery } from "./Seeds.gql";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import styled from 'styled-components';
import { plantSchemaForCalculations } from "../planning21-context";
import { nrOfRows, plantAmount } from "../../../utils/planting.util";
import { formatAbs } from "../../../shared/utils/currency.utils";
import config from "../../../config";

interface SeedsPageProps {

}

interface SeedItem {
  supplierId: string | undefined,
  supplierName: string | undefined,
  cropName: string | undefined,

  varietyCropName: string | undefined | null,
  varietyName: string | undefined,
  varietyCode: string | undefined | null,
  varietyGramsPerRowMeter: number | null,

  seedingLength: number,
  plantingLength: number,
  nrOfPlants: number,
  totalGrams: number,
}

const VarietyInfo = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;

  span:last-child {
    color: #748F56;
  }
`;

const Lengths = styled.div`
  display: grid;
  grid-template-columns: 200px 90px;
  grid-column-gap: 16px;
`;

const SeedsPage = (props: SeedsPageProps) => {
  const {data} = useQuery<SeedsData>(SeedsDataQuery, {variables: {season: config.currentPlanningSeason}});

  const [seedItems, setSeedItems] = useState<{ [key: string]: SeedItem }>();

  useEffect(() => {
    let value = chain(data?.crops)
      .compact()
      .reduce((acc: {
        [key: string]: SeedItem
      }, crop) => {
        let updatedSeedItems = acc;

        compact(crop.cultivations).forEach(cultivation => {
          compact(cultivation.varieties).forEach(({variety, percentage}) => {
            let matchingSeedItem = updatedSeedItems[variety.id];

            const plantSchema = plantSchemaForCalculations({...cultivation, crop});
            if (!matchingSeedItem) {
              matchingSeedItem = {
                supplierId: variety.supplier?.id,
                supplierName: variety.supplier?.name,
                cropName: crop.name,
                varietyCropName: variety.alternativeCropName,
                varietyName: variety.name,
                varietyCode: variety.code,
                varietyGramsPerRowMeter: variety.gramsPerRowMeter,
                seedingLength: 0,
                plantingLength: 0,
                nrOfPlants: 0,
                totalGrams: 0,
              }
            }

            let nrOfRowsPerBedWidth = 0;
            if (plantSchema && variety.gramsPerRowMeter) {
              nrOfRowsPerBedWidth = nrOfRows(cultivation.plot.rotationArea.field.bedWidth, plantSchema?.plantSchema.distanceBetweenRows);
              matchingSeedItem = {
                ...matchingSeedItem,
                totalGrams: matchingSeedItem.totalGrams +
                  ((cultivation.length * percentage) * nrOfRowsPerBedWidth) * variety.gramsPerRowMeter,
              };
            }

            if (cultivation.cropTiming.type === PlantOrSeed.SEED) {
              matchingSeedItem = {
                ...matchingSeedItem,
                seedingLength: matchingSeedItem.seedingLength + cultivation.length * percentage,
              };
            } else {
              const nrOfPlants = plantSchema
                ? plantAmount(
                  plantSchema.plantSchema,
                  cultivation.length * percentage,
                  cultivation.plot.rotationArea.field.bedWidth, undefined,
                )
                : 0;
              matchingSeedItem = {
                ...matchingSeedItem,
                nrOfPlants: matchingSeedItem.nrOfPlants + nrOfPlants,
                plantingLength: matchingSeedItem.plantingLength + cultivation.length * percentage,
              };
            }

            updatedSeedItems = {
              ...updatedSeedItems,
              [variety.id]: matchingSeedItem,
            };
          })
        })
        return updatedSeedItems;
      }, {})
      .value();

    setSeedItems(value);
  }, [data]);

  return <div>
    <h1>Zaden</h1>
    <DataTable
      size={"small"}

      responsiveLayout={"scroll"}
      value={sortBy(values(seedItems), ['supplierId', 'cropName'])}
    >
      <Column header={'Gewas'} field={'supplierName'} sortable filter filterField={'supplierName'}
              sortField={'supplierName'}/>
      <Column header={'Gewas'} field={'cropName'} sortable sortField={'cropName'} body={seedItem => <VarietyInfo>
        <span>{seedItem.cropName}</span>
        <span>{seedItem.varietyCropName}</span>
      </VarietyInfo>}/>
      <Column header={'Gewas'} field={'varietyCode'} body={seedItem => <VarietyInfo>
        <span>{seedItem.varietyName}</span>
        <span>{seedItem.varietyCode}</span>
      </VarietyInfo>}/>
      <Column header={'Gram per rij per meter'} field={'gramsPerRowMeter'} body={seedItem => seedItem.varietyGramsPerRowMeter
        ? <div>{seedItem.varietyGramsPerRowMeter}gram</div>
        : null
      }/>
      <Column header={'#m zaaibed'}
              field={'seedingLength'} body={seedItem => <Lengths>
        {seedItem.seedingLength > 0 && <>
          <span>Rechtstreeks zaaien</span>
          <span>{formatAbs(seedItem.seedingLength)}m</span>
        </>}
        {seedItem.plantingLength > 0 && <>
          <span>Voorzaaien</span>
          <span>{seedItem.nrOfPlants}pl <br/>{formatAbs(seedItem.plantingLength)}m</span>
        </>}
      </Lengths>}/>
      <Column header={'Min. te bestellen gewicht'}
              body={seedItem => <Lengths>
                {seedItem.totalGrams > 0 && <>
                  <span>{formatAbs(seedItem.totalGrams)}gram</span>
                </>}
              </Lengths>}/>
    </DataTable>
  </div>;
};

export default SeedsPage;
