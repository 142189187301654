import React, { useState } from 'react';
import { TimelineRowType } from "./ScrollingTimeline";
import { first, last, values } from "lodash";
import { COLUMN_CONFIG } from 'pages/TimelinePage/TimelinePage';
import { Sidebar } from 'primereact/sidebar';
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Moment } from "moment";
import { Checkbox } from 'primereact/checkbox';
import moment from "moment/moment";
import CropMultiSelect from "./CropMultiSelect";
import LogicalCropGroupSelect from "./LogicalCropGroupSelect";

interface TimelineViewOptionsProps {
  alwaysShowMetadata: boolean;
  granularityCode: string;
  rowType: TimelineRowType;
  dateRange: Moment[];
  cropSelection: string[];
  logicalCropGroup: string | undefined;
  onGranularityChange: (granularity: 'yearly' | 'quarterly' | 'monthly' | 'weekly' | 'daily') => void;
  onRowTypeChange: (rowType: TimelineRowType) => void;
  onCropSelectionChange: (crops: string[]) => void;
  onLogicalCropGroupChange: (logicalCropGroup: string | undefined) => void;
  onDateRangeChange: (dateRange: Moment[]) => void;
  onAlwaysShowMetadataChange: (alwaysShowMetadata: boolean) => void;
}

const TimelineViewOptions = (props: TimelineViewOptionsProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  let dateRangeYears = props.dateRange.map(date => date.year());

  return <div>
    <Button
      severity="secondary"
      outlined
      rounded
      icon="pi pi-sliders-h"
      onClick={() => setVisible(true)}
    />

    <Sidebar position="right" visible={visible} onHide={() => setVisible(false)}>
      <div className="text-xl font-bold m-2">Instellingen</div>

      <div className="mb-2">
        <div className="text-base uppercase text-color-secondary pb-1">Periode</div>
        {values([2022, 2023, 2024, 2025]).map(year => {
          return <div key={year} className="flex align-items-center">
            <Checkbox inputId={`${year}`} name="granularity" value={year}
                      onChange={(e) => {
                        let updatedDateRange = [...dateRangeYears];
                        if (e.checked) {
                          updatedDateRange = [...updatedDateRange, e.value];
                        } else {
                          updatedDateRange.splice(updatedDateRange.indexOf(e.value), 1);
                        }
                        updatedDateRange = updatedDateRange.sort();


                        props.onDateRangeChange([
                          moment(first(updatedDateRange), 'YYYY').startOf('y'),
                          moment(last(updatedDateRange), 'YYYY').endOf('y'),
                        ]);
                      }}
                      checked={dateRangeYears.includes(year)}/>
            <label htmlFor={`${year}`} className="ml-2">{year}</label>
          </div>;
        })}
      </div>

      <div className="mb-2">
        <div className="text-base uppercase text-color-secondary pb-1">Toon per</div>
        {values(COLUMN_CONFIG).map(columnConfig =>
          <div key={columnConfig.code} className="flex align-items-center">
            <RadioButton inputId={columnConfig.code} name="granularity" value={columnConfig.code}
                         onChange={(e) => props.onGranularityChange(e.value)}
                         checked={props.granularityCode === columnConfig.code}/>
            <label htmlFor={columnConfig.code} className="ml-2">{columnConfig.label}</label>
          </div>)}
      </div>

      <div className="mb-2">
        <div className="text-base uppercase text-color-secondary pb-1">Toon volgens</div>
        {[
          {label: 'Gewas', value: 'crop'},
          {label: 'Locatie', value: 'plot'},
        ].map(columnConfig =>
          <div key={columnConfig.value} className="flex align-items-center">
            <RadioButton inputId={columnConfig.value} name="rowType" value={columnConfig.value}
                         onChange={(e) => props.onRowTypeChange(e.value)}
                         checked={props.rowType === columnConfig.value}/>
            <label htmlFor={columnConfig.value} className="ml-2">{columnConfig.label}</label>
          </div>)}
      </div>

      <div className="mb-2">
        <div className="text-base uppercase text-color-secondary pb-1">Toon Gewassen</div>
        <CropMultiSelect cropSelection={props.cropSelection} onCropSelectionChange={props.onCropSelectionChange}/>
      </div>

      <div className="mb-2">
        <div className="text-base uppercase text-color-secondary pb-1">Toon groepen</div>
        <LogicalCropGroupSelect logicalCropGroup={props.logicalCropGroup} onLogicalCropGroupChange={props.onLogicalCropGroupChange} />
      </div>

      <div className="mb-2">
        <div className="text-base uppercase text-color-secondary pb-1">opties</div>
        <Checkbox
          checked={props.alwaysShowMetadata}
          inputId='alwaysShowMetadata'
          onChange={e => props.onAlwaysShowMetadataChange(e.checked || false)}
          />
        <label htmlFor="alwaysShowMetadata">Toon weken op tijdslijn</label>
      </div>
    </Sidebar>

  </div>;
};

export default TimelineViewOptions;
