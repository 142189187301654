import React from 'react';
import { CultivationsForCrop_cultivations, CultivationsForPlot_cultivations, FieldType, PlantOrSeed } from "../../../../../__generated__/types";
import { hasIncorrectRotationGroup } from "../../utils/cultivation-warnings";
import styled from "styled-components";
import { Tooltip } from "primereact/tooltip";
import { plantSchemaForCalculations } from "../../../planning21-context";
import varietiesList from "../../../crop-management/crop-details/components/Varieties/VarietiesList";
import { sum } from "lodash";

interface WarningColumnProps {
  cultivation: CultivationsForCrop_cultivations | CultivationsForPlot_cultivations;
}

const Container = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  color: var(--orange-500);
`;

const WarningColumn = (props: WarningColumnProps) => {
  const {varieties, id} = props.cultivation;
  const errors = [];
  if (hasIncorrectRotationGroup(props.cultivation)) {
    errors.push("Deze teelt past niet in de gewasgroepen voor dit veld.");
  }

  if (props.cultivation.cropTiming.type === PlantOrSeed.PLANT && !plantSchemaForCalculations(props.cultivation)) {
    errors.push("Er is geen plantschema gekend.");
  }


  if (props.cultivation.cropTiming.cultivationMethod === 'Openlucht' && props.cultivation.plot.rotationArea.field.type === FieldType.GREENHOUSE) {
    errors.push("Er wordt een teeltmogelijkheid voor openlucht in een serre gebruikt.");
  }

  if (props.cultivation.cropTiming.cultivationMethod === 'Beschut' && props.cultivation.plot.rotationArea.field.type === FieldType.OPEN) {
    errors.push("Er wordt een teeltmogelijkheid voor serreteelt in openlucht gebruikt.");
  }

  if (varieties?.length === 0) {
    errors.push("Er zijn geen rassen gekend.");
  }

  let totalPercentage = sum(varieties?.map(variety => variety.percentage));
  if(!(totalPercentage > 0.98 && totalPercentage < 1.02)) {
    errors.push("Foutieve verdeling van aantal planten per ras.");
  }

  return <Container>
    <Tooltip target={`#warning-icon-${id}`} />
    {errors.length > 0 && <i id={`warning-icon-${id}`} className={'pi pi-exclamation-triangle'} data-pr-tooltip={errors.join('\n')}/>}
  </Container>;
};

export default WarningColumn;
