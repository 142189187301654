import React from 'react';
import { MultiSelect } from "primereact/multiselect";
import { compact, intersection, map, uniq, without } from "lodash";
import { Checkbox } from "primereact/checkbox";
import { useQuery } from "@apollo/client";
import { CropGroupsWithCrops } from "../../../../__generated__/types";
import { CropGroupsWithCropsQuery } from "../../../21/crop-management/CropManagement.gql";

interface CropMultiSelectProps {
  cropSelection: string[];
  onCropSelectionChange: (crops: string[]) => void;
}

const CropMultiSelect = (props: CropMultiSelectProps) => {
  const {data} = useQuery<CropGroupsWithCrops>(CropGroupsWithCropsQuery);
  const cropGroups = compact(data?.cropGroups);
  const groupedItemTemplate = (option: any) => {
    const cropGroup = cropGroups.find(cropGroup => cropGroup.id === option.id);
    const cropIdsForGroup = map(compact(cropGroup?.crops), 'id');
    return (
      <div className="flex align-items-center">
        <Checkbox
          checked={props.cropSelection.length > 0 && intersection(props.cropSelection, cropIdsForGroup).length === cropIdsForGroup.length}
          onChange={e => {
            if (e.checked) {
              props.onCropSelectionChange(uniq([...props.cropSelection, ...cropIdsForGroup]));
            } else {
              props.onCropSelectionChange(without(props.cropSelection, ...cropIdsForGroup));
            }
          }}>

        </Checkbox>
        <div>{option.name}</div>
      </div>
    );
  };
  return <MultiSelect
    //key={'id'}
    filter
    options={cropGroups}
    optionLabel='name'
    optionValue='id'
    value={props.cropSelection}
    onChange={(e) => {
      props.onCropSelectionChange(e.value);
    }}
    optionGroupLabel="name"
    optionGroupChildren="crops"
    optionGroupTemplate={groupedItemTemplate}
    placeholder="Selecteer gewassen"
    display="comma"
    style={{maxWidth: '15rem', width: '15rem'}}
  />;
};

export default CropMultiSelect;
