import React, { useRef } from 'react';
import { useUserObject } from "../../context/UserContext";
import { OverlayPanel } from "primereact/overlaypanel";
import FarmSelector from "../../pages/HomePage/components/FarmSelector/FarmSelector";

interface FarmSwitchProps {

}

const FarmSwitch = (props: FarmSwitchProps) => {
  const {user, activeFarm, setActiveFarm} = useUserObject();
  const overlayPanelRef = useRef<OverlayPanel>(null);

  return <div className="pr-2 mr-2 border-right-1 border-50">
    <div onClick={(e) => {
      overlayPanelRef.current?.toggle(e);
    }}>
      {activeFarm?.logo && <img className="w-6rem" src={activeFarm.logo} alt={activeFarm.name}/>}
    </div>
    <OverlayPanel ref={overlayPanelRef}>
      <FarmSelector/>
    </OverlayPanel>
  </div>;
};

export default FarmSwitch;
