import React from 'react';
import styled from "styled-components";
import { AllCrops_crops } from "../../../../__generated__/types";
import { Header } from "../../Planning21/styled-components";

interface CropListItemProps {
  crop: AllCrops_crops;
}

const Container = styled.div`
  display: grid;
  grid-template-areas:
  'cropName cropName'
  'cropFamily cropGroup';
  grid-template-rows: max-content max-content;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 4px;
  padding: 4px 8px 8px 16px;
  line-height: 1;
  margin-bottom: 8px;
`;

const CropFamily = styled.div`
  color: var(--text-color-secondary);
  //padding-left: 8px;
`;

const CropGroup = styled.div`
  color: var(--text-color-secondary);
`;

const CropListItem = (props: CropListItemProps) => {
  const {name, family, group} = props.crop;
  return <Container>
    <Header style={{gridArea: 'cropName'}}>{name}</Header>
    <CropFamily style={{gridArea: 'cropFamily'}}>{family && family.name}</CropFamily>
    <CropGroup style={{gridArea: 'cropGroup'}}>{group && group.name}</CropGroup>
  </Container>;
};

export default CropListItem;
