import React, { MutableRefObject, useEffect } from 'react';
import { ContextMenu } from "primereact/contextmenu";
import { MenuItem } from "primereact/menuitem";
import { Button } from "primereact/button";
import config from "../../../../config";
import { CopyCultivationsToPlot, CopyCultivationsToPlotVariables, CultivationCreateWithoutPlotInput, CultivationDetails, CultivationDetails_cultivation, CultivationVarietyCreateInput, PlantOrSeed } from "../../../../__generated__/types";
import { compact, round } from "lodash";
import { calculateCultivationDates } from "../../../21/Planning21/components/AddCultivation/AddCultivation";
import { startYear } from "../../../../shared/utils/season.util";
import { CultivationDetailsQuery } from "../../../21/Planning21/components/AddCultivation/AddCultivation.gql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client/react/hooks";
import { CopyCultivationsToPlotMutation } from "../../../21/planning21.gql";
import { plantSchemaForCalculations } from "../../../21/planning21-context";
import { nrOfRows, plantAmount, plantAmountToLength } from "../../../../utils/planting.util";

interface CultivationRowContextMenuProps {
  menuRef: MutableRefObject<ContextMenu | null>;
  cultivationId: string;
}


const CultivationRowContextMenu = (props: CultivationRowContextMenuProps) => {
  const [getCultivationDetails, {data}] = useLazyQuery<CultivationDetails>(CultivationDetailsQuery);

  const [copyCultivationsToPlot] = useMutation<CopyCultivationsToPlot>(CopyCultivationsToPlotMutation);

  const copyCultivation = (
    cultivation: CultivationDetails_cultivation,
    targetPlot: { id: string, rotationArea: { field: { bedWidth: number } } } | undefined) => {

    if (cultivation) {
      const varieties: CultivationVarietyCreateInput[] = compact(cultivation.varieties).map(variety => {
        return {
          variety: {connect: {id: variety.variety.id}},
          percentage: variety.percentage,
        };
      });

      let length = cultivation.length
      const originalBedWidth = cultivation.plot.rotationArea.field.bedWidth;
      const targetBedWidth = targetPlot?.rotationArea.field.bedWidth;
      if (targetBedWidth && originalBedWidth !== targetBedWidth) {
        const plantSchema = plantSchemaForCalculations(cultivation);
        debugger;
        if (!plantSchema && cultivation.cropTiming.type === PlantOrSeed.SEED) {
          length = round(length * (targetBedWidth / originalBedWidth), 0);
        } else {
          let parentPlantSchema = cultivation.parent ? plantSchemaForCalculations(cultivation.parent) : undefined;
          let nrOfPlants = plantAmount(plantSchema?.plantSchema, length, originalBedWidth, parentPlantSchema?.plantSchema);

          length = plantAmountToLength(plantSchema?.plantSchema, nrOfPlants, targetBedWidth, parentPlantSchema?.plantSchema);

        }
      }

      let cultivationCreateWithoutPlotInput: CultivationCreateWithoutPlotInput = {
        crop: {connect: {id: cultivation.crop.id}},
        cropTiming: {connect: {id: cultivation.cropTiming.id}},
        interPlanting: cultivation.interPlanting,
        length,
        transplant: cultivation.transplant,
        pinned: cultivation.pinned,
        varieties: {
          create: varieties,
        },
        ...calculateCultivationDates(startYear(config.currentPlanningSeason), cultivation),
      };

      if (cultivation.plantSchema?.id) {
        cultivationCreateWithoutPlotInput = {
          ...cultivationCreateWithoutPlotInput,
          plantSchema: {connect: {id: cultivation.plantSchema?.id}},
        };
      }

      const variables: CopyCultivationsToPlotVariables = {
        plotId: targetPlot?.id || cultivation.plot.id,
        createMayCultivationsInput: [cultivationCreateWithoutPlotInput]
      };

      copyCultivationsToPlot({variables, refetchQueries: "active"});
    }
  };

  useEffect(() => {
    if (data?.cultivation) {
      copyCultivation(data.cultivation, data.cultivation.plot);
    }
  }, [data]);

  const items: MenuItem[] = [
    {
      template: () => <Button
        link
        icon='pi pi-fw pi-search'
        label={`Kopieer naar ${config.currentPlanningYear}`}
        onClick={() => {
          getCultivationDetails({
            variables: {id: props.cultivationId}
          })

          // copyCultivation({
          //   id: "clceyhyd4d32i07753zpq9uio", rotationArea: {field: {bedWidth: 0.85}}
          // });
          //copyCultivation("clceyozyfd3h80775k7hc2bhh");
        }}
      />
    },
  ];

  return <ContextMenu className={'z-5'} model={items} ref={props.menuRef} breakpoint="767px"/>;
};

export default CultivationRowContextMenu;
