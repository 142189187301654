import React, { useState } from 'react';
import { ConfirmPopup } from "primereact/confirmpopup";
import { Button } from "primereact/button";

interface DeleteButtonProps {
  onConfirm: (varietyId: string) => void;
  varietyId: string;
}

const DeleteButton = (props: DeleteButtonProps) => {
  const [visible, setVisible] = useState(false);

  let buttonId = 'delete' + props.varietyId;
  let elementById = document.getElementById(buttonId);
  return <>
    <ConfirmPopup
      target={elementById as HTMLElement}
      visible={visible}
      onHide={() => setVisible(false)}
      message="Ben je zeker dat je dit ras wenst te verwijderen?"
      icon="pi pi-exclamation-triangle"
      accept={() => {
        props.onConfirm(props.varietyId);
      }}
    />
    <Button id={buttonId} icon="pi pi-trash" style={{padding: 0}} className="p-button-sm p-button-text p-button-danger"
            onClick={() => setVisible(true)}/>
  </>;
};

export default DeleteButton;
