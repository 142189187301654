import React, { useEffect, useState } from 'react';
import { CropDetails, CropDetails_crop } from "../../../../__generated__/types";
import styled from "styled-components";
import Varieties from "./components/Varieties/Varieties";
import PlantSchemas from "./components/PlantSchemas";
import CropTimings from "./components/CropTimings/CropTimings";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { CropDetailsQuery } from "../CropManagement.gql";

interface CropDetailsViewProps {
  cropId: string;
}

const Container = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding: 32px;
`;

const CropDetailsView = (props: CropDetailsViewProps) => {
  const {data, loading} = useQuery<CropDetails>(CropDetailsQuery, {
    variables: {
      id: props.cropId || ""
    }
  });
  const [crop, setCrop] = useState<CropDetails_crop>();

  useEffect(() => {
    if (data?.crop) {
      setCrop(data?.crop);
    }
  }, [data]);

  if (loading) {
    return <Container>
      Laden
    </Container>
  }
  if (!crop) {
    return <Container>
      Geen gewas gekend
    </Container>
  }

  return <Container>
    <h1>{crop.name}</h1>
    <h2>Plantschema</h2>

    <PlantSchemas crop={crop}/>

    <h2>Vari&euml;teiten</h2>
    <Varieties crop={crop}/>
    <h2>Teeltmogelijkheden</h2>
    <CropTimings cropId={crop.id}
                 years={[moment().year(), moment().year() + 1]}
                 year={moment().year()}
                 onChange={() => {

                 }}
    />
  </Container>;
};

export default CropDetailsView;
