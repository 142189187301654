import { Gridder } from "../__generated__/types";

export const nrOfRows = (width: number, distanceBetweenRows: number) => {
  const rowsNoRounding = (width * 100) / distanceBetweenRows;

  return (rowsNoRounding < 2 && rowsNoRounding > 1.5) ? Math.ceil(rowsNoRounding) : Math.floor(rowsNoRounding);
};

export const plantAmountToLength = (plantSchema: {
                                      distanceBetweenRows: number,
                                      distanceInRow: number,
                                    } | null | undefined,
                                    nrOfPlants: number,
                                    width: number,
                                    parentPlantSchema: {
                                      distanceBetweenRows: number,
                                      distanceInRow: number,
                                    } | null | undefined,) => {
  // Per 100m to prevent rounding issues
  const nrOfPlantsPer100Meter = plantAmount(plantSchema, 100, width, parentPlantSchema);
  return (nrOfPlants / nrOfPlantsPer100Meter) * 100;
};

export const plantAmount = (
  plantSchema: {
    distanceBetweenRows: number,
    distanceInRow: number,
  } | null | undefined,
  length: number,
  width: number,
  parentPlantSchema: {
    distanceBetweenRows: number,
    distanceInRow: number,
  } | null | undefined,
): number => {
  if (!plantSchema) {
    return 0;
  }

  const {distanceInRow, distanceBetweenRows} = plantSchema;

  // let rows = 0;
  // let remainingWidth = (width*100);
  // while (remainingWidth > distanceBetweenRows/5) {
  //   rows = rows + 1;
  //   remainingWidth = remainingWidth - distanceBetweenRows;
  // }

  const plantsPerRowNoRounding = (length * 100) / distanceInRow;
  const plantsPerRow = (plantsPerRowNoRounding % 1 >= 0.5) ? Math.ceil(plantsPerRowNoRounding) : Math.floor(plantsPerRowNoRounding);

  let totalAmount = nrOfRows(width, distanceBetweenRows) * plantsPerRow;

  if (parentPlantSchema) {
    let distanceInRowForParent = parentPlantSchema.distanceInRow;
    let distanceInRow = plantSchema.distanceInRow;
    if (distanceInRowForParent && distanceInRow &&
      ((distanceInRowForParent % distanceInRow === 0) || (distanceInRow && distanceInRowForParent === 0))) {
      let totalAmountParent = plantAmount(parentPlantSchema, length, width, undefined);
      return totalAmount - totalAmountParent;
    }
  }

  return totalAmount;
};


export const plantAmounts = (
  plantSchema: {
    distanceBetweenRows: number,
    distanceInRow: number,
  } | null | undefined,
  length: number,
  width: number,
  parentPlantSchema: {
    distanceBetweenRows: number,
    distanceInRow: number,
  } | null | undefined,
): {totalPlants: number, nrOfRows: number, distanceInRow: number, distanceBetweenRows: number} | undefined => {
  if (!plantSchema) {
    return;
  }

  const {distanceInRow, distanceBetweenRows} = plantSchema;

  const plantsPerRowNoRounding = (length * 100) / distanceInRow;
  const plantsPerRow = (plantsPerRowNoRounding % 1 >= 0.5) ? Math.ceil(plantsPerRowNoRounding) : Math.floor(plantsPerRowNoRounding);

  let rows = nrOfRows(width, distanceBetweenRows);
  let totalAmount = rows * plantsPerRow;

  if (parentPlantSchema) {
    let distanceInRowForParent = parentPlantSchema.distanceInRow;
    let distanceInRow = plantSchema.distanceInRow;
    if (distanceInRowForParent && distanceInRow &&
      ((distanceInRowForParent % distanceInRow === 0) || (distanceInRow && distanceInRowForParent === 0))) {
      let totalAmountParent = plantAmount(parentPlantSchema, length, width, undefined);
      return {
        totalPlants: totalAmount - totalAmountParent,
        nrOfRows: rows,
        distanceInRow,
        distanceBetweenRows,
      };
    }
  }

  return {
    totalPlants: totalAmount,
    nrOfRows: rows,
    distanceInRow,
    distanceBetweenRows,
  };
};


// export const cultivationLength = (plantSchema: {
//                                     distanceBetweenRows: number,
//                                     distanceInRow: number,
//                                   } | null | undefined,
//                                   nrOfPlants: number | null | undefined,
//                                   bedWidth: number) => {
//   if (plantSchema && nrOfPlants) {
//     const nrOfPlantsNextToEachOther = Math.floor(((bedWidth * 100) / plantSchema.distanceBetweenRows));
//
//     //const nrOfPlantsPerRow = Math.floor(((length * 100) / plantSchema.distanceInRow));
//
//     const nrOfPlantsInRow = nrOfPlants / nrOfPlantsNextToEachOther;
//
//     return ceil(nrOfPlantsInRow * (plantSchema.distanceInRow / 100), 0.5);
//   } else {
//     return undefined;
//   }
// };

export const schema = (cultivationRow: {
  crop: {
    seedSchema?: {
      rearGear: number,
      frontGear: number,
      rows: number,
    } | null,
    plantSchema?: {
      gridder: Gridder | null,
      distanceInRow: number,
      offset: boolean,
      distanceBetweenRows: number,
    } | null
  },
}) => {
  if (cultivationRow && cultivationRow.crop.plantSchema) {
    const {gridder, distanceInRow, distanceBetweenRows} = cultivationRow.crop.plantSchema;
    let wheels;
    switch (gridder) {
      case Gridder.TWO_WHEELS:
        wheels = '(2)';
        break;
      case Gridder.FOUR_WHEELS:
        wheels = '(4)';
        break;
      case Gridder.THREE_WHEELS:
        wheels = '(3)';
        break;
      case Gridder.SIX_WHEELS:
        wheels = '(6)';
        break;
      default:
        wheels = '';
    }

    return `${distanceBetweenRows} x ${distanceInRow} ${wheels}`
  }

  if (cultivationRow && cultivationRow.crop.seedSchema) {
    const {rearGear, frontGear, rows} = cultivationRow.crop.seedSchema;
    return `v${frontGear} - a${rearGear} / ${rows} rijen`
  }
};

export const gridderNrOfDiscs = (gridder?: Gridder | null) => {
  if (gridder === Gridder.TWO_WHEELS) {
    return 2;
  } else if (gridder === Gridder.THREE_WHEELS) {
    return 3;
  } else if (gridder === Gridder.FOUR_WHEELS) {
    return 4;
  } else if (gridder === Gridder.SIX_WHEELS) {
    return 6;
  }
}
