import React, { useState } from 'react';
import MarkAsDoneButton from "./MarkAsDoneButton";
import { CultivationTask } from "../Tasks";
import { CultivationField } from "../../Planning21/components/AddCultivation/AddCultivation";
import { Calendar } from "primereact/calendar";
import { asIso8601, momentFromIso8601 } from "../../../../shared/utils/date.utils";
import { CultivationEventType } from "../../../../__generated__/types";
import moment from "moment/moment";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { usePlanning } from "../../planning21-context";

interface TaskOverviewItemActionsProps {
  task: CultivationTask;
}

const TaskOverviewItemActions = (props: TaskOverviewItemActionsProps) => {
  const {task} = props;

  const {createCultivationEvent} = usePlanning();

  const [value, setValue] = useState<string>(asIso8601(moment()));
  const [visible, setVisible] = useState<boolean>(false);
  const [skipped, setSkipped] = useState<boolean>(false);
  const [note, setNote] = useState<string>();

  let isDone = task.taskStatus === 'done';
  return <div className="mt-3">
    <div className="flex justify-content-between md:flex-column">
      <MarkAsDoneButton
        type={'done'}
        isDone={task.taskStatus === 'done'}
        onClick={() => {
          setSkipped(false);
          setVisible(true);
        }}
      />
      <MarkAsDoneButton
        type={'skip'}
        isDone={task.taskStatus === 'done'}
        onClick={() => {
          setSkipped(true);
          if (task.taskType === CultivationEventType.FLAMEWEED && task.taskDate) {
            setValue(asIso8601(task.taskDate));
          }
          setVisible(true);
        }}
      />
    </div>
    {visible && <div>
      <div>
        <CultivationField label={'Datum'}>
          <Calendar
            inline
            value={momentFromIso8601(value).toDate()}
            disabled={task.taskType === CultivationEventType.FLAMEWEED}
            locale={'nl'}
            dateFormat={"dd/mm/yy"}
            showWeek
            onChange={e => setValue(asIso8601(moment(e.value as Date)))}
          />
        </CultivationField>
        <CultivationField label={'Opmerking'}>
          <InputTextarea
            style={{width: '100%'}}
            value={note || ''}
            onChange={(e: any) => setNote(e.target.value)}
          />

        </CultivationField>
      </div>
      <div className="flex justify-content-between">
        <Button
          disabled={isDone}
          label={'Ok'}
          onClick={(e) => {
            setVisible(false);
            !isDone && createCultivationEvent(task.id, task.taskType, value, note, undefined, skipped);
          }}
        />
        <Button
          disabled={isDone}
          label={'Annuleren'}
          className="p-button-text"
          onClick={(e) => {
            setVisible(false);
          }}
        />
      </div>
    </div>}
  </div>;
};

export default TaskOverviewItemActions;
