import React from 'react';
import { get } from "lodash";
import { TIMELINE_VIEW_CONFIG } from "./ScrollingTimeline";
import { momentFromIso8601 } from "../../../../shared/utils/date.utils";
import { Moment } from "moment";
import { nrOfDaysInTimeline } from "./CultivationRowTimeline";
import { CultivationEventType } from "../../../../__generated__/types";

interface TimelineEventProps {
  event: any;
  timelineRange: Moment[];
  rowHeight: number;
}

const TimelineEvent = (props: TimelineEventProps) => {
  const [timelineStart, timelineEnd] = props.timelineRange;
  const eventDate = momentFromIso8601(get(props.event, "date"));
  let nrOfDaysFromTimelineStartToEventStart = eventDate.diff(timelineStart, "d");

  // calculate length based on action type, flameweeding needs to be done in a 1-2 days, fertilization can wait 3 days or longer
  let nrOfDaysFromTimelineStartToEventEnd = eventDate.clone().add(1, 'week').diff(timelineStart, "d");

  const isFertilizeEvent = get(props.event, "type") === CultivationEventType.FERTILIZE;
  const isSeedEvent = get(props.event, "type") === CultivationEventType.SEED;

  if (!isSeedEvent && !isFertilizeEvent) {
    return null;
  }

  return <div
    style={{
      height: props.rowHeight,
      top: 0,
      left: `${(nrOfDaysFromTimelineStartToEventStart / nrOfDaysInTimeline(props.timelineRange)) * 100}%`,
      width: (nrOfDaysFromTimelineStartToEventEnd - nrOfDaysFromTimelineStartToEventStart) / nrOfDaysInTimeline(props.timelineRange) * 100 + '%'
    }}
    className="absolute flex align-items-center justify-content-center p-0 px-1 z-3"
    onClick={() => {
    }}
  >
    <div
      className={`w-full bg-${isFertilizeEvent ? 'red-300' : isSeedEvent ? 'orange-900' : 'pink-200'} border-round-md`}
      style={{
        height: TIMELINE_VIEW_CONFIG.cultivationBarHeight,
      }}>
    </div>
  </div>;
};

export default TimelineEvent;
