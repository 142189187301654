import { OverlayPanel } from "primereact/overlaypanel";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { first, get, isEmpty, map, values } from "lodash";
import { Planning21Column } from "./planning21-columns";
import { usePlanning } from "../../planning21-context";
import { Button } from "primereact/button";
import { AllCrops_crops } from "../../../../__generated__/types";
import { ListBox } from "primereact/listbox";
import { TextButton } from "../styled-components";

interface SortingItemProps<T> {
  cropGroups: { [key: string]: AllCrops_crops[] },
  label: string;
  subHeaders?: (years: number[]) => Planning21Column<T>[];
  sortFields?: string[];
  onSort?: (fields: string[], direction: 'asc' | 'desc' | undefined) => void;
  filterValue: any[];
  filterOptionValues?: { name: string, id: any }[];
  isFiltered: boolean | undefined;
  isSorted: boolean | undefined;
  onFilter: (values: string[]) => void;
}

const Container = styled.div`
  padding: 0;
  color: var(--text-color);

  > .label {
    font-weight: bold;
  }
  > .sort {  text-align: right;}

`;

const Header = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: normal;
  grid-gap: 8px;
  font-weight: bold;
  padding: 0;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .p-button {
      margin: 0;
      padding: 0;
      border: 0;

      &.p-button-icon-only {
        height: auto;
        width: auto;
      }
    }
  }

  i {
    color: var(--text-color);
    opacity: 0.6;
  }

  i.pi.active {
    color: var(--primary-color);
  }
`;

const SubHeaders = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.count}, 1fr);
  width: 100%;
  > div {
  }
`;

const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  color: var(--text-color);
  padding: 0 2px;
  font-size: 90%;
  border-left: 1px solid var(--surface-d);
`;

const SortingItem = <T extends any>(props: SortingItemProps<T>) => {
  const [direction, setDirection] = useState<'asc' | 'desc' | undefined>();
  const {years} = usePlanning();
  const panelRef = React.createRef<OverlayPanel>();

  const subHeaders = props.subHeaders ? props.subHeaders(years) : [];

  const {onFilter} = props;
  const onFilterChangeCallback = useCallback((e: any) => {
    onFilter(e.value);
  }, [onFilter]);

  return <Container>
    <Header>
      <span className={'label'}>{props.label}</span>
      <div>
        {!isEmpty(props.sortFields) &&
        <Button tooltip={'Sorteren'} tooltipOptions={{showDelay: 0, position: 'bottom'}} icon={`pi pi-sort-alpha-down`}
                className={`p-button-rounded p-button-text ${props.isSorted ? '' : 'p-button-secondary'}`}
                onClick={() => {
                  const updatedDirection = direction === 'asc'
                    ? 'desc'
                    : direction === 'desc' ? undefined : 'asc';
                  setDirection(updatedDirection);
                  props.onSort && props.onSort(props.sortFields || [], updatedDirection);
                }}/>}
        {props.filterOptionValues &&
        <>
          <Button tooltip={'Filteren'} tooltipOptions={{showDelay: 0, position: 'bottom'}} icon={`pi pi-filter`}
                  className={`p-button-rounded p-button-text ${props.isFiltered ? '' : 'p-button-secondary'}`}
                  onClick={e => panelRef.current && panelRef.current.toggle(e, null)}/>
          <OverlayPanel ref={panelRef}>
            <div>
              <div><TextButton onClick={() => props.onFilter([])}>Toon alles</TextButton><br/></div>
              {values(props.cropGroups).map(cropsInCropGroup => {
                let group = get(first(cropsInCropGroup), 'group');
                return <div key={group?.id}><TextButton
                    onClick={() => props.onFilter(map(cropsInCropGroup, 'id'))}>Toon {group?.name}</TextButton>
                  </div>;
                }
              )}
              <ListBox
                filter
                optionValue={'id'}
                optionLabel={'name'}
                value={props.filterValue}
                options={props.filterOptionValues}
                onChange={onFilterChangeCallback}
                multiple
                listStyle={{maxHeight: '250px'}}
              />
            </div>
          </OverlayPanel>
        </>}
      </div>
    </Header>
    {!isEmpty(subHeaders) &&
    <SubHeaders count={subHeaders.length}>{subHeaders.map((sortingGroupingItem) => {
      return <SubHeader key={sortingGroupingItem.value}>
        <div className="label">{sortingGroupingItem.label}</div>
      </SubHeader>;
    })}</SubHeaders>}
  </Container>;
};
export default SortingItem
