import React, { useEffect, useState } from 'react';
import { compact, first } from 'lodash';
import styled from 'styled-components';
import { AllCrops, AllCrops_crops } from "../../../__generated__/types";
import CropDetails from "./crop-details/CropDetails";
import { Spaced, TextButton } from "../Planning21/styled-components";
import AddCrop from "./add-crop";
import CropListItem from "./components/CropListItem";
import { useQuery } from "@apollo/client";
import { AllCropsQuery } from "./CropManagement.gql";
import { useLocation, useSearchParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

const Wrapper = styled.div`
  margin: 0;
  display: grid;
  grid-template-rows: max-content 1fr;
`;

const Container = styled.div`
  position: relative;
`;

const CropMasterDetail = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr;
  //grid-gap: 8px;
  overflow: scroll;
`;

const CropList = styled.div`
  //position: absolute;
  //top: 0;
  //bottom: 0;
  //left: 0;
   overflow-y: scroll;
   //position: relative;
   display: grid;
   grid-template-rows: max-content  max-content 1fr;
`;

const CropListItems = styled.div`
  overflow-y: scroll;
  height: 100%;
`;

const CropListRow = styled.div`
  cursor: pointer;
  &:hover, &.active {
    background-color: var(--surface-d);
  }
`;

const CropManagement = () => {
  const [cropListItems, setCropListItems] = useState<AllCrops_crops[]>([]);
  const [activeCropId, setActiveCropId] = useState<string>();

  const [searchParams, setSearchParams] = useSearchParams();

  const {data} = useQuery<AllCrops>(AllCropsQuery);

  const [mode, setMode] = useState<'read' | 'edit' | 'create'>('read');

  useEffect(() => {
    let cropId = searchParams.get('cropId');
    if (cropId) {
      setActiveCropId(cropId)
    }
  }, [searchParams]);

  useEffect(() => {
    let crops = compact(data?.crops);
    setCropListItems(crops);

    // const firstItem = first(crops);
    // if (!activeCropId && firstItem) {
    //   setActiveCropId(firstItem.id)
    // }
  }, [data, activeCropId]);

  return <Wrapper>
    <Spaced>
      <h1>Gewasbeheer</h1>
    </Spaced>
    <Container>
      <CropMasterDetail>
        <CropList>
          {mode === 'read' && <TextButton
            onClick={() => setMode('create')}
          >+ Gewas toevoegen</TextButton>}
          {mode === 'create' && <AddCrop onClose={() => setMode('read')}/>}
          <CropListItems>
            {cropListItems.map(cropListItem =>
              <CropListRow
                key={cropListItem.id}
                className={(activeCropId === cropListItem.id) ? 'active' : ''}
                onClick={() => {
                  //setSelectedVarietyId && setSelectedVarietyId(undefined);
                  setSearchParams({cropId: cropListItem.id});
                  setActiveCropId(cropListItem.id);
                }}
              >
                <CropListItem crop={cropListItem}/>
              </CropListRow>)}
          </CropListItems>
        </CropList>
        {activeCropId && <CropDetails cropId={activeCropId}/>}
      </CropMasterDetail>
    </Container>
  </Wrapper>;
};

export default () => <CropManagement/>;
