import React, { useState } from 'react';
import { CopyCultivationsToPlot, CopyCultivationsToPlotVariables, CultivationCreateWithoutPlotInput, CultivationsForPlot_cultivations, CultivationVarietyCreateInput, Season } from "../../../../__generated__/types";
import { useMutation } from "@apollo/client/react/hooks";
import { CopyCultivationsToPlotMutation } from "../../planning21.gql";
import { compact } from "lodash";
import { calculateCultivationDates } from "./AddCultivation/AddCultivation";
import { startYear } from "../../../../shared/utils/season.util";
import LocationDropDown from "./AddCultivation/components/LocationDropDown/LocationDropDown";
import styled from "styled-components";
import { Button } from "primereact/button";

interface CopySelectionButtonProps {
  season: Season;
  selection: CultivationsForPlot_cultivations[];
  plotId?: string;
  onSubmit: () => void;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  align-items: center;
  grid-gap: 16px;
  padding: 4px;
`;

const CopySelectionButton = (props: CopySelectionButtonProps) => {
  const [copyCultivationsToPlot] = useMutation<CopyCultivationsToPlot>(CopyCultivationsToPlotMutation, {
    update(cache, {data}) {
      cache.modify({
        fields: {
          cultivations: (previous) => {
            if (data) {
              return [...previous, data.updatePlot?.cultivations];
            } else {
              return previous;
            }
          }
        }
      })
    }
  });

  const [targetPlot, setTargetPlot] = useState<string | undefined>(props.plotId);

  function onSubmit() {
    if (targetPlot) {

      const variables: CopyCultivationsToPlotVariables = {
        plotId: targetPlot,
        createMayCultivationsInput: props.selection.map(cultivation => {
          const varieties: CultivationVarietyCreateInput[] = compact(cultivation.varieties).map(variety => {
            return {
              variety: {connect: {id: variety.variety.id}},
              percentage: variety.percentage,
            };
          });

          let cultivationCreateWithoutPlotInput: CultivationCreateWithoutPlotInput = {
            crop: {connect: {id: cultivation.crop.id}},
            cropTiming: {connect: {id: cultivation.cropTiming.id}},
            interPlanting: cultivation.interPlanting,
            length: cultivation.length,
            transplant: cultivation.transplant,
            pinned: cultivation.pinned,
            varieties: {
              create: varieties,
            },
            ...calculateCultivationDates(startYear(props.season), cultivation),
          };

          if (cultivation.plantSchema?.id) {
            cultivationCreateWithoutPlotInput = {
              ...cultivationCreateWithoutPlotInput,
              // TEST
              plantSchema: {connect: {id: cultivation.plantSchema?.id}},

            };
          }


          return cultivationCreateWithoutPlotInput;
        })
      };

      copyCultivationsToPlot({variables});
      props.onSubmit();
    }
  }

  return <Container>
    <div>
      Dupliceer {props.selection.length} teelt{props.selection.length > 1 ? 'en' : ''} naar
      seizoen {startYear(props.season)} op
      veld
    </div>

    <div className={'input'}>
      <LocationDropDown
        onChange={(plotId) => {
          setTargetPlot(plotId);
        }}
        season={props.season}
        value={targetPlot}
      />
    </div>

    {targetPlot && <Button
      className={'input'}
      label={'Kopiëren'}
      onClick={() => {
        onSubmit();
      }}
    >
    </Button>}
  </Container>
    ;
};

export default CopySelectionButton;
