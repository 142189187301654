import React, { useEffect, useState } from 'react';
import { usePlanning } from "../planning21-context";
import PlantSchemaDrawing from "../../../components/PlantSchemaDrawing/PlantSchemaDrawing";
import { Gridder } from "../../../__generated__/types";
import { chain, keys } from 'lodash';
import { plantAmount } from "../../../utils/planting.util";
import styled from "styled-components";

interface PlantSchemaPageProps {

}

const gridders = {
  [Gridder.TWO_WHEELS]: 2,
  [Gridder.THREE_WHEELS]: 3,
  [Gridder.FOUR_WHEELS]: 4,
  [Gridder.SIX_WHEELS]: 6,
};

const GridderContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;

  h1 {
text-align: center;
  }
`;

const GridderOptions = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin: 8px;
  }
`;

const PlantSchemaPage = (props: PlantSchemaPageProps) => {
  const {plantSchemas} = usePlanning();

  const [plantSchemaList, setPlantSchemaList] = useState<any>([]);

  useEffect(() => {
    const value = chain(plantSchemas)
      .map(plantSchema => {
        return {
          ...plantSchema,
          gridder: plantSchema.gridder ? gridders[plantSchema.gridder] : undefined,
          nrOfPlants: plantAmount(plantSchema, 1, 1, undefined),
          grid: `${plantSchema.distanceBetweenRows}cm x ${plantSchema.distanceInRow}cm`,
        }

      })
      .groupBy('gridder')
      .value();
    setPlantSchemaList(value);

  }, [plantSchemas]);


  return <div
    //value={orderBy(plantSchemaList, ['gridder'], ['asc'])}
  >
    {keys(plantSchemaList).map(gridder => <GridderContainer>
      <h1>{gridder}</h1>
      <GridderOptions>
        {plantSchemaList[gridder].map((x: any) => <div>
          <PlantSchemaDrawing plantSchema={x}/>
        </div>)}
      </GridderOptions>
    </GridderContainer>)}
  </div>;
};

export default PlantSchemaPage;
