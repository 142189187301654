import { map } from "lodash";
import styled from "styled-components";

export const Spaced = styled.div`
  margin: 0 16px;
`;

export const TextButton = styled.span`
  cursor: pointer;
  font-weight: normal;
  font-size: 0.8rem;
  color: var(--secondary-color);

  &:hover {
    color: var(--primary-color);
  }
`;

export const Garden = styled.div`
  margin: 0 16px;
  display: grid;
  grid-template-rows: max-content 1fr;
  height: calc(100vh - 30px);
  .cultivations {
    overflow: scroll;
  }
`;

export const Header = styled.div`
  font-size: 120%;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Planning21HorizontalGrid = styled.div<{ columns: { width: string }[] }>`
  display: grid;
  align-items: stretch;
  grid-template-columns: ${({columns}) => map(columns, 'width').join(' ')};
  //.timeline {
  //  overflow-y: scroll;
  //  overflow-x: scroll;
  //  > div {
  //    min-width: 3000px;
  //    width: 3000px;
  //    max-width: 3000px;
  //    background-color: magenta;
  //  }
  //}
`;

export const RadioButtonWrapper = styled.div`
  padding: 2px 0;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  span:last-child {
    padding-left: 4px;
  }

  &.other {
    opacity: 0.7;
  }
`;
