import moment, { Moment } from "moment";
import React from "react";
import styled from "styled-components";
import { min, sum, times } from "lodash";
import { TimelineData } from "./planning21.interfaces";
import { Tooltip } from "primereact/tooltip";
import { asWeekYear } from "../../../shared/utils/date.utils";

const Today = styled.div`
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  background-color: var(--primary-color);
  z-index: 101;
`;

const CultivationTimeline = styled.div<{ nrOfYears: number }>`
  position: relative;
  border-left: 1px solid black;
  height: 100%;
  overflow: hidden;
`;

const Dividers = styled.div<{ count: number }>`
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(${props => props.count}, 1fr);
  align-items: stretch;
  .divider {
    border-left: 1px solid var(--surface-d);
  }

  .divider-week {
    border-left: 1px solid rgb(56,56,56, 0.33);
  }
  .divider-year {
    border-left: 3px solid var(--surface-d);
  }
`;

const CultivationPeriod = styled.div`
  position: absolute;
  bottom: 0;
  //opacity: 0.3;
  z-index: 200;
  display: grid;
`;

interface TimelineProps {
  id: string;
  cropTiming?: {};
  length?: number;
  bedLength?: number;
  year?: number;
  years: number[];
  showPreseeding?: boolean;
  showFlameweeding: boolean;
  timelineData: TimelineData;
  occupationRatio: number;
  allowWeekSelection?: boolean;
  onStartWeekClick?: (startWeek: Moment) => void;
  onHarvestEndWeekClick?: (harvestEndWeek: Moment) => void;
  selectedStartWeek?: Moment;
  selectedHarvestEndWeek?: Moment;
}

const Something = styled.div<{ columns: number }>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  align-items: stretch;
  .week {
    z-index: 999;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    text-align: center;

    &.selected,
    &:hover {
        opacity: 1;
        color: white;
        font-weight: bold;

        &.harvesting {
          background-color: rgb(34,46,77);
        }

        &.sowingOrPlanting {
          background-color: rgb(66, 110, 67);
        }
    }
  }
`;

const Timeline = (props: TimelineProps) => {
  const {id, years} = props;
  const daysInTimeline = sum(years.map(year => moment(year, 'YYYY').endOf('y').dayOfYear()));

  const firstDayInTimeline = moment(min(years), 'YYYY').startOf('y');
  const daysTilToday = moment().diff(firstDayInTimeline, 'd');

  let harvestEndDate, harvestStartInDaysFromBegin, sowingOrPlantingStartInDaysFromBegin, sowingOrPlantingEndDate;
  const {sowingOrPlantingWindow, harvestWindow} = props.timelineData;
  harvestEndDate = harvestWindow.lastDay.clone();
  harvestStartInDaysFromBegin = harvestWindow.firstDay.diff(firstDayInTimeline, 'd');
  sowingOrPlantingStartInDaysFromBegin = sowingOrPlantingWindow.firstDay.diff(firstDayInTimeline, 'd');
  sowingOrPlantingEndDate = sowingOrPlantingWindow.lastDay;

  const harvestEndInDaysFromBegin = harvestEndDate.diff(firstDayInTimeline, 'd');
  const sowingOrPlantingEndInDaysFromBegin = sowingOrPlantingEndDate.diff(firstDayInTimeline, 'd');

  const months = years.length * 12;
  const top = `${(1 - props.occupationRatio) * 100}%`;

  const preseedingStartInDaysFromBegin = sowingOrPlantingStartInDaysFromBegin - (4 * 7);

  let selectionWeeksForHarvest = 1 + harvestWindow.lastDay.diff(harvestWindow.firstDay, 'w');
  let selectionWeeksForSowingOrPlanting = 1 + sowingOrPlantingWindow.lastDay.diff(sowingOrPlantingWindow.firstDay, 'w');

  return <CultivationTimeline className={'timeline'} key={`Tijdslijn-${id}`} nrOfYears={years.length}>
    <Tooltip target={`.timeline${props.id}`} position={'top'} mouseTrackTop={10}/>
    <>
      {/*<CultivationBedPrepEvent*/}
      {/*  style={{top: top,left: `${(daysTilBedPrep / daysInTimeline) * 100}%`}}/>*/}
      {props.showPreseeding &&
        <CultivationPeriod
          style={{
            backgroundColor: 'brown',
            top: top,
            left: `${(preseedingStartInDaysFromBegin / daysInTimeline) * 100}%`,
            width: `${((sowingOrPlantingStartInDaysFromBegin - preseedingStartInDaysFromBegin) / daysInTimeline) * 100}%`,
          }}
        />
      }
      <CultivationPeriod
        style={{
          top: top,
          backgroundColor: 'rgba(90,116,49,0.3)',
          left: `${(sowingOrPlantingStartInDaysFromBegin / daysInTimeline) * 100}%`,
          width: `${((harvestEndInDaysFromBegin - sowingOrPlantingStartInDaysFromBegin) / daysInTimeline) * 100}%`,
        }}
      />
      <CultivationPeriod
        style={{
          backgroundColor: 'rgb(90, 116, 49)',
          top: top,
          left: `${(sowingOrPlantingStartInDaysFromBegin / daysInTimeline) * 100}%`,
          width: `${((sowingOrPlantingEndInDaysFromBegin - sowingOrPlantingStartInDaysFromBegin) / daysInTimeline) * 100}%`,
        }}
      >
      </CultivationPeriod>
      <CultivationPeriod
        style={{
          backgroundColor: 'rgba(44,61,101)',
          top: top,
          left: `${(harvestStartInDaysFromBegin / daysInTimeline) * 100}%`,
          width: `${((harvestEndInDaysFromBegin - harvestStartInDaysFromBegin) / daysInTimeline) * 100}%`,
        }}
      />
    </>

    <Dividers count={years.length * 12}>{times(months).map((t) => <div key={`divider-${t}`}
                                                                       className="divider"/>)}</Dividers>
    {months <= 12 &&
      <Dividers count={years.length * 52}>{times(52 * years.length).map((t) => <div key={`divider-week-${t}`}
                                                                                    className="divider-week"/>)}</Dividers>}
    {years.length > 1 &&
      <Dividers count={years.length}>{times(years.length).map((t) => <div key={`divider-year-${t}`}
                                                                          className="divider-year"/>)}</Dividers>}
    {props.allowWeekSelection && <>
      <CultivationPeriod
        style={{
          top: top,
          left: `${(sowingOrPlantingStartInDaysFromBegin / daysInTimeline) * 100}%`,
          width: `${((sowingOrPlantingEndInDaysFromBegin - sowingOrPlantingStartInDaysFromBegin) / daysInTimeline) * 100}%`,
        }}
      >
        <Something columns={selectionWeeksForSowingOrPlanting}>
          {times(selectionWeeksForSowingOrPlanting)
            .map(t => {
              const weekMoment = sowingOrPlantingWindow.firstDay.clone().add(t, "w");
              return <div
                data-pr-tooltip={`${weekMoment.isoWeek()}`}
                key={`${asWeekYear(weekMoment)}-${id}`}
                className={`timeline${props.id} week sowingOrPlanting ${(props.selectedStartWeek && props.selectedStartWeek.isSame(weekMoment, "isoWeek")) ? 'selected' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.onStartWeekClick && props.onStartWeekClick(sowingOrPlantingWindow.firstDay.clone().add(t, "w"));
                }}>

              </div>;
            })}
        </Something>
      </CultivationPeriod>
      <CultivationPeriod
        style={{
          backgroundColor: 'rgba(44,61,101)',
          top: top,
          left: `${(harvestStartInDaysFromBegin / daysInTimeline) * 100}%`,
          width: `${((harvestEndInDaysFromBegin - harvestStartInDaysFromBegin) / daysInTimeline) * 100}%`,
        }}
      >
        <Something columns={selectionWeeksForHarvest}>
          {times(selectionWeeksForHarvest)
            .map(t => {
              const weekMoment = harvestWindow.firstDay.clone().add(t, "w");
              return <div
                data-pr-tooltip={`${weekMoment.isoWeek()}`}
                key={`${asWeekYear(weekMoment)}-${id}`}
                className={`timeline${props.id} week harvesting ${(props.selectedHarvestEndWeek && props.selectedHarvestEndWeek.isSame(weekMoment, "isoWeek")) ? 'selected' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.onHarvestEndWeekClick && props.onHarvestEndWeekClick(harvestWindow.firstDay.clone().add(t, 'w'));
                }}>

              </div>;
            })}
        </Something>
      </CultivationPeriod></>
    }

    <Today
      style={{left: `${(daysTilToday / daysInTimeline) * 100}%`}}/>

  </CultivationTimeline>;
};

export default Timeline;

