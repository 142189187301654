import React from 'react';
import { Dropdown } from "primereact/dropdown";
import { useQuery } from "@apollo/client";
import { AllCrops } from "../../../../../../../__generated__/types";
import { AllCropsQuery } from 'pages/21/crop-management/CropManagement.gql';

interface CropDropDownProps {
  value: string | undefined;
  onChange: (cropId: string) => void;
}

const CropDropDown = (props: CropDropDownProps) => {
  const {data} = useQuery<AllCrops>(AllCropsQuery);

  return <Dropdown
    optionLabel={'name'}
    placeholder={"Kies een gewas"}
    className="w-full"
    optionValue={'id'}
    value={props.value}
    options={data?.crops}
    onChange={(e) => props.onChange(e.value)}
  />;
};

export default CropDropDown;
