import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { CropDetails_crop_cropTimings, DeleteCropTiming, PlantOrSeed, Source, UpsertCropTiming21_updateCrop, UpsertCropTiming21Variables } from "../../../../../../__generated__/types";
import { Button } from "primereact/button";
import { useMutation } from "@apollo/client/react/hooks";
import { CropTimingUpsert, DeleteCropTimingMutation } from "../../../../planning21.gql";
import { CultivationField } from "../../../../Planning21/components/AddCultivation/AddCultivation";
import { SelectButton } from "primereact/selectbutton";
import { InputNumber } from "primereact/inputnumber";
import { Actions } from '../Varieties/AddVariety';
import { Toast } from "primereact/toast";

interface AddCropTimingProps {
  cropId: string;
  cropTiming: Partial<CropDetails_crop_cropTimings> | undefined;
  onClose: () => void;
}

const Container = styled.div`
  background-color: var(--surface-c);
  padding: 16px;
  display: grid;
  grid-template-rows: max-content max-content
`;

const modeTexts = {
  add: {
    title: 'Teeltmogelijkheid toevoegen',
    saveButton: 'Toevoegen',
  },
  edit: {
    title: 'Teeltmogelijkheid wijzigen',
    saveButton: 'Wijzigen',
  },
  copy: {
    title: 'Gekopieerde teeltmogelijkheid toevoegen',
    saveButton: 'Toevoegen',
  }
};

enum Mode {
  EDIT = 'edit',
  ADD = 'add',
}

const FieldContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 4px;
  padding: 8px 16px;

  > div {
    display: grid;
    align-items: stretch;
  }
`;

const AddCropTiming = (props: AddCropTimingProps) => {
  const toast = useRef<Toast>(null);
  const [mode, setMode] = useState<Mode>(Mode.ADD);
  const [upsertingCropTiming, setUpsertingCropTiming] = useState<Partial<UpsertCropTiming21Variables>>();
  const [deleteCropTiming] = useMutation<DeleteCropTiming>(DeleteCropTimingMutation);

  const [upsertCropTiming] = useMutation<UpsertCropTiming21_updateCrop>(CropTimingUpsert);

  useEffect(() => {
    if (props.cropTiming) {
      const cropTiming = props.cropTiming;
      setMode(Mode.EDIT);
      setUpsertingCropTiming({
        //id: props.cropTiming.id ? props.cropTiming.id : undefined,
        cropTimingId: cropTiming.id,
        cropId: props.cropId,
        cultivationMethod: cropTiming.cultivationMethod,
        type: cropTiming.type,
        startWeekNumberFrom: cropTiming.startWeekNumberFrom,
        startWeekNumberUntil: cropTiming.startWeekNumberUntil,
        endWeekNumberFrom: cropTiming.endWeekNumberFrom,
        endWeekNumberUntil: cropTiming.endWeekNumberUntil,
        minDuration: cropTiming.minDuration,
        maxDuration: cropTiming.maxDuration,
        source: cropTiming.source,
      });
    } else {
      setMode(Mode.ADD);
      setUpsertingCropTiming({
        cropId: props.cropId,
        cropTimingId: 'new',
        source: Source.GROENTEGEWELD
      });
    }
  }, [props.cropId, props.cropTiming]);

  return <div>
    <Toast ref={toast} />
    <Container>
      <FieldContainer>
        <CultivationField label={'Type'}>
          <SelectButton
            value={upsertingCropTiming && upsertingCropTiming.type}
            options={[{label: 'Planten', value: PlantOrSeed.PLANT}, {label: 'Zaaien', value: PlantOrSeed.SEED}]}
            onChange={(e) => setUpsertingCropTiming({...upsertingCropTiming, type: e.value})}/>
        </CultivationField>
        <CultivationField label={'Locatie'}>
          <SelectButton
            value={upsertingCropTiming && upsertingCropTiming.cultivationMethod}
            options={[{label: 'Beschut', value: 'Beschut'}, {label: 'Openlucht', value: 'Openlucht'}]}
            onChange={(e) => setUpsertingCropTiming({...upsertingCropTiming, cultivationMethod: e.value})}/>
        </CultivationField>
        <CultivationField label={'Zaai/plant periode'}>
          <InputNumber
            value={upsertingCropTiming && upsertingCropTiming.startWeekNumberFrom}
            prefix={'w'}
            onChange={(e) => {
              if(e.value) {
                setUpsertingCropTiming({...upsertingCropTiming, startWeekNumberFrom: e.value});
              }
            }}
          /> - <InputNumber
          value={upsertingCropTiming && upsertingCropTiming.startWeekNumberUntil}
          prefix={'w'}
          onChange={(e) => {
            if(e.value) {
              setUpsertingCropTiming({...upsertingCropTiming, startWeekNumberUntil: e.value});
            }
          }}
        />
        </CultivationField>
        <CultivationField label={'Oogstperiode'}>
          <InputNumber
            value={upsertingCropTiming && upsertingCropTiming.endWeekNumberFrom}
            prefix={'w'}
            onChange={(e) => {
              if(e.value) {
                setUpsertingCropTiming({...upsertingCropTiming, endWeekNumberFrom: e.value});
              }
            }}
          /> - <InputNumber
          value={upsertingCropTiming && upsertingCropTiming.endWeekNumberUntil}
          prefix={'w'}
          onChange={(e) => {
            if(e.value) {
              setUpsertingCropTiming({...upsertingCropTiming, endWeekNumberUntil: e.value});
            }
          }}
        />
        </CultivationField>
      </FieldContainer>

      <Actions>
        <div>
          {upsertingCropTiming && upsertingCropTiming.source === Source.GROENTEGEWELD && upsertingCropTiming.cropTimingId !== 'new' &&
            <Button
              icon="pi pi-trash"
              iconPos="right"
              className={'p-button-danger p-button-rounded p-button-outlined'}
              onClick={() => {
                const cropTimingId = upsertingCropTiming.cropTimingId;
                if (cropTimingId) {
                  deleteCropTiming({variables: {id: cropTimingId}})
                    .catch((error) => {
                      toast.current?.show({severity:'error', summary: 'Gekoppelde teelten', detail:'Er zijn nog teelten gekoppeld aan deze teeltmogelijkheid', life: 5000});
                    })
                    .then((result) => {
                      if(result) {
                        setUpsertingCropTiming(undefined);
                        props.onClose();
                      }
                    });
                }
              }}
            />}
        </div>
        <div>
          <Button
            label={'Annuleren'}
            className={'p-button-text p-button-secondary'}
            onClick={() => {
              setUpsertingCropTiming(undefined);
              props.onClose();
            }}
          />

          <Button

            label={modeTexts[mode].saveButton}
            onClick={() => {
              if (upsertingCropTiming) {
                let variables = {
                  ...upsertingCropTiming,
                  minDuration: (upsertingCropTiming.startWeekNumberUntil || 0) - (upsertingCropTiming.startWeekNumberFrom || 0),
                  maxDuration: (upsertingCropTiming.endWeekNumberUntil || 0) - (upsertingCropTiming.endWeekNumberFrom || 0),
                };
                if (upsertingCropTiming.cropTimingId === 'new') {
                  variables = {...variables, source: Source.GROENTEGEWELD};
                }

                upsertCropTiming({variables}).then(() => {
                  setUpsertingCropTiming(undefined);
                  props.onClose();
                });
              }
            }}
          />
        </div>
      </Actions>
    </Container>
  </div>;
};

export default AddCropTiming;
