import { compact } from "lodash";
import { Permission } from "../__generated__/types";

export const hasPlannerPermission = (user: { permissions: Permission[] } | null) =>
  compact(user?.permissions)
    .filter(permission => permission.startsWith("PLANNER_")).length > 0;

export const hasAdminPermission = (user: { permissions: Permission[] } | null) =>
  hasPermission(user, Permission.PLANNER_ADMIN);

export const hasFarmAdminPermission = (user: { permissions: Permission[] } | null) =>
  hasPermission(user, Permission.PLANNER_FARM_ADMIN);

export const hasPermission = (user: { permissions: Permission[] } | null, permission: Permission) =>
  compact(user?.permissions)
    .findIndex(userPermission => userPermission === permission) > -1;
