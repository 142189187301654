import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({children}: any) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  let domain = process.env.REACT_APP_AUTH_DOMAIN || '';
  let clientId = process.env.REACT_APP_AUTH_CLIENT_ID || '';
  const providerConfig = {
    domain,
    clientId,
    //  ...(authConfig.audience ? {audience: authConfig.audience} : null),
    redirectUri: window.location.origin,
    scope: 'read:roles openid profile create:users',
    audience: `https://${domain}/api/v2/`,
    onRedirectCallback,
    // advancedOptions: {
    //   defaultScope: 'create:users'
    // }
  };

  return (
    <Auth0Provider
      {...providerConfig}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
