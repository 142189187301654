import { GardenFilterValues } from "../planning21.interfaces";
import React, { Suspense, useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { CultivationsForRotationGroup, CultivationsForRotationGroup_cultivations, CultivationsForRotationGroup_rotationGroup, FieldType, Season } from "../../../../__generated__/types";
import { CultivationsForRotationGroupQuery } from "../../planning21.gql";
import { asIso8601 } from "../../../../shared/utils/date.utils";
import moment from "moment";
import { chain, isEmpty, max, min, toInteger } from "lodash";
import CultivationRow from "../components/CultivationRow";
import OutsideAlerter from "../../../../shared/components/outside-alerter";
import { GroupRow } from "../components/planning21-cultivation-group-row";
import { Planning21HorizontalGrid, TextButton } from "../styled-components";
import PlotTimeline from "../components/planning21-plot-timeline";
import { ROTATION_GROUP_PLANNING21_COLUMNS } from "../components/planning21-columns";
import AddCultivation from "../components/AddCultivation/AddCultivation";
import config from "../../../../config";

const RotationGroupInfo = styled.div`
  padding: 2px 4px;
  display: grid;
  grid-template-columns: 1fr max-content;
`;

const TimelineHolder = styled.div`
  height: 80px;
  background: var(--surface-c);
  border: 1px solid var(--surface-d);
  border-bottom: 0;
  position: relative;
  overflow-x: clip;

  .loading {
    padding: 2px;
    font-size: 0.9rem;
  }

  > .timeline {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
 `;

interface RotationGroupRowProps {
  rotationGroupId: string;
  filters: GardenFilterValues;
  fieldType: FieldType;
}

const columns = ROTATION_GROUP_PLANNING21_COLUMNS;
const RotationGroupRow = (props: RotationGroupRowProps) => {
  const [showCultivations, setShowCultivations] = useState<boolean>(false);
  const [filteredCultivations, setFilteredCultivations] = useState<CultivationsForRotationGroup_cultivations[]>([]);

  const {rotationGroupId, filters, fieldType} = props;

  const {data} = useQuery<CultivationsForRotationGroup>(CultivationsForRotationGroupQuery, {
    variables: {
      fields: filters.fields,
      rotationGroupId,
      fieldType,
      season: config.currentPlanningSeason,
      from: `${asIso8601(moment(min(filters.years), 'YYYY').startOf('y'))}`,
      to: `${asIso8601(moment(max(filters.years), 'YYYY').endOf('y'))}`,
    },
  });

  const [rotationGroup, setRotationGroup] = useState<CultivationsForRotationGroup_rotationGroup>();

  useEffect(() => {
    if (data) {
      if (data.rotationGroup) {
        setRotationGroup(data.rotationGroup);

        if (data.cultivations) {
          const cultivations = chain(data.cultivations)
            .compact()
            .value();

          setFilteredCultivations(
            cultivations
              .filter((cultivation: CultivationsForRotationGroup_cultivations) => isEmpty(filters.crops) || (filters.crops.includes(cultivation.crop.id)))
          );
        }
      }
    }
  }, [data, filters]);

  const [mode, setMode] = useState<'read' | 'edit' | 'create'>('read');

  if (rotationGroup) {
    const rotationGroupTotalLength = chain(rotationGroup.rotationSchema)
      .reduce((totalLength, rotationSchema) => {
        if (!rotationSchema.rotationArea.active
          || !rotationSchema.rotationArea.field.active
          || rotationSchema.season !== config.currentPlanningSeason) {
          return totalLength;
        }

        return totalLength + (rotationSchema.rotationArea.plots || []).length * rotationSchema.rotationArea.field.bedLength * (rotationSchema.rotationArea.field.bedWidth === 1.2 ? 1.5 : 1 );
      }, 0)
      .value();

    return <OutsideAlerter onOutsideClicked={() => setMode('read')}>
      <GroupRow className={mode === 'create' ? 'p-shadow-3' : ''}
                onClick={() => setShowCultivations(!showCultivations)}>
        <Planning21HorizontalGrid columns={[
          {
            width: `${chain(columns)
              .filter(c => c.width.endsWith('px'))
              .map(c => toInteger(c.width.replace('px', '')))
              .sum()
              .value()}px`,
          },
          {
            width: '1fr'
          }
        ]}>
          <RotationGroupInfo>
            <div>
              <span>{fieldType === FieldType.GREENHOUSE && 'Serre'} {rotationGroup.name}{} {rotationGroupTotalLength}m</span>
            </div>
          </RotationGroupInfo>
          <TimelineHolder>
            <Suspense fallback={<div className="loading">loading...</div>}>
              <PlotTimeline key={`rotation-group-timeline-${props.fieldType}-${rotationGroup.id}`}
                            id={`rotation-group-timeline-${props.fieldType}-${rotationGroup.id}`}
                            opacity={1}
                            cultivations={filteredCultivations.filter(cultivation => {
                              let plantOrSeedEvent = (cultivation.events || []).find(e => e.type === 'PLANT' || e.type === 'SEED');
                              if (plantOrSeedEvent && plantOrSeedEvent.skipped) {
                                return false;
                              }
                              if(cultivation.interPlanting) {
                                return false;
                              }
                              return true;
                            })}
                            showTopValue
                            color={'rgb(73, 87, 45)'}
                            maxOccupation={rotationGroupTotalLength}/>
              <PlotTimeline key={`rotation-group-timeline-inter-${props.fieldType}-${rotationGroup.id}`}
                            id={`rotation-group-timeline-inter-${props.fieldType}-${rotationGroup.id}`}
                            cultivations={filteredCultivations.filter(cultivation => {
                              let plantOrSeedEvent = (cultivation.events || []).find(e => e.type === 'PLANT' || e.type === 'SEED');
                              if (plantOrSeedEvent && plantOrSeedEvent.skipped) {
                                return false;
                              }
                              if(!cultivation.interPlanting) {
                                return false;
                              }
                              return true;
                            })}
                            color={'#123812'}
                            opacity={1}
                            maxOccupation={rotationGroupTotalLength}/>
            </Suspense>
          </TimelineHolder>
        </Planning21HorizontalGrid>
      </GroupRow>

      {showCultivations && <>
        {filteredCultivations.map((cultivation) =>
          <CultivationRow
            editable={true}
            plotId={cultivation.plot.id}
            columns={columns}
            groupingColumn={columns[2]}
            cultivation={cultivation}
            key={`${rotationGroup.id}-${cultivation.id}`}
          />)}
        {mode === 'create' && <AddCultivation onClose={() => setMode('read')}/>}
        <TextButton onClick={() => setMode('create')} style={{gridArea: 'add'}}>
          + teelt toevoegen
        </TextButton>
      </>
      }
    </OutsideAlerter>;
  } else {
    return <div/>;
  }
};

export default RotationGroupRow;
