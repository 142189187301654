import { gql } from "@apollo/client";

export const SeedsDataQuery = gql`
query SeedsData($season: Season!) {
  crops(
    where: {
      cultivations_some: {
        season: $season
        OR:[
          {
            cropTiming: {
              type: SEED
            }
          },
          {
            transplant: false
            cropTiming: {
              type: PLANT
            }
          }
        ]
      }
    }
  ) {
    id
    name
    plantSchemas(where: {default: true}) {
      default
      plantSchema {
        id
        distanceInRow
        distanceBetweenRows
      }
    }
    cultivations(where: {
      season: $season
      OR:[
          {
            cropTiming: {
              type: SEED
            }
          },
          {
            transplant: false
            cropTiming: {
              type: PLANT
            }
          }
        ]
    }) {
      startDate
      length
      transplant
      plot {
        rotationArea {
          field {
            bedWidth
          }
        }
      }
      cropTiming {
        type
      }
      varieties {
        percentage
        variety {
          id
          name
          alternativeCropName
          code
          gramsPerRowMeter
          supplier {
            id
            name
          }
        }
      }
      plantSchema {
        plantSchema {
          distanceInRow
          distanceBetweenRows
        }
      }
    }
  }
}`;
