import React, { useEffect, useState } from 'react';
import { InputNumber } from "primereact/inputnumber";
import { plantAmount } from "../../../../utils/planting.util";
import PlantSchemaDrawing from "../../../../components/PlantSchemaDrawing/PlantSchemaDrawing";
import { CropDetails, CropDetails_crop_plantSchemas, CultivationDetails } from "../../../../__generated__/types";
import styled from "styled-components";
import { compact, keyBy, sortBy } from 'lodash';
import { useQuery } from "@apollo/client";
import { CropDetailsQuery } from "../../crop-management/CropManagement.gql";
import { plantSchemaForCalculations } from "../../planning21-context";
import { CultivationDetailsQuery } from "./AddCultivation/AddCultivation.gql";
import { Button } from 'primereact/button';
import classNames from "classnames";

const PlantSchemas = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: row;

  > * {
    margin-right: 8px;
  }

  .selected {
    border: 1px solid var(--primary-color);
    rgb(129 199 132 / 0.1)
  }

  .default {
    position: relative;

    &::after {
      content: 'Standaard';
      bottom: -20px;
      color: var(--secondary-color);
      left: 50%;
      transform: translate(-50%, 0);
      position: absolute;
    }
  }
`;

interface AmountSelectionProps {
  cropId: string | undefined;
  onChange: (length: number) => void;
  onCropPlantSchemaChange: (cropPlantSchemaId: string | undefined) => void;
  length: number;
  bedLength: number;
  bedWidth: number;
  cultivationCropPlantSchemaId?: string;
  parentId: string | undefined | null;
}

const AmountSelection = (props: AmountSelectionProps) => {
  const {data} = useQuery<CropDetails>(CropDetailsQuery, {
    variables: {
      id: props.cropId || ""
    }
  });

  const {data: parentCultivationData} = useQuery<CultivationDetails>(CultivationDetailsQuery, {
    variables: {id: props.parentId || ""}
  });

  const [nrOfPlants, setNrOfPlants] = useState<number>();
  const [availablePlantSchemas, setAvailablePlantSchemas] = useState<{
    [key: string]: CropDetails_crop_plantSchemas
  }>({});
  const [length, setLength] = useState<number>();

  const [activeCropPlantSchema, setActiveCropPlantSchema] = useState<CropDetails_crop_plantSchemas>();

  useEffect(() => {
    setLength(props.length);
    let plantSchemaForParent = parentCultivationData?.cultivation && plantSchemaForCalculations(parentCultivationData.cultivation);

    activeCropPlantSchema && setNrOfPlants(plantAmount(activeCropPlantSchema.plantSchema, props.length, props.bedWidth, plantSchemaForParent?.plantSchema));
  }, [props.length, activeCropPlantSchema, parentCultivationData]);

  useEffect(() => {
    if (data?.crop) {
      setAvailablePlantSchemas(keyBy(data?.crop.plantSchemas, 'id'));
      setActiveCropPlantSchema(compact(data?.crop.plantSchemas).find(plantSchema => props.cultivationCropPlantSchemaId ? props.cultivationCropPlantSchemaId === plantSchema.id : plantSchema.default))
    }
  }, [data, props.cultivationCropPlantSchemaId]);

  return <div className="flex flex-column w-full">

    <div className="flex align-items-center mb-2">
      <InputNumber
        suffix={'m'}
        inputClassName='w-6rem'
        value={length}
        onChange={(e) => {
          if (e.value) {
            let plantSchemaForParent = parentCultivationData?.cultivation && plantSchemaForCalculations(parentCultivationData.cultivation);
            const length = e.value;
            //updateCultivation({length});
            setLength(length);
            setNrOfPlants(length && activeCropPlantSchema ? plantAmount(activeCropPlantSchema.plantSchema, length, props.bedWidth, plantSchemaForParent?.plantSchema) : 0);
            //props.onChange(length);
          }
        }}
      /> <span className="pl-2">= {nrOfPlants} planten</span>
    </div>
    {length && (props.bedLength > 0) && (length > props.bedLength) && <div className="text-red-500">
      <i className="pi pi-exclamation-triangle"></i> Dit bed is maar {props.bedLength}m, verlaag het aantal meter
      </div>
    }

    {!props.cultivationCropPlantSchemaId && !availablePlantSchemas &&
      <div>Ken een standaard plantschema toe voor dit gewas.</div>
    }

    <PlantSchemas>
      {sortBy(availablePlantSchemas, p => p.id !== props.cultivationCropPlantSchemaId).map(p =>
        <div key={p.id} className={
          classNames(
            {
              "selected": (props.cultivationCropPlantSchemaId && props.cultivationCropPlantSchemaId === p.id)
                || (!props.cultivationCropPlantSchemaId && p.default)
            },
          )
        }
             onClick={() => props.onCropPlantSchemaChange(p.default ? undefined : p.id)}>
          <PlantSchemaDrawing
            plantSchema={p.plantSchema}
            hideNrOfPlants
            bedWidth={props.bedWidth}
          />
        </div>
      )}
    </PlantSchemas>

    <div className="flex justify-content-end mt-2">
      <Button
        label={'Bevestigen'}
        onClick={() => {
          if (length) {
            props.onChange(length);
          }
        }}
      />
    </div>
  </div>;
};

export default AmountSelection;
