import React, { useRef } from 'react';
import { ContextMenu } from "primereact/contextmenu";
import { map, sum } from "lodash";
import TimelineRowContextMenu from "./TimelineRowContextMenu";
import { ScrollingTimelineRow, TIMELINE_VIEW_CONFIG } from "./ScrollingTimeline";
import { Button } from "primereact/button";
import TimelineRowHeaderExpansion from "./TimelineRowHeaderExpansion";

interface TimelineRowHeaderProps<T> {
  rowData: ScrollingTimelineRow<T>;
  rowIndex: number;
  onToggleExpansion: (rowId: string) => void;
  rowHeights: number[];
  isExpanded: boolean;
  cultivations: {
    startDate: string;
  }[];
}

const TimelineRowHeader = <T extends {
  id: string,
  cultivations: { id: string, startDate: any, varieties: any[] | null }[] | null
}, >(props: TimelineRowHeaderProps<T>) => {
  const {rowData} = props;

  const cm = useRef<ContextMenu | null>(null);

  return <div
    className={`item bg-gray-${props.rowIndex % 2 ? 7 : 8}00 border-bottom-1 border-gray-600`}
    style={{
      top: `${sum(props.rowHeights.slice(0, props.rowIndex - 1))}px`,
      height: `${props.rowHeights[props.rowIndex]}px`
    }}
  >
    <TimelineRowContextMenu
      cropId={props.rowData.type === 'crop' ? props.rowData.id : undefined}
      plotId={props.rowData.type === 'plot' ? props.rowData.id : undefined}
      menuRef={cm}/>
    <div className="w-full h-full">
      <div className="flex align-items-center justify-content-between p-2"
           style={{height: TIMELINE_VIEW_CONFIG.rowHeight}}>
        <div className="flex align-items-center">
          <i
            className={`text-gray-500 text-xl cursor-pointer pi pi-chevron-${props.isExpanded ? 'down' : 'right'}`}
            onClick={() => {
              props.onToggleExpansion(rowData.id)
            }}
          />
          <div className="ml-2">{rowData.label} ({props.cultivations.length})</div>
        </div>
        <Button
          icon="pi pi-ellipsis-h"
          className="text-color-secondary"
          link
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.persist();
            cm.current?.show(e);
          }}
        />
      </div>
      {props.isExpanded &&
        <TimelineRowHeaderExpansion rowHeights={props.rowHeights} cultivationIds={map(props.cultivations, 'id')}/>}
    </div>
  </div>;
};

export default TimelineRowHeader;
