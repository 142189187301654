import React, { createRef, useEffect, useState } from 'react';
import XLSX from "xlsx";
import { FileUpload } from "primereact/fileupload";
import { ceil, floor, range, set, uniqBy } from "lodash";
import { Button } from "primereact/button";
import { OrderData } from "./planning21-orders";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styled from "styled-components";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import AddCultivation from '../Planning21/components/AddCultivation/AddCultivation';

interface DeKosterProps {
  data: OrderData[];
  onClose: () => void;
}

interface XlsRow {
  cropName: string,
  varietyName: string,
  size: string,
  amount: number,
  extra: string,
  price: string,
  0: string | number,
  1: string | number,
  2: string | number,
  3: string | number,
  4: string | number,
  5: string | number,
  6: string | number,
  7: string | number,
  8: string | number,
  9: string | number,
  10: string | number,
  11: string | number,
  12: string | number,
  13: string | number,
  14: string | number,
  15: string | number,
  16: string | number,
  17: string | number,
  18: string | number,
  19: string | number,
  20: string | number,
  21: string | number,
  22: string | number,
  23: string | number,
  24: string | number,
  25: string | number,
  26: string | number,
  27: string | number,
  28: string | number,
  29: string | number,
  30: string | number,
  31: string | number,
  32: string | number,
  33: string | number,
  34: string | number,
  35: string | number,
  36: string | number,
  37: string | number,
  38: string | number,
  39: string | number,
  40: string | number,
  41: string | number,
  42: string | number,
  43: string | number,
  44: string | number,
  45: string | number,
  46: string | number,
  47: string | number,
  48: string | number,
  49: string | number,
  50: string | number,
  51: string | number,
  52: string | number,
}

const handleOrderFormXls = (file: File, headers: string[], onLoaded: (data: XlsRow[]) => void) => {
  /* Boilerplate to set up FileReader */
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e: any) => {
    /* Parse data */

    const bstr = e.target.result;
    const wb = XLSX.read(bstr, {type: rABS ? 'binary' : 'array'});
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data: XlsRow[] = XLSX.utils.sheet_to_json(ws, {
      header: headers
    });
    debugger;
    onLoaded(data);
    /* Update state */
  };
  if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
};

function ec(r: any, c: any) {
  return XLSX.utils.encode_cell({r: r, c: c});
}

function delete_row(ws: any, row_index: any) {
  var variable = XLSX.utils.decode_range(ws["!ref"])
  for (var R = row_index; R < variable.e.r; ++R) {
    for (var C = variable.s.c; C <= variable.e.c; ++C) {
      ws[ec(R, C)] = ws[ec(R + 1, C)];
    }
  }
  variable.e.r--
  ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
}

const rowMatcherByName = (row: XlsRow, order: OrderData) => {
  if (!order.cultivationVarietyName) {
    return false;
  }
  let names: string[] = [row.varietyName];
  if (row.varietyName.startsWith('B ')) {
    let varietyNameWithoutPrefix = row.varietyName.substring(2, row.varietyName.length);
    names.push(varietyNameWithoutPrefix);
  }

  let hasVarietyNameMatch = names.includes(order.cultivationVarietyName);
  let hasSoilBlockTypeMatch = order.cultivationVarietySoilBlockType ? row.size === order.cultivationVarietySoilBlockType : true;

  return hasVarietyNameMatch && hasSoilBlockTypeMatch;
};

const rowMatcherBySoilBlockType = (row: XlsRow, order: OrderData) => {
  if (!order.cultivationVarietySoilBlockType) {
    return false;
  }

  return row.size === order.cultivationVarietySoilBlockType;
};

const Weeks = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
`;

const DeKoster = (props: DeKosterProps) => {
  const [active, setActive] = useState<{
    cultivationId: string,
    cropId: string,
  } | undefined>();
  const fileUploadRef = createRef<FileUpload>();
  const [xlsRows, setXlsRows] = useState<XlsRow[]>([]);
  const [unmatchedOrderData, setUnmatchedOrderData] = useState<OrderData[]>([]);
  const [matchedXlsRows, setMatchedXlsRows] = useState<XlsRow[]>([]);
  const [firstWeekInOrderXls, setFirstWeekInOrderXls] = useState<number | undefined>();
  const [headers, setHeaders] = useState<string[]>([]);
  const [lastOrderWeekToGenerate, setLastOrderWeekToGenerate] = useState<number | undefined>();

  useEffect(() => {
    if (firstWeekInOrderXls) {
      setHeaders(["cropName", "varietyName", "size", "amount", "extra", "price",
        ...range(firstWeekInOrderXls, 52).map(t => `${t}`)
      ]);
    } else {
      setHeaders([]);
    }
  }, [firstWeekInOrderXls]);

  const onGenerateOrderXls = () => {
    if (xlsRows.length > 0 && lastOrderWeekToGenerate) {
      props.data
        .filter(o => o.cultivationVarietySupplierName === 'De Koster')
        .filter(o => o.week <= lastOrderWeekToGenerate)
        .map(order => {
          let matchingXlsRowsByVarietyName = xlsRows.filter((xlsRow) => rowMatcherByName(xlsRow, order));
          let matchCountByVarietyName = matchingXlsRowsByVarietyName.length;
          let matchingXlsRowsBySoilBlockType = matchingXlsRowsByVarietyName.filter((xlsRow) => rowMatcherBySoilBlockType(xlsRow, order));
          let matchCountBySoilBlockType = matchingXlsRowsBySoilBlockType.length;
          if (order.cultivationVarietyName) {

            if (matchCountByVarietyName === 1) {
              let rowIdx = xlsRows.findIndex((xlsRow) => rowMatcherByName(xlsRow, order));
              const matchingRow = xlsRows[rowIdx];
              if (order.cultivationAmount) {
                let orderAmount = (order.cultivationAmount - (order.orderAmount || 0)) / matchingRow.amount;

                let decimalPart = orderAmount - Math.floor(orderAmount);
                if (decimalPart < 0.5) {
                  orderAmount = floor(orderAmount);
                } else {
                  orderAmount = ceil(orderAmount);
                }

                if (orderAmount > 0) {
                  let updatedRows = xlsRows;
                  set(updatedRows, `[${rowIdx}].${order.week}`, orderAmount);
                  setXlsRows(updatedRows);
                  setMatchedXlsRows(value => ([...value, updatedRows[rowIdx]]));
                }
              }
            } else if (matchCountBySoilBlockType === 1) {
              let rowIdx = xlsRows.findIndex((xlsRow) => rowMatcherByName(xlsRow, order));
              const matchingRow = xlsRows[rowIdx];
              if (order.cultivationAmount) {
                let orderAmount = (order.cultivationAmount - (order.orderAmount || 0)) / matchingRow.amount;

                let decimalPart = orderAmount - Math.floor(orderAmount);
                if (decimalPart < 0.5) {
                  orderAmount = floor(orderAmount);
                } else {
                  orderAmount = ceil(orderAmount);
                }

                if (orderAmount > 0) {
                  let updatedRows = xlsRows;
                  set(updatedRows, `[${rowIdx}].${order.week}`, orderAmount);
                  setXlsRows(updatedRows);
                  setMatchedXlsRows(value => ([...value, updatedRows[rowIdx]]));
                }
              }
            } else {
              setUnmatchedOrderData(value => ([...value, order]));
            }
          }
        });
    }
  };
  return <Dialog
    header="De Koster bestelformulier"
    visible
    style={{width: '40rem'}}
    onHide={() => props.onClose()}
  >
    <div className="mb-2 border-1 border-primary-800 p-4">
      <div className="p-2 text-sm text-color-secondary border-bottom-1 border-primary-900 mb-2">Geef eerst een
        weeknummer in vooraleer je een bestand kan uploaden.
      </div>

      <div className="flex align-items-center">
        <div className="text-right mr-2 w-20rem">
          <label htmlFor="orderFormUpload">Weeknummer van eerste bestelkolom</label>
        </div>
        <div>
          <InputNumber
            value={firstWeekInOrderXls}
            prefix={'Week '}
            onChange={(e) => {
              if (e.value) {
                setFirstWeekInOrderXls(e.value);
              }
            }}
          />
        </div>
      </div>

      <div className="mt-3 flex align-items-center">
        <div className="text-right mr-2 w-20rem">
          <label htmlFor="orderFormUpload">De Koster .xls-bestand</label>
        </div>
        <div>
          <FileUpload
            disabled={headers.length === 0}
            ref={fileUploadRef}
            customUpload
            name="bestelling"
            auto
            uploadHandler={(e) => {
              handleOrderFormXls(e.files[0], headers, setXlsRows);
              fileUploadRef.current && fileUploadRef.current.clear();
            }}/>
        </div>

      </div>

      <div className="flex align-items-center">
        <div className="text-right mr-2 w-20rem">
          <label htmlFor="orderFormUpload">Bestelling genereren tot week</label>
        </div>
        <div>
          <InputNumber
            value={lastOrderWeekToGenerate}
            prefix={'Week '}
            onChange={(e) => {
              if (e.value) {
                setLastOrderWeekToGenerate(e.value);
              }
            }}
          />
        </div>
      </div>
      <div className="mt-3 flex align-items-center">
        <div className="text-right mr-2 w-20rem">

        </div>
        <div>
          <Button
            disabled={xlsRows.length === 0}
            label="Genereer bestellijst"
            onClick={onGenerateOrderXls}
          />
        </div>
      </div>

    </div>

    {xlsRows.length > 0 && <Button
      label={'Download bestellijst'}
      onClick={() => {
        let workSheet = XLSX.utils.json_to_sheet(xlsRows, {
          header: headers
        });

        delete_row(workSheet, 0);
        let workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
        XLSX.writeFile(workBook, 'bestelling.xlsx');
      }}
    />}

    <div>
      <div>
        <div className="font-medium text-lg">Niet te bestellen</div>
        <DataTable
          value={unmatchedOrderData}
        >
          <Column header={'Week'} field={'week'}
                  body={x => `w${x.week}`}/>
          <Column header={'Gewas'} field={'cultivationCropName'}
                  body={x => x.cultivationVarietyAlternativeCropName || x.cultivationCropName}/>
          <Column header={'Ras'} field={'cultivationVarietyName'}
                  body={x => <div>
                    <div>{x.cultivationVarietyName}</div>
                    <div className="text-color-secondary text-xs">{x.cultivationVarietySoilBlockType}</div>
                  </div>}/>
          <Column body={x => <Button
            text
            label={'Wijzig teelt'}
            onClick={() => setActive({cultivationId: x.cultivationId, cropId: x.cultivationCropId})}
          />

          }/>
        </DataTable>
      </div>
      {active &&
        <AddCultivation crop={{id: active.cropId}} cultivationId={active.cultivationId} onClose={() => setActive(undefined)}/>}

      {/*<div>*/}
      {/*  <div className="font-medium text-lg">Opgenomen in bestelling</div>*/}
      {/*  <DataTable*/}
      {/*    value={uniqBy(matchedXlsRows, (xlsRow) => xlsRow.cropName + '-' + xlsRow.varietyName)}*/}
      {/*  >*/}
      {/*    <Column header={'Soortnaam'} field={'cropName'}/>*/}
      {/*    <Column header={'Variëteit'} field={'varietyName'}/>*/}
      {/*    <Column header={'Potmaat'} field={'size'}/>*/}
      {/*    <Column header={'Pl/Kist'} field={'amount'}/>*/}
      {/*    <Column header={'#'} body={xlsRow => {*/}
      {/*      return <Weeks>*/}
      {/*        {range(1, 53).map(weekNr => {*/}
      {/*          if (xlsRow[weekNr] > 0) {*/}
      {/*            return <>*/}
      {/*              <span>w{weekNr}</span>*/}
      {/*              <span>{xlsRow[weekNr]}</span>*/}
      {/*            </>;*/}
      {/*          }*/}
      {/*        })}*/}
      {/*      </Weeks>*/}
      {/*    }}/>*/}
      {/*  </DataTable>*/}
      {/*</div>*/}
    </div>

  </Dialog>;
};

export default DeKoster;
