import React from 'react';
import { CultivationTask, iconForType, readable } from "../Tasks";
import { CultivationEventType } from "../../../../__generated__/types";
import { compact } from "lodash";
import { asDayDdMmYyyy } from "../../../../shared/utils/date.utils";
import IconWithTooltip from "../../planning21-icon-with-tooltip";
import classNames from "classnames";
import moment from "moment";
import TaskOverviewItemActions from "./TaskOverviewItemActions";
import TaskOverviewItemDetail from "./TaskOverviewItemDetail";

interface TaskOverviewItemProps {
  task: CultivationTask;
  asRow: boolean;
}

const TaskOverviewItem = (props: TaskOverviewItemProps) => {
  const {task} = props;

  let varieties = compact(task.varieties);

  //LOCATION
  const {field, number} = task.plot.rotationArea;
  let prefixName = `${field.name}-${number}`;

  const icon = iconForType(task.taskType);
  let isOverdue = task.taskDate.isBefore(moment(), 'day');
  return <div className={classNames(
    'm-2 mt-0 border-1 border-200',
    {'border-red-500 border-2': isOverdue}
  )}>
    <div className="flex justify-content-between align-items-stretch">
      <div className={classNames(
          "flex flex-row align-items-center text-base font-bold surface-100 p-1 px-2 w-min white-space-nowrap",
        )}>
        {task.taskType !== CultivationEventType.PRESEED &&
          <>
            <div className="pr-2">
              {icon && <IconWithTooltip size={25} name={icon} text={readable(task.taskType)} id={`icon_${task.id}`}/>}
            </div>
            {prefixName}
          </>
        }
        {task.taskType === CultivationEventType.PRESEED && <div>Voorzaaien</div>}
      </div>
      <div className={classNames(
        "p-1 w-min white-space-nowrap",
        {'text-red-500': isOverdue}
      )}>
        {task.taskDate && asDayDdMmYyyy(task.taskDate)}
      </div>
    </div>

    <div className="p-2 grid align-items-center">
      <div className="col-12 text-l mb-1">
        {varieties.map(cultivationVariety => {
          const {alternativeCropName, name} = cultivationVariety.variety;
          return (
            <div
              key={`Gewas-${task.id}-${cultivationVariety.id}`}
              className="md:flex md:flex-column"
            >
              <span className="text-base font-bold mr-2">{task.crop && (cultivationVariety && alternativeCropName ? alternativeCropName : task.crop.name)}</span>
              <span className="font-light font-light text-400">{name}</span>
            </div>
          );
        })}
        {varieties.length === 0 && `${task.crop.name}: ONBEKEND RAS`}
      </div>

      <div className={"col-12"}>
        <TaskOverviewItemDetail task={task}/>
      </div>

      <div className="col-12">
        <TaskOverviewItemActions task={task}/>
      </div>
    </div>
  </div>;
};

export default TaskOverviewItem;
