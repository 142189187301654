import gql from "graphql-tag";

export const UpdateFieldOrderNumberMutation = gql`
mutation UpdateFieldOrderNumber($id: ID!, $orderNumber: Int!) {
  updateField(
    where: {id: $id}
    data: {orderNumber: $orderNumber}
  ) {
    id
    orderNumber
  }
}
`;

export const CreateFieldMutation = gql`
mutation CreateField(
  $createData: FieldCreateInput!,
) {
  createField(
    data: $createData
  ) {
    id
  }
}
`;

export const GetLastFieldOrderNumberQuery = gql`
query GetLastFieldOrderNumber($locationId: ID!, $fieldType: FieldType!) {
  fields(
    where: {
      location: {id: $locationId}
      type: $fieldType
      orderNumber_not: null
    }
    orderBy: orderNumber_DESC
    first: 1
  ) {
    orderNumber
  }
}
`;

export const GetFarmQuery = gql`
query GetFarm($id: ID!) {
  farm(where: {id: $id}) {
    id
    name
    locations {
      id
      name
    }
  }
}
`;

export const FieldsOverviewQuery = gql`
query FieldsOverview($locationId: ID!) {
  fields(
    where: {
      location: {
        id: $locationId
      }
    }
    orderBy: orderNumber_ASC
  ) {
    id
    name
    prefix
    type
    bedLength
    bedWidth
    orderNumber
    active
    location {
      id
    }
  }
}
`;

export const FieldDetailsQuery = gql`
query FieldDetailsData($id: ID!) {
  field(where: {id: $id}) {
    id
    name
    prefix
    type
    bedLength
    bedWidth
    orderNumber
    active
    rotationAreas(orderBy: number_ASC) {
      id
      number
      active
      rotationSchema {
        id
        season
        rotationGroup {
          id
          name
          cropGroups {
            id
            name
          }
        }
      }
      plots(orderBy: number_ASC) {
        id
        number
        active
      }
    }
  }
}
`;

export const UpsertRotationSchemaMutation = gql`
mutation UpsertRotationSchemaForRotationArea(
  $fieldId: ID!
  $rotationSchemaId: ID
  $rotationGroupId: ID!
  $rotationAreaId: ID!
  $season: Season!
) {
  updateField(
    where: {id: $fieldId}
    data: {
      rotationAreas: {
        update: {
          where: {id: $rotationAreaId}
          data: {
            rotationSchema: {
              upsert: {
                where: {
                  id: $rotationSchemaId
                }
                create: {
                  season: $season
                  rotationGroup: {
                    connect: {id: $rotationGroupId}
                  }
                }
                update: {
                  season: $season
                  rotationGroup: {
                    connect: {id: $rotationGroupId}
                  }
                }
              }
            }
          }
        }
      }
    }
  ) {
  	id
    rotationAreas {
      id
      rotationSchema {
        id
        season
        rotationGroup {
          id
          name
        }
      }
    }
  }
}
`;

export const UpdateRotationAreaStatusMutation = gql`
mutation UpdateRotationAreaStatus(
  $fieldId: ID!
  $rotationAreaId: ID!
  $active: Boolean!
) {
  updateField(
    where: {id: $fieldId}
    data: {
      rotationAreas: {
        update: {
          where: {id: $rotationAreaId}
          data: {
            active: $active
          }
        }
      }
    }
  ) {
  	id
    rotationAreas {
      id
      active
    }
  }
}

`;
