import React, { useEffect, useState } from 'react';
import { Planning21HorizontalGrid } from "../styled-components";
import styled from "styled-components";
import { chain, compact, difference, get, isEmpty, keys, map, orderBy, range, sortBy, without } from "lodash";
import SortingItem from "./planning21-garden-filter-sorting-grouping";
import { DEFAULT_FILTER_OPTIONS, DEFAULT_SORTING, FilterOptionValues, GardenFilterValues } from "../planning21.interfaces";
import { Planning21Column, Planning21Group } from "./planning21-columns";
import { DEFAULT_YEARS } from "../../planning21-context";
import { SelectButton } from "primereact/selectbutton";
import { AllCrops, AllCrops_crops, FiltersData, } from "../../../../__generated__/types";
import { Checkbox } from "primereact/checkbox";
import { useQuery } from "@apollo/client";
import { FiltersDataQuery } from "../../planning21.gql";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { AllCropsQuery } from "../../crop-management/CropManagement.gql";
import Seasons from "./GardenFilters/Seasons";
import { useUserObject } from "../../../../context/UserContext";
import { useSearchParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

interface GardenFilterProps<T> {
  value: GardenFilterValues;
  group: Planning21Group;
  onFilter: (filterUpdate: Partial<GardenFilterValues>) => void;
  onGroup?: (groupingColumn: Planning21Group) => void;
  columns: Planning21Column<T>[];
  noFilters?: boolean;
}

const Container = styled(Planning21HorizontalGrid)`
  padding-bottom: 4px;
  grid-template-rows: max-content;
  align-items: end;
`;

export const VisibilityOptions = styled.div`
  display: flex;
  flex-direction: row;

  .option {
    margin-right: 16px;

    label {
      margin-left: 4px;
    }
  }
`;

const GardenFilters = <T extends any>(props: GardenFilterProps<T>) => {
  const {activeFarm} = useUserObject();
  const [filterOptionValues, setFilterOptionValues] = useState<FilterOptionValues>(DEFAULT_FILTER_OPTIONS);
  const [cropGroups, setCropGroups] = useState<{ [key: string]: AllCrops_crops[] }>({});
  const [sortedColumns, setSortedColumn] = useState<string[] | undefined>(keys(DEFAULT_SORTING));
  const [group, setGroup] = useState<Planning21Group>(props.group);

  const {data: cropsData} = useQuery<AllCrops>(AllCropsQuery);
  const {data: filtersData} = useQuery<FiltersData>(FiltersDataQuery, {variables: {farm: activeFarm?.id}});
  const {value, onFilter} = props;
  useEffect(() => {
    if (cropsData?.crops) {
      const filterOptionValues = {
        rotationGroups: filtersData && filtersData.rotationGroups ? sortBy(compact(filtersData.rotationGroups), 'name') : [],
        crops: sortBy(compact(cropsData?.crops), 'name'),
        fields: filtersData && filtersData.fields ? sortBy(compact(filtersData.fields), 'name') : [],
        weeks: range(1, 54).map(n => ({name: `w${n}`, id: n})),
        years: DEFAULT_YEARS.map(y => ({name: `${y}`, id: y})),
      };

      setCropGroups(chain(cropsData?.crops)
        .compact()
        .groupBy('group.id')
        .value());
      setFilterOptionValues(filterOptionValues);
      let newVar = {
        ...value,
        rotationGroups: map(filterOptionValues.rotationGroups, 'id'),
        fields: map(filterOptionValues.fields, 'id'),
      };

      onFilter(newVar);
    }
  }, [cropsData, filtersData]);

  const filterHandler = (values: string[], field: keyof FilterOptionValues) => {

    props.onFilter({
      [field]: values,
    });
  };

  return <>
    {props.noFilters
      ? <div/>
      : <div className="flex align-items-stretch">
        <div className="mb-3">
          <div className="uppercase text-sm text-color-secondary mb-2">Groeperen op:</div>
          <Dropdown
            options={[
              {label: 'Locatie', value: Planning21Group.PLOT},
              {label: 'Gewas', value: Planning21Group.CROP},
              {label: 'Teeltwisselingsgroep', value: Planning21Group.ROTATION_GROUP},
              {label: 'Datum', value: Planning21Group.DATE},
            ]}
            value={props.group}
            onChange={(e: any) => {
              // setGroup(e.value);
              props.onGroup && props.onGroup(e.value);
            }}
          />

        </div>
        <div className="pl-3">
          <div className="uppercase text-sm text-color-secondary mb-2">Teeltwisselingsgroepen:</div>
          <VisibilityOptions>
            <MultiSelect
              value={props.value.rotationGroups}
              onChange={(e) => {
                filterHandler(e.value, 'rotationGroups');
              }}
              options={filterOptionValues.rotationGroups}
              optionLabel="name"
              optionValue="id"
              placeholder="Kies groepen"
              maxSelectedLabels={3}
              className="w-full md:w-20rem"
            />
          </VisibilityOptions>
        </div>

        <div className="pl-3">
          <div className="uppercase text-sm text-color-secondary mb-2">Velden:</div>
          <div>
            <MultiSelect
              filter
              // selectAll
              // showSelectAll
              optionValue={'id'}
              optionLabel={'name'}
              maxSelectedLabels={3}
              value={props.value.fields}
              options={filterOptionValues.fields}
              onChange={(e) => filterHandler(e.value, 'fields')}
            />
            <Button className={'p-button-link'} label={'Serres'} onClick={() => {
              filterHandler(filterOptionValues.fields.filter(f => f.name.startsWith('Serre')).map(f => f.id), 'fields');
            }}/>
          </div>
        </div>
        <div className="pl-3">
          <div className="uppercase text-sm text-color-secondary mb-2">Seizoenen:</div>
          <Seasons
            value={props.value.years}
            years={filterOptionValues.years}
            onChange={filterHandler}
          />
        </div>
        {/*<VisibilityOptions>
        {filterOptionValues.years.map(year =>
          <div className="option" key={year.id}>
            <Checkbox id={`year${year.id}`}
                      value={year.id}
                      checked={props.value.years.includes(year.id)}
                      onChange={(e) => {
                        if (e.checked) {
                          filterHandler(orderBy([...props.value.years, e.value], 'id'), 'years');
                        } else {
                          filterHandler(without(props.value.years, e.value), 'years');
                        }
                      }}/>
            <label htmlFor={`years${year.id}`}>{year.name}</label>

          </div>
        )}
      </VisibilityOptions>*/}
        {/*<div>*/}
        {/*  <Button*/}
        {/*    label={'Filters toepassen'}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>}
    <Container columns={props.columns}>
      {props.columns.map((sortingGroupingItem) => {
        return <SortingItem
          cropGroups={sortingGroupingItem.filterField === 'crops' ? cropGroups : {}}
          key={sortingGroupingItem.key}
          label={sortingGroupingItem.label}
          subHeaders={sortingGroupingItem.subHeaders}
          filterValue={sortingGroupingItem.filterField ? get(props.value, sortingGroupingItem.filterField, []) : []}
          onFilter={values => {
            sortingGroupingItem.filterField && filterHandler(values, sortingGroupingItem.filterField);
          }}
          sortFields={sortingGroupingItem.sortFields}
          filterOptionValues={sortingGroupingItem.filterOptionValuesProperty && get(filterOptionValues, sortingGroupingItem.filterOptionValuesProperty)}
          onSort={() => setSortedColumn(sortingGroupingItem.sortFields)}
          isFiltered={sortingGroupingItem.filterField && !isEmpty(props.value[sortingGroupingItem.filterField])}
          isSorted={sortedColumns && difference(sortingGroupingItem.sortFields, sortedColumns).length === 0}
        />;
      })}
    </Container>
  </>;
};

export default GardenFilters;
