import { CultivationsForCrop_cultivations } from "../../../../__generated__/types";

export const hasIncorrectRotationGroup = (cultivation: CultivationsForCrop_cultivations) => {
  const {season, plot, crop} = cultivation;
  let cropGroupIdForCultivation = crop.group?.id;

  let rotationSchemaForPlot = plot.rotationArea.rotationSchema?.find(schema => schema.season === season);
  let cropGroupIdsForPlot = (rotationSchemaForPlot?.rotationGroup.cropGroups || []).map(cropGroup => cropGroup.id);

  let hasIncorrectRotationGroup = false;

  if (cultivation.plot.rotationArea.field.name.startsWith("Serre ") &&
    cultivation.crop.group?.name.startsWith("Vruchtgewassen")) {
    return false;
  }

  if (cropGroupIdForCultivation) {
    hasIncorrectRotationGroup = !cropGroupIdsForPlot.includes(cropGroupIdForCultivation);
  }
  return hasIncorrectRotationGroup;
}
