import React from 'react';
import { Button } from "primereact/button";
import styled from 'styled-components';
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

interface MarkAsDoneButtonProps {
  isDone: boolean;
  type: 'done' | 'skip';
  onClick: () => void;
}

const CheckButton = styled(Button)`
  .pi {
    font-size: 1rem;
  }
`;

const MarkAsDoneButton = (props: MarkAsDoneButtonProps) => {
  const types = {
    done: {
      label: 'Uitgevoerd',
      icon: 'pi-check-circle',
      className: 'p-button-success',
      onClick: props.onClick,
    },
    skip: {
      label: 'Overslaan',
      icon: 'pi-ban',
      className: 'p-button-danger p-button-text',
      onClick: (e: any) =>
        confirmPopup({
          target: e.currentTarget,
          message: 'Ben je zeker dat je deze taak wil overslaan?',
          icon: 'pi pi-exclamation-triangle',
          acceptClassName: 'p-button-danger',
          acceptLabel: 'Ja',
          rejectLabel: 'Nee',
          accept: props.onClick
        })
    },
  };
  const {icon, className, label, onClick} = types[props.type];
  return <>
    <ConfirmPopup/>
    <CheckButton
    disabled={props.isDone}
    label={label}
    icon={`pi ${icon}`}
    className={`${className} ${props.isDone ? '' : 'p-button-plain'}`}
    onClick={onClick}
  /></>;
};

export default MarkAsDoneButton;
