import React, { useEffect, useState } from 'react';
import { chain, compact, flatten, orderBy, values } from 'lodash';
import { Moment } from 'moment';
import { CultivationEventType, CultivationsForTasks, CultivationsForTasks_cultivations, PlantOrSeed, Season } from "../../../__generated__/types";
import { momentFromIso8601 } from "../../../shared/utils/date.utils";
import styled from "styled-components";
import TaskList from "./components/TaskList";
import { useQuery } from "@apollo/client";
import { CultivationsForTasksQuery } from "./Tasks.gql";
import { notEmpty } from "../../../shared/utils/stream.utils";
import { calculateSowingOrPlantingWindow } from "../Planning21/planning21-mutations";
import config from "../../../config";
import TaskOverview from './components/TaskOverview';
import { useUserObject } from "../../../context/UserContext";

interface TasksProps {

}

export const TRANSPLANT_OFFSET_WEEKS = 5;

export const Type = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 4px;
  align-items: center;
  font-weight: bold;
  font-size: 1.3em;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color-secondary);
  margin-bottom: 32px;

  div:first-child {
    font-weight: bold;
    font-size: 1.2em;
  }
`;

export const readable = (type: CultivationEventType) => {
  if (type === CultivationEventType.PLANT) {
    return 'Planten';
  } else if (type === CultivationEventType.SEED) {
    return 'Zaaien';
  } else if (type === CultivationEventType.PRESEED) {
    return 'Voorzaaien';
  } else if (type === CultivationEventType.FLAMEWEED) {
    return 'Flameweeden';
  }
  return '';
};
export const iconForType = (type: CultivationEventType) => {
  if (type === CultivationEventType.PLANT) {
    return 'plant';
  } else if (type === CultivationEventType.SEED) {
    return 'seed';
  } else if (type === CultivationEventType.PRESEED) {
    return 'preseed';
  } else if (type === CultivationEventType.FLAMEWEED) {
    return 'flame';
  }
};

export interface CultivationTask extends CultivationsForTasks_cultivations {
  taskType: CultivationEventType;
  taskWeekNumber: number;
  taskDate: Moment;
  taskStatus: string;
}

const Tasks = (props: TasksProps) => {
  const {activeFarm} = useUserObject();
  const {data} = useQuery<CultivationsForTasks>(CultivationsForTasksQuery, {
    variables: {
      season: Season.S_2024_2025,
      farm: activeFarm?.id
    }
  });

  const [tasks, setTasks] = useState<{ [key: number]: CultivationTask[] }>({});
  const [cultivations, setCultivations] = useState<CultivationsForTasks_cultivations[]>([]);

  useEffect(() => {
    if (data && data.cultivations) {
      setCultivations(data.cultivations.filter(notEmpty));
    }
  }, [data]);

  //const [middleWeek, setMiddleWeek] = useState<number>(moment().isoWeek());

  useEffect(() => {
    const tasksReduced = chain(cultivations)
      .reduce((allTasks: CultivationTask[], cultivation: CultivationsForTasks_cultivations): CultivationTask[] => {
        const {cropTiming, transplant} = cultivation;

        const sowingOrPlantingWindow = calculateSowingOrPlantingWindow(cultivation.cropTiming, cultivation.startDate,);
        if (sowingOrPlantingWindow.firstDay.year() < 2021) {
          return allTasks;
        }

        const tasksForCultivation = [];

        const preseedWeek = sowingOrPlantingWindow.firstDay.isoWeek() - TRANSPLANT_OFFSET_WEEKS;
        //const firstVisibleWeek = middleWeek - weekOffset;
        //const lastVisibleWeek = middleWeek + weekOffset;

        const hasPreseedingEvent = (cultivation: { events: { type: CultivationEventType }[] | null }) => {
          return compact(cultivation.events).findIndex(cultivationEvent => cultivationEvent.type === CultivationEventType.PRESEED) > -1;
        };

        const hasSeedOrPlantEvent = (cultivation: { cropTiming: { type: PlantOrSeed }, events: { type: CultivationEventType }[] | null }) => {
          return compact(cultivation.events)
            .findIndex(cultivationEvent => cultivationEvent.type === (cultivation.cropTiming.type === PlantOrSeed.SEED ? CultivationEventType.SEED : CultivationEventType.PLANT)) > -1;
        };

        const hasFlameweedEvent = (cultivation: { events: { date: string, type: CultivationEventType }[] | null }, date: Moment) => {
          return compact(cultivation.events)
            .findIndex(cultivationEvent =>
              cultivationEvent.type === CultivationEventType.FLAMEWEED
              && momentFromIso8601(cultivationEvent.date).isSame(date, 'day')
            ) > -1;
        };

        if (
          !hasPreseedingEvent(cultivation)
          &&
          (!transplant && cropTiming.type === PlantOrSeed.PLANT)
          //&& (preseedWeek > firstVisibleWeek && preseedWeek < lastVisibleWeek)
        ) { // Eigen opkweek and preseed week is visible range
          tasksForCultivation.push(
            {
              taskType: CultivationEventType.PRESEED,
              taskWeekNumber: preseedWeek,
              taskDate: sowingOrPlantingWindow.firstDay.clone().subtract(7 * TRANSPLANT_OFFSET_WEEKS, 'day'),
              taskStatus: hasPreseedingEvent(cultivation) ? 'done' : 'todo',
              ...cultivation,
            }
          );
        }

        if (
          !hasSeedOrPlantEvent(cultivation)
          //&& sowingOrPlantingWindow.firstDay.isoWeek() > firstVisibleWeek
          //&& sowingOrPlantingWindow.firstDay.isoWeek() < lastVisibleWeek
        ) {
          tasksForCultivation.push(
            {
              taskType: cultivation.cropTiming.type === PlantOrSeed.SEED ? CultivationEventType.SEED : CultivationEventType.PLANT,
              taskWeekNumber: sowingOrPlantingWindow.firstDay.isoWeek(),
              taskDate: sowingOrPlantingWindow.firstDay.clone(),
              taskStatus: hasSeedOrPlantEvent(cultivation) ? 'done' : 'todo',
              ...cultivation,
            }
          );
        }

        const seedingEvent = (cultivation.events || [])
          .find(event => event.type === CultivationEventType.SEED);

        if (['Busselwortel', 'Bewaarwortel'].includes(cultivation.crop.name)) {
          [5, 10].forEach(offset => {
            const flameweedDay = seedingEvent ? momentFromIso8601(seedingEvent.date).add(offset, 'day') : sowingOrPlantingWindow.firstDay.clone().add(offset, 'day');
            if (
              !hasFlameweedEvent(cultivation, flameweedDay)
              // && flameweedDay.isoWeek() > firstVisibleWeek
              // && flameweedDay.isoWeek() < lastVisibleWeek
            ) {
              tasksForCultivation.push(
                {
                  taskType: CultivationEventType.FLAMEWEED,
                  taskWeekNumber: flameweedDay.isoWeek(),
                  taskDate: flameweedDay,
                  taskStatus: hasFlameweedEvent(cultivation, flameweedDay) ? 'done' : 'todo',
                  ...cultivation,
                }
              );
            }
          })
        }

        return [...allTasks, ...tasksForCultivation];
      }, [])
      .groupBy('taskWeekNumber')
      .mapValues(tasks => orderBy(tasks,
        [
          'plot.rotationArea.field.prefix',
          'plot.rotationArea.number',
          'plot.plot.number',
        ], ['asc', 'asc', 'asc']
      ))
      .value();
    setTasks(tasksReduced);
    // }, [cultivations, middleWeek]);
  }, [cultivations]);

  return <div>
    <TaskOverview tasks={flatten(values(tasks))}/>
    {/*<TaskList tasks={flatten(values(tasks))}/>*/}
  </div>;
};

export default Tasks;
