import React, { useEffect, useState } from 'react';
import { compact } from 'lodash';
import { useQuery } from "@apollo/client";
import { CropDetails, CropDetails_crop_plantSchemas, CropDetails_crop_varieties, CultivationDetails, PlantOrSeed } from "../../../../../../__generated__/types";
import { CultivationDetailsQuery } from "../AddCultivation.gql";
import { plantSchemaForCalculations } from "../../../../planning21-context";
import { CropDetailsQuery } from "../../../../crop-management/CropManagement.gql";
import { nrOfRows, plantAmount } from "../../../../../../utils/planting.util";
import { formatDoubleDigit } from "../../../../../../shared/utils/currency.utils";
import PlantSchemaDrawing from "../../../../../../components/PlantSchemaDrawing/PlantSchemaDrawing";

interface QuantityValuesPropsProps {
  cropId: string | undefined;
  length: number;
  bedWidth: number;
  cultivationCropPlantSchemaId?: string;
  parentId: string | undefined | null;
  cultivationType: PlantOrSeed;
  selectedVarieties: {
    varietyId: string,
    percentage: number,
    variety: CropDetails_crop_varieties | undefined;
  }[];
  transplant: boolean | null | undefined;
}

const QuantityValuesProps = (props: QuantityValuesPropsProps) => {
  const {data} = useQuery<CropDetails>(CropDetailsQuery, {
    variables: {
      id: props.cropId || ""
    }
  });

  const {data: parentCultivationData} = useQuery<CultivationDetails>(CultivationDetailsQuery, {
    variables: {id: props.parentId || ""}
  });

  const [nrOfPlants, setNrOfPlants] = useState<number>();
  const [activeCropPlantSchema, setActiveCropPlantSchema] = useState<CropDetails_crop_plantSchemas>();

  useEffect(() => {
    let plantSchemaForParent = parentCultivationData?.cultivation && plantSchemaForCalculations(parentCultivationData.cultivation);

    activeCropPlantSchema && setNrOfPlants(plantAmount(activeCropPlantSchema.plantSchema, props.length, props.bedWidth, plantSchemaForParent?.plantSchema));
  }, [props.length, activeCropPlantSchema, parentCultivationData]);

  useEffect(() => {
    if (data?.crop) {
      setActiveCropPlantSchema(compact(data?.crop.plantSchemas).find(plantSchema => props.cultivationCropPlantSchemaId ? props.cultivationCropPlantSchemaId === plantSchema.id : plantSchema.default))
    }
  }, [data, props.cultivationCropPlantSchemaId]);

  return <div className="flex flex-row align-items-center">
    <div className="grid mt-1">
      <div className="col-6 text-right font-bold text-lg">{props.length}</div>
      <div className="col-6 font-bold text-color-secondary">meter</div>
      {(props.cultivationType === PlantOrSeed.PLANT
        || (props.cultivationType === PlantOrSeed.SEED && !props.transplant)) && <>
        <div className="col-6 text-right font-bold text-lg">{nrOfPlants}</div>
        <div className="col-6 font-bold text-color-secondary">planten</div>
      </>}
      {props.cultivationType === PlantOrSeed.SEED && <>
        {props.selectedVarieties.map(variety => {
          let nrOfRowsPerBedWidth = 0;
          let totalGramsForVariety = 0;
          if (activeCropPlantSchema && variety.variety?.gramsPerRowMeter) {
            nrOfRowsPerBedWidth = nrOfRows(props.bedWidth, activeCropPlantSchema?.plantSchema.distanceBetweenRows);
            totalGramsForVariety = ((props.length * variety.percentage) * nrOfRowsPerBedWidth) * variety.variety.gramsPerRowMeter;
          }
          return <>
            <div className="col-6 text-right font-bold text-lg">
              {totalGramsForVariety > 0 ? formatDoubleDigit(totalGramsForVariety) : <span>??</span>}
            </div>
            <div className="col-6 font-bold text-color-secondary">gram</div>
          </>;
        })}
      </>}
    </div>
    <div>
      {activeCropPlantSchema &&
        <PlantSchemaDrawing
          plantSchema={activeCropPlantSchema.plantSchema}
          hideNrOfPlants
          bedWidth={props.bedWidth}
        />}
    </div>
  </div>;
};

export default QuantityValuesProps;
