import React from 'react';
import { TIMELINE_VIEW_CONFIG } from "./ScrollingTimeline";

interface TimelineOccupationProps {
  maxBarHeight: number;
  maxOccupation: number;
  nrOfDaysInTimeline: number;
  occupation: { start: number, end: number, length: number };
}

const TimelineOccupation = (props: TimelineOccupationProps) => {
  const {occupation, maxBarHeight, maxOccupation, nrOfDaysInTimeline} = props;

  return <div
    style={{
      top: 0,
      height: TIMELINE_VIEW_CONFIG.rowHeight,
      left: `${(occupation.start / nrOfDaysInTimeline) * 100}%`,
      right: `${100 - (occupation.end / nrOfDaysInTimeline) * 100}%`
    }}
    className="absolute flex align-items-stretch justify-content-between z-5"
  >
    <div
      className="shadow-1 bg-green-600 border-1 border-green-300 p-0 absolute left-0 right-0 top-50 border-round-md flex align-items-center justify-content-center"
      style={{
        //height: TIMELINE_VIEW_CONFIG.cultivationBarHeight,
        height: (occupation.length / maxOccupation) * maxBarHeight,
        transform: 'translateY(-50%)',
      }}
    >
      {/*{cultivation.nrOfPlants}*/}
    </div>
  </div>;
};

export default TimelineOccupation;
