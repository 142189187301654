import React from 'react';

interface LogicalCropGroupSelectProps {
  logicalCropGroup: string | undefined;
  onLogicalCropGroupChange: (logicalCropGroup: string | undefined) => void;
}

const LogicalCropGroupSelect = (props: LogicalCropGroupSelectProps) => {
  return <div>{props.logicalCropGroup}</div>;
};

export default LogicalCropGroupSelect;
