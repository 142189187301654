import React, { Fragment } from 'react';
import styled from "styled-components";
import { usePlanning } from "../../planning21-context";
import { chain, findIndex, get, max, min, set, sum } from "lodash";
import moment from "moment";
import { Tooltip } from "primereact/tooltip";
import { calculateHarvestWindow } from "../planning21-mutations";

interface CropTimelineProps {
  maxOccupation: number | undefined;
  cultivations: CultivationForCropTimeline[];
  cropId: string;
}

export interface CultivationForCropTimeline {
  cropTiming: {
    startWeekNumberFrom: number;
    startWeekNumberUntil: number;
    endWeekNumberFrom: number;
    endWeekNumberUntil: number;
  };
  length: number;
  startDate: string;
  harvestEndDate: string;
}

const Container = styled.div`
  height: 100%;
  position: relative;
`;

const Occupation = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgb(90, 116, 49);
  opacity: 0.3;
  z-index: 1;

  &:hover {
    background-color: rgba(90,116,49,0.4);
  }
`;

export const plotOccupation = (cultivations: CultivationForCropTimeline[], years: number[]) => {
  const lastDayInTimeline = moment(max(years), 'YYYY').endOf('y');
  const firstDayInTimeline = moment(min(years), 'YYYY').startOf('y');

  // [day]: [start, end value of that single day, e.g.: 20,0]
  const message = chain(cultivations)
    .orderBy(['startDateUnix'], ['asc'])
    .reduce((occupation: { day: number, length: number }[], cultivation: CultivationForCropTimeline) => {
      let updatedOccupation = occupation;

      const harvestWindow = calculateHarvestWindow(cultivation.cropTiming, cultivation.startDate, cultivation.harvestEndDate);

      if (cultivation
        && cultivation.length
      ) {
        const firstDay = harvestWindow.firstDay.clone();
        let lastDay = harvestWindow.lastDay.clone();


        while (lastDay.diff(firstDay, 'days') >= 0) {
          if (firstDay.isBetween(firstDay, lastDay, 'd', '[]') &&
            firstDay.isBetween(firstDay, lastDayInTimeline, 'd', '[]')
          ) {
            const day = firstDay.diff(firstDayInTimeline, 'd');

            const occupationIdxForDay = findIndex(updatedOccupation, ['day', day]);
            if (occupationIdxForDay > -1) {
              const updatedLength = updatedOccupation[occupationIdxForDay].length + cultivation.length;
              updatedOccupation = set(updatedOccupation, `[${occupationIdxForDay}].length`, updatedLength);
            } else {
              updatedOccupation = [
                ...updatedOccupation,
                {
                  day,
                  length: cultivation.length,
                }
              ];
            }
          }
          firstDay.add(1, 'days')
        }
      }
      return updatedOccupation;
    }, [])
    .value();

  const convertToWindows = (data: { day: number, length: number }[]) => {
    let result: { start: number, end: number, length: number }[] = [];
    data.forEach(v => {
      let rl = result.length;
      if (rl === 0 || result[rl - 1].end + 1 !== v.day || result[rl - 1].length !== v.length) {
        result.push({start: v.day, end: v.day, length: v.length});
      } else result[rl - 1].end += 1;
    });
    return result;
  };

  return convertToWindows(message);
};

const CropTimeline = (props: CropTimelineProps) => {
  const {years} = usePlanning();
  const daysInTimeline = sum(years.map(year => moment(year, 'YYYY').endOf('y').dayOfYear()));

  return <Container>
    <Tooltip target={`.occupation${props.cropId}`} position={'top'} mouseTrackTop={10}/>
    {plotOccupation(props.cultivations, years).map(occupation => {
      const startPercentage = `${occupation.start / daysInTimeline * 100}%`;
      const endPercentage = `${(occupation.end - occupation.start + 1) / daysInTimeline * 100}%`;

      const occupationRatio = props.maxOccupation ? occupation.length / props.maxOccupation : 1;

      const top = (1 - (occupationRatio <= 1 ? occupationRatio : 1)) * 100;
      return <Fragment key={`plot-timeline-wrapper-${get(props.cultivations, '[0].plot.id')}-${startPercentage}`}>
        {occupationRatio > 1 && <Occupation
          className={`occupation${props.cropId}`}
          style={{
            cursor: 'pointer',
            left: startPercentage,
            width: endPercentage,
            top: `-${(occupationRatio - 1) * 100}%`,
            bottom: '100%',
            backgroundColor: 'red',
            opacity: 0.7,
          }}
        />}
        <Occupation
          data-pr-tooltip={`${occupation.length}m`}
          className={`occupation${props.cropId}`}
          style={{
            left: startPercentage,
            width: endPercentage,
            top: `${top}%`,
          }}
        />
      </Fragment>;
    })}
    {/*<Chart type="line" data={basicData} options={options} height={'20px'}/>*/}
  </Container>;
};

export default CropTimeline;
