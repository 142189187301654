import React, { useRef, useState } from 'react';
import { Moment } from "moment";
import { compact, get } from "lodash";
import { TIMELINE_VIEW_CONFIG } from "./ScrollingTimeline";
import { ContextMenu } from "primereact/contextmenu";
import config from "../../../../config";
import { MenuItem, MenuItemCommandEvent } from "primereact/menuitem";
import { Button } from "primereact/button";
import CultivationRowContextMenu from "./CultivationRowContextMenu";
import { asDayDdMmYyyy, momentFromIso8601 } from "../../../../shared/utils/date.utils";
import TimelineEvent from "./TimelineEvent";

interface CultivationRowTimelineProps {
  alwaysShowMetadata: boolean;
  style: any;
  timelineRange: Moment[];
  cultivation: {
    events: {}[] | null,
    id: string;
    varieties: {}[] | null;
    sowingOrPlantingWindow: {
      firstDay: Moment;
      lastDay: Moment;
    },
    harvestWindow: {
      firstDay: Moment;
      lastDay: Moment;
    };
  };
}

export const nrOfDaysInTimeline = (range: Moment[]) => {
  const [timelineStart, timelineEnd] = range;
  return timelineEnd.diff(timelineStart, "d") + 1;
};

const CultivationRowTimeline = (props: CultivationRowTimelineProps) => {
  const [showMeta, setShowMeta] = useState<boolean>(props.alwaysShowMetadata);
  const {cultivation} = props;
  const [timelineStart, timelineEnd] = props.timelineRange;

  const varieties = compact(cultivation.varieties);
  const harvestWindow = cultivation.harvestWindow;
  const sowingOrPlantingWindow = cultivation.sowingOrPlantingWindow;

  const lastHarvestDate = harvestWindow.lastDay.endOf('isoWeek');
  let nrOfDaysFromTimelineStartToHarvestStart = harvestWindow.firstDay.diff(timelineStart, "d");
  let nrOfDaysFromTimelineStartToHarvestEnd = lastHarvestDate.diff(timelineStart, "d");
  let nrOfDaysFromTimelineStartToPlantingStart = sowingOrPlantingWindow.firstDay.diff(timelineStart, "d");
  let nrOfDaysFromTimelineStartToPlantingEnd = sowingOrPlantingWindow.lastDay.endOf('isoWeek').diff(timelineStart, "d");

  let nrOfDaysFromTimelineEndToPlantingStart = timelineEnd.diff(sowingOrPlantingWindow.firstDay, "d");
  let nrOfDaysFromTimelineEndToHarvestStart = timelineEnd.diff(harvestWindow.firstDay, "d");

  const cm = useRef<ContextMenu | null>(null);

  return <div
    style={{
      height: (TIMELINE_VIEW_CONFIG.detailRowHeight * (varieties.length || 1)),
      //top: `${rowHeights[cultivationIdx]}px`,
      left: 0,
      right: 0,
      ...props.style,
    }}
    className="absolute hover:surface-hover z-4 cursor-pointer"
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
    onMouseEnter={() => {
      if (!props.alwaysShowMetadata) {
        setShowMeta(true);
      }
    }}
    onMouseLeave={() => {
      if (!props.alwaysShowMetadata) {
        setShowMeta(false);
      }
    }}
  >
    {showMeta && <div className="z-5">
      <div
        style={{
          top: '50%',
          right: `${((nrOfDaysFromTimelineEndToPlantingStart) / nrOfDaysInTimeline(props.timelineRange)) * 100}%`,
          transform: 'translateY(-50%)',
        }}
        className="absolute text-gray-500 text-xs"
      >
        w{sowingOrPlantingWindow.firstDay.isoWeek()}
      </div>
      {/*<div*/}
      {/*  style={{*/}
      {/*    bottom: 0,*/}
      {/*    left: `${((nrOfDaysFromTimelineStartToPlantingEnd) / nrOfDaysInTimeline(props.timelineRange)) * 100}%`,*/}
      {/*    transform: 'translateX(-50%) translateY(+50%)',*/}
      {/*  }}*/}
      {/*  className="absolute text-color-secondary text-xs"*/}
      {/*>*/}
      {/*  w{sowingOrPlantingWindow.lastDay.isoWeek()}*/}
      {/*</div>*/}

      <div
        style={{
          top: '50%',
          right: `${((nrOfDaysFromTimelineEndToHarvestStart) / nrOfDaysInTimeline(props.timelineRange)) * 100}%`,
          transform: 'translateY(-50%)',
        }}
        className="absolute text-gray-500 text-xs"
      >
        {harvestWindow.firstDay.diff(sowingOrPlantingWindow.firstDay,'d')}d <br/>
        w{harvestWindow.firstDay.isoWeek()}
      </div>
      <div
        style={{
          top: '50%',
          // right: `${(nrOfDaysFromTimelineEndToHarvestStart / nrOfDaysInTimeline(props.timelineRange)) * 100}%`,
          left: `${(((nrOfDaysFromTimelineStartToHarvestEnd)) / nrOfDaysInTimeline(props.timelineRange)) * 100}%`,
          transform: 'translateY(-50%)',
        }}
        className="absolute text-gray-500 text-xs"
      >
        {lastHarvestDate.diff(sowingOrPlantingWindow.firstDay,'d')}d <br/>
        w{lastHarvestDate.isoWeek()}
      </div>

    </div>}

    {/*HARVEST WINDOD*/}
    <div
      style={{
        height: (TIMELINE_VIEW_CONFIG.detailRowHeight * (varieties.length || 1)),
        top: 0,
        left: `${(nrOfDaysFromTimelineStartToHarvestStart / nrOfDaysInTimeline(props.timelineRange)) * 100}%`,
        right: `${100 - (nrOfDaysFromTimelineStartToHarvestEnd / nrOfDaysInTimeline(props.timelineRange)) * 100}%`
        //width: (nrOfDaysFromTimelineStartToHarvestEnd - nrOfDaysFromTimelineStartToHarvestStart) / nrOfDaysInTimeline(props.timelineRange) * 100 + '%'
      }}
      className="absolute flex align-items-center justify-content-center p-0 px-1 z-3"
      onClick={() => {
      }}
    >
      <CultivationRowContextMenu cultivationId={cultivation.id} menuRef={cm}/>
      <div
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          e.persist();
          cm.current?.show(e);
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="w-full bg-green-200 border-round-md"
        style={{
          height: TIMELINE_VIEW_CONFIG.cultivationBarHeight,
        }}>
      </div>
    </div>

    {/*PLANTING WINDODW*/}
    <div
      style={{
        height: (TIMELINE_VIEW_CONFIG.detailRowHeight * (varieties.length || 1)),
        top: 0,
        left: `${(nrOfDaysFromTimelineStartToPlantingStart / nrOfDaysInTimeline(props.timelineRange)) * 100}%`,
        //right: `${100 - (nrOfDaysFromTimelineStartToPlantingEnd / nrOfDaysInTimeline(props.timelineRange)) * 100}%`
        width: (nrOfDaysFromTimelineStartToPlantingEnd - nrOfDaysFromTimelineStartToPlantingStart) / nrOfDaysInTimeline(props.timelineRange) * 100 + '%'
      }}
      className="absolute flex align-items-center justify-content-center p-0 px-1 z-3"
      onClick={() => {
      }}
    >
      <CultivationRowContextMenu cultivationId={cultivation.id} menuRef={cm}/>
      <div
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          e.persist();
          cm.current?.show(e);
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="w-full bg-blue-200 border-round-md"
        style={{
          height: TIMELINE_VIEW_CONFIG.cultivationBarHeight,
        }}>
      </div>
    </div>

    {/* EVENTS*/}
    {
      compact(cultivation.events).map(event => {
        return <TimelineEvent
          key={`cultivation-timeline-event-${cultivation.id}-${get(event, 'id')}`}
          event={event}
          rowHeight={(TIMELINE_VIEW_CONFIG.detailRowHeight * (varieties.length || 1))}
          timelineRange={props.timelineRange}
        />;
      })
    }
  </div>;
};

export default CultivationRowTimeline;
