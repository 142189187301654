import React, { useState } from 'react';
import RowGroupHeaderTemplate from "./RowGroupHeaderTemplate";
import { FieldsOverview, FieldsOverview_fields, FieldType, UpdateFieldOrderNumber } from "../../../../__generated__/types";
import FieldDetails from "./FieldDetails";
import { Column } from "primereact/column";
import { DataTable, DataTableExpandedRows } from "primereact/datatable";
import { useMutation, useQuery } from "@apollo/client";
import { FieldsOverviewQuery, UpdateFieldOrderNumberMutation } from "../FieldManagement.gql";
import { compact, last, sortBy } from "lodash";
import UpsertFieldDialog from "./UpsertFieldDialog";
import { Button } from "primereact/button";

interface FieldsListProps {
  location: string
}

const FieldsList = (props: FieldsListProps) => {
  const {data} = useQuery<FieldsOverview>(FieldsOverviewQuery, {
    variables: {
      locationId: props.location
    }
  });

  const [updateFieldOrderNumber] = useMutation<UpdateFieldOrderNumber>(UpdateFieldOrderNumberMutation, {
    update(cache, {data}) {
      cache.modify({
        fields: {
          fields: (previous) => {
            return data ? [...previous, data.updateField] : previous;
          }
        }
      })
    }
  });

  const [fields, setFields] = useState<FieldsOverview_fields[]>([]);
  const [fieldInitFor, setFieldInitFor] = useState<{ locationId?: string, type?: FieldType, orderNumber?: number } | undefined>();
  const [expandedRows, setExpandedRows] = useState<FieldsOverview_fields[]>([]);

  const onRowReorder = (value: FieldsOverview_fields[]) => {
    value.forEach((field, index) => {
      updateFieldOrderNumber({
        variables: {
          id: field.id,
          orderNumber: index,
        }
      });
    });
  };

  return <>
    {fieldInitFor && <UpsertFieldDialog
      onHide={() => setFieldInitFor(undefined)}
      field={fieldInitFor}
    />}
    <Button
      label={'+ Veld toevoegen'}
      onClick={() => {
        setFieldInitFor({
          locationId: props.location,
        });
      }}
    />
    <DataTable

      size={'small'}
      value={sortBy(compact(data?.fields), 'type')}
      reorderableColumns
      rowGroupMode={'subheader'}
      groupRowsBy={'type'}
      rowGroupHeaderTemplate={(tableData: FieldsOverview_fields) =>
        <RowGroupHeaderTemplate
          fields={compact(data?.fields)}
          type={tableData.type}
          onAddFieldInit={() => {
            let lastOrderNumber = last(compact(data?.fields).filter(f => f.type === tableData.type))?.orderNumber;
            setFieldInitFor({
              locationId: props.location,
              type: tableData.type,
              orderNumber: lastOrderNumber ? (lastOrderNumber + 1) : undefined
            });
          }}/>}
      onRowReorder={(e) => {

        onRowReorder(e.value.filter((f: FieldsOverview_fields) => f.type === e.value[e.dragIndex].type));
      }}
      expandedRows={expandedRows}
      onRowToggle={(e) => {
        setExpandedRows(e.data as FieldsOverview_fields[]);
      }}
      rowExpansionTemplate={(field: FieldsOverview_fields) => <FieldDetails id={field.id}/>}
    >
      <Column expander style={{width: '3em'}}/>
      <Column field="name" header="Naam"/>
      <Column field="active" header="In gebruik" body={field => field.active ? 'Ja' : 'Nee'}/>
      <Column field="orderNumber" header="Nummer"/>
      <Column field="bedLength" header="Bed lengte" body={(field: FieldsOverview_fields) => {
        return `${field.bedLength}m`;
      }}/>
      <Column field="bedWidth" header="Bed breedte" body={(field: FieldsOverview_fields) => {
        return `${field.bedWidth}m`;
      }}/>
      <Column rowReorder style={{width: '3em'}}/>
    </DataTable>
  </>;
};

export default FieldsList;
