import React, { useEffect, useState } from 'react';
import { GardenFilterValues } from "../planning21.interfaces";
import { useQuery } from "@apollo/client";
import { FieldType, RotationGroups, RotationGroups_rotationGroups } from "../../../../__generated__/types";
import { RotationGroupsQuery } from 'pages/21/planning21.gql';
import { chain, isEmpty } from "lodash";
import RotationGroupRow from "../rows/RotationGroupRow";
import { useUserObject } from "../../../../context/UserContext";

interface RotationGroupBasedPlanningProps {
  gardenFilters: GardenFilterValues;
}

const RotationGroupBasedPlanning = (props: RotationGroupBasedPlanningProps) => {
  const {activeFarm} = useUserObject();

  const {data} = useQuery<RotationGroups>(RotationGroupsQuery, {
    variables: {
      farm: activeFarm?.id,
    }
  });
  const [rotationGroups, setRotationGroups] = useState<RotationGroups_rotationGroups[]>([]);

  useEffect(() => {
    if (data) {

      let value = chain(data.rotationGroups)
        .compact()
        .filter(crop => isEmpty(props.gardenFilters.rotationGroups) || (props.gardenFilters.rotationGroups.includes(crop.id)))
        .value();
      setRotationGroups(
        value);
    }
  }, [data, props.gardenFilters]);
  return <>
    {[FieldType.GREENHOUSE, FieldType.OPEN].map(fieldType => rotationGroups.map(rotationGroup =>
      <RotationGroupRow
        fieldType={fieldType}
        key={`${rotationGroup.id}-${fieldType}`}
        rotationGroupId={rotationGroup.id}
        filters={props.gardenFilters}
      />))}
  </>;
};

export default RotationGroupBasedPlanning;
