import React from 'react';
import { CultivationEventType } from "../../../../../__generated__/types";

interface StatusColumnProps {
  cultivation: {
    id: string;
    pinned: boolean,
    events: {
      type: CultivationEventType,
      skipped: boolean,
    }[] | null;
  }
}

const StatusColumn = (props: StatusColumnProps) => {
  let hasPlantOrSeedEvent = (props.cultivation.events || []).findIndex(e => e.type === 'PLANT' || (e.type === 'SEED' && !e.skipped)) > -1;
  return <div>
    {!hasPlantOrSeedEvent && props.cultivation.pinned &&
      <i className={'pi pi-lock'}/>}

    {hasPlantOrSeedEvent &&
      <i className={'pi pi-check'} onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}/>}
  </div>
    ;
};

export default StatusColumn;
