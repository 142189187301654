import React from 'react';
import { Dropdown } from "primereact/dropdown";
import { CultivationsForPlot_cultivations } from "../../../../../../../__generated__/types";
import { compact } from "lodash";

interface CultivationDropDownProps {
  options: CultivationsForPlot_cultivations[] | undefined;
  value: string | undefined | null;
  onChange: (cropId: string) => void;
}

const CultivationDropDown = (props: CultivationDropDownProps) => {
  return <Dropdown
    value={props.value}
    options={compact(props.options).map(cultivation => {
      return {
        value: cultivation.id,
        label: cultivation.crop.name,
      }
    })}
    onChange={(e) => props.onChange(e.value)}
  />;
};

export default CultivationDropDown;
