import React, { useEffect, useState } from 'react';
import classNames from "classnames";
import { calculateRelativePositionBoundaries, ScrollingTimelineColumn, TIMELINE_VIEW_CONFIG } from "./ScrollingTimeline";
import { TimelineColumnConfig } from "../../TimelinePage";
import { asDdMmYyyy } from "../../../../shared/utils/date.utils";

interface TimelineColumnHeadersProps {
  config: TimelineColumnConfig;
  columns: ScrollingTimelineColumn[];
}

const TimelineColumnHeaders = (props: TimelineColumnHeadersProps) => {
  const {headerHeight} = TIMELINE_VIEW_CONFIG;
  const nrOfColumns = props.columns.length;
  const [columnsMeta, setColumnsMeta] = useState<{ color: number, borderColor: number }[]>([]);

  useEffect(() => {
    let currentColor = 7;
    setColumnsMeta(props.columns.reduce((acc: { color: number, borderColor: number }[], column, idx) => {
      if (idx > 0 && !props.config.isSameAs(props.columns[idx - 1].date.clone(), column.date.clone())) {
        currentColor = currentColor === 7 ? 8 : 7;
      }
      return [...acc, {
        color: currentColor,
        borderColor: props.config.isSameAs(props.columns[idx - 1]?.date.clone(), column.date.clone()) ? currentColor : 5
      }];
    }, []));
  }, [props.columns]);

  return <div className="header w-full sticky top-0 shadow-3"
              style={{height: `${headerHeight}px`, zIndex: 20}}>
    <div className="header-inner w-full" style={{height: `${headerHeight}px`}}>
      <div className="header-inner-wrapper w-full relative h-full">
        {props.columns.map((column, idx) => <div
          key={`column-header-${idx}`}
          style={calculateRelativePositionBoundaries(idx, idx + 1, nrOfColumns, props.config.columnWidth)}
          className={classNames(
            ` absolute top-0 bottom-0 flex-auto-0 flex flex-column align-items-center justify-content-center`,
            {
              "font-bold": column.highlighted,
              [`bg-gray-${columnsMeta[idx]?.color}00`]: !column.highlighted,
            }
          )}
        >
          {props.config.columnBody(column.date)}
        </div>)}
      </div>
      <div className="header-inner-lines flex top-0 absolute w-full h-full">
        {props.columns.map((column, idx) =>
          <div
            key={`column-header-lines-${idx}`}
            style={calculateRelativePositionBoundaries(idx, idx + 1, nrOfColumns, props.config.columnWidth)}
            className={classNames(
              `absolute top-0 bottom-0 flex-auto-0 border-left-1 border-gray-${columnsMeta[idx]?.borderColor}00`,
              {
                "bg-black-alpha-60": column.highlighted,
              }
            )}
          >
            {column.highlighted && <div
              className="absolute top-0 h-full border-left-1 border-right-1 border-gray-500"
              style={{left: '-1px', right: '-1px'}}></div>}
          </div>
        )}
      </div>
    </div>
  </div>;
};

export default TimelineColumnHeaders;
