import React from 'react';
import { asDdMmYyyy } from "../../../../shared/utils/date.utils";
import { calculateRelativePositionBoundaries, ScrollingTimelineColumn } from "./ScrollingTimeline";

interface TimelineCellHolderProps {
 column: ScrollingTimelineColumn;
 columnIdx: number;
 totalColumns: number;
 columnWidth: number;
}

const TimelineCellHolder = (props: TimelineCellHolderProps) => {
  const {column, columnIdx, totalColumns, columnWidth}= props;
  return <div
    onClick={() => {
      console.log(asDdMmYyyy(column.startDate), asDdMmYyyy(column.endDate))
    }}
    style={calculateRelativePositionBoundaries(columnIdx, columnIdx + 1, totalColumns, columnWidth)}
    className='hover:bg-gray-600 z-4 absolute top-0 bottom-0 flex-auto-0 flex flex-column align-items-center justify-content-center'
  >
    {/*  represents CELL content: empty container that can be filled, clicked, ...*/}
  </div>;
};

export default TimelineCellHolder;
