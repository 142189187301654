import React, { useState } from 'react';
import { compact, get, map, uniq } from "lodash";
import { useQuery } from "@apollo/client";
import { CultivationsQuery } from "../../../21/planning21.gql";
import { Cultivations, Cultivations_cultivations_varieties } from "../../../../__generated__/types";
import { TIMELINE_VIEW_CONFIG } from "./ScrollingTimeline";
import CultivationInfo from "./CultivationInfo";
import classNames from "classnames";
import { Button } from "primereact/button";
import AddCultivation from "../../../21/Planning21/components/AddCultivation/AddCultivation";
import TimelineRowHeaderExpansionSkeleton from './TimelineRowHeaderExpansionSkeleton';
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import useDeleteCultivationMutation from "../../../../hooks/useDeleteCultivationMutation";

interface TimelineRowHeaderExpansionProps {
  cultivationIds: string[];
  rowHeights: number[];
}

const TimelineRowHeaderExpansion = (props: TimelineRowHeaderExpansionProps) => {
  const {data, loading} = useQuery<Cultivations>(CultivationsQuery, {
    variables: {
      ids: props.cultivationIds
    }
  });

  const [activeCultivation, setActiveCultivation] = useState<string>();
  const [deleteCultivationMutation] = useDeleteCultivationMutation();

  if (loading) {
    return <div className="flex-grow-1 w-full relative flex align-items-center justify-content-center">
      <i className="pi pi-spinner pi-spin text-3xl"></i>
    </div>
  }

  const hasAlternativeNames = (cultivationVarieties: Cultivations_cultivations_varieties[]) => {
    const nrOfVarieties = cultivationVarieties.length;
    const nrOfAlternativeCropNames = compact(uniq(map(cultivationVarieties,'variety.alternativeCropName'))).length;
    return nrOfAlternativeCropNames === nrOfVarieties;
  };

  return <div className="flex-grow-1 w-full relative">

    {compact(data?.cultivations).map(cultivation => {
      const cultivationVarieties = compact(cultivation.varieties);
      return <div
        key={`row-header-cultivation-${cultivation.id}`}
        className="px-1 relative hover:overflow-visible overflow-hidden"
        style={{
          boxSizing: "border-box",
          height: `${TIMELINE_VIEW_CONFIG.detailRowHeight * (cultivationVarieties.length || 1)}px`
        }}
      >
        {activeCultivation === cultivation.id &&
          <AddCultivation
            plotId={cultivation.plot.id}
            crop={{id: cultivation.crop.id}}
            cultivationId={cultivation.id}
            onClose={() => setActiveCultivation(undefined)}/>}

        <div className={classNames(
          "flex",
          {"border-1 border-primary-700 p-1 border-round-mg": cultivationVarieties.length > 1}
        )}
        >
          <div className="w-3rem text-overflow-ellipsis flex-shrink-0">
            {`${cultivation.plot.rotationArea.field.prefix}-${cultivation.plot.rotationArea.number}`}
          </div>
          <div className="overflow-hidden flex-grow-1 flex justify-content-between align-items-center">
            <div className="flex-grow-1 flex flex-column align-items-stretch mr-2 overflow-hidden">
              {cultivationVarieties.length > 0
                ? cultivationVarieties.map((cultivationVariety, idx) => {
                    return <CultivationInfo
                      varietyIdx={idx}
                      key={`row-header-expansion-${cultivation.id}-${cultivationVariety.id}`}
                      cultivation={cultivation}
                      cultivationVarietyId={cultivationVariety.id}
                    />;
                  })
                : <CultivationInfo varietyIdx={0} key={`row-header-expansion-${cultivation.id}`}
                                   cultivation={cultivation}/>}
            </div>
            <Button
              link
              className='flex-shrink-0 p-0 w-1rem text-primary-800 hover:text-primary-400'
              size="small"
              icon="pi pi-pencil"
              onClick={() => setActiveCultivation(cultivation.id)}
            />
          </div>
        </div>
        <div className="absolute top-0 right-0 bg-red-100 border-circle-right"
             style={{
               transform: 'translate(100%, 50%)',

             }}>
          <div className="mr-1">
            <ConfirmPopup/>
            <Button
              link
              severity="danger"
              className='p-1 text-xs w-auto text-red-700'
              size="small"
              icon="pi pi-trash"
              onClick={(event) => {
                confirmPopup({
                  target: event.currentTarget,
                  message: 'Ben je zeker dat je deze teelt wil verwijderen',
                  icon: 'pi pi-exclamation-triangle',
                  acceptClassName: 'p-button-danger',
                  acceptLabel: 'Ja',
                  rejectLabel: 'Nee',
                  accept: () => {
                    const id = cultivation.id;
                    if (id) {
                      deleteCultivationMutation({variables: {id}});
                    }
                  }
                });
              }}
            />
          </div>
        </div>
      </div>;
    })}
  </div>;
};

export default TimelineRowHeaderExpansion;
