import React, { useState } from 'react';
import CropTimingList from "./CropTimingList";
import styled from "styled-components";
import AddCropTiming from "./AddCropTiming";
import { CropDetails, CropDetails_crop_cropTimings, CropTimingsForCrop_cropTimings, FieldType, PlantOrSeed } from "../../../../../../__generated__/types";
import { Moment } from "moment";
import { useQuery } from "@apollo/client";
import { CropDetailsQuery } from "../../../CropManagement.gql";
import { compact } from "lodash";

export interface CropTimingsProps {
  cropId: string;
  value?: string | undefined;
  filters?: { type: FieldType | undefined, plantOrSeed: PlantOrSeed | undefined };
  startDate?: Moment | undefined;
  year: number;
  years: number[];
  onChange: (cropTiming: CropDetails_crop_cropTimings | undefined) => void;
  showPreseeding?: boolean;
  varietyId?: string;
  onStartWeekClick?: (cropTimingId: string, cropTimings: CropTimingsForCrop_cropTimings[], startWeek: Moment) => void;
  onHarvestEndWeekClick?: (cropTimingId: string, cropTimings: CropTimingsForCrop_cropTimings[], harvestEndWeek: Moment) => void;
  weekValues?: { startWeek?: Moment, harvestEndWeek?: Moment };
  hideFilters?: boolean;
  allowWeekSelection?: boolean;
}

const Container = styled.div`
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: max-content;
  grid-gap: 16px;

  > div {
    background-color: var(--surface-c);
  }
`;

export const AddButtonBlock = styled.div`
  margin: 16px;
  border: 4px dashed var(--text-color-secondary);
  color: var(--text-color-secondary);
  display: grid;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
`;

const AddVarietyContainer = styled.div`
  align-self: stretch;
  display: grid;
  min-height: 250px;
`;


const CropTimings = (props: CropTimingsProps) => {
  const {data, refetch} = useQuery<CropDetails>(CropDetailsQuery, {
    variables: {
      id: props.cropId || ""
    }
  });

  const [mode, setMode] = useState<'read' | 'edit' | 'create'>('read');

  const [selectedCropTiming, setSelectedCropTiming] = useState<CropDetails_crop_cropTimings>();

  const {cropId, ...rest} = props;

  return <Container>
    <AddVarietyContainer>
      {mode === 'read' &&
        <AddButtonBlock onClick={() => {
          setMode('create');
          setSelectedCropTiming(undefined);
        }}>+ Teeltmogelijkheid toevoegen</AddButtonBlock>}
      {(mode === 'create' || mode === 'edit') &&
        <div style={{alignSelf: 'start'}}>
          <AddCropTiming
            cropTiming={selectedCropTiming}
            cropId={cropId}
            onClose={() => {
              refetch().then(() => {
              });
              setMode('read');
            }}/>
        </div>
      }
    </AddVarietyContainer>
    <div>
      <CropTimingList
        {...rest}
        cropTimings={compact(data?.crop?.cropTimings)}
        visibleColumns={['type', 'cultivationMethod', 'timeline']}
        onChange={(cropTiming) => {
          setMode(cropTiming ? 'edit' : 'read');
          props.onChange(cropTiming);
          setSelectedCropTiming(cropTiming);
        }}
      />
    </div>
  </Container>;
};
export default CropTimings;
