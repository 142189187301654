import React, { useState } from 'react';
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

interface RemarksProps {
  value: string;
  onChange: (value: string) => void;
}

const Remarks = (props: RemarksProps) => {
  const [value, setValue] = useState<string>(props.value);
  return <div>
    <InputTextarea
      style={{width: '100%'}}
      value={value}
      onChange={(e: any) => {
        setValue(e.target.value)
      }}
    />
    <div className="flex">

      <Button
        link
        label="Annuleren"
        onClick={() => {
          props.onChange(value);
        }}
      />
      <Button
        label={"Bevestigen"}
        onClick={() => {
          props.onChange(value);
        }}
      >
      </Button>
    </div>
  </div>;
};

export default Remarks;
