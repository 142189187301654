import { useMutation } from "@apollo/client/react/hooks";
import { DeleteCultivation } from "../__generated__/types";
import { DeleteCultivationQuery } from "../pages/21/planning21.gql";

const useDeleteCultivationMutation = () => {
  return useMutation<DeleteCultivation>(DeleteCultivationQuery, {
    update(cache, mutationResult) {
      if (mutationResult && mutationResult.data && mutationResult.data.deleteCultivation) {
        let identify = cache.identify({__typename: 'Cultivation', id: mutationResult.data.deleteCultivation.id});
        cache.evict({id: identify});
      }
    }

  });
};

export default useDeleteCultivationMutation;
