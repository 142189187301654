import React from 'react';
import { Checkbox } from "primereact/checkbox";
import { orderBy, without } from "lodash";
import { VisibilityOptions } from "../GardenFilters";
import { FilterOptionValues } from "../../planning21.interfaces";

interface SeasonsProps {
  value: number[];
  years: { name: string, id: number }[];
  onChange: (values: string[], field: keyof FilterOptionValues) => void;
}

const Seasons = (props: SeasonsProps) => {
  return <VisibilityOptions>
    {props.years.map(year =>
      <div className="option" key={year.id}>
        <Checkbox id={`year${year.id}`}
                  value={year.id}
                  checked={props.value.includes(year.id)}
                  onChange={(e) => {
                    let updatedValue = e.checked
                      ? orderBy([...props.value, e.value], 'id')
                      : without(props.value, e.value);

                    props.onChange(updatedValue, 'years');
                  }}/>
        <label htmlFor={`years${year.id}`}>{year.name}</label>

      </div>
    )}
  </VisibilityOptions>;
};

export default Seasons;
