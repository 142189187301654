import React from 'react';
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { FieldDetailsQuery } from "../FieldManagement.gql";
import { FieldDetailsData, FieldDetailsData_field_rotationAreas, FieldsOverview_fields, FieldType, RotationGroups_rotationGroups, Season } from "../../../../__generated__/types";
import { Button } from "primereact/button";
import { compact } from "lodash";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
`;

const FieldSummary = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 8px;
`;

const FieldData = styled.div`
  display: grid;
  grid-template-columns: 80px 120px 100px 80px;
  grid-gap: 8px;
`;

const FieldLog = (props: { season: Season, rotationGroup: RotationGroups_rotationGroups, fieldId: string }) => {
  const {data} = useQuery<FieldDetailsData>(FieldDetailsQuery, {variables: {id: props.fieldId}});

  if (data?.field) {
    const {bedLength, bedWidth, name, rotationAreas} = data?.field;
    let nrOfRotationBlocksForGroup = compact(rotationAreas)
      .reduce((acc: number, el: FieldDetailsData_field_rotationAreas) => {
        if (el.rotationSchema) {
          let totalLengthInSeason = compact(el.rotationSchema)
            .filter(rotationSchema => rotationSchema.season === props.season && rotationSchema.rotationGroup.id === props.rotationGroup.id)
            .length;
          return acc + (totalLengthInSeason * (bedWidth === 1.2 ? 1.5 : 1));
        }
        return acc;
      }, 0);


    let totalLength = nrOfRotationBlocksForGroup * bedLength;
    if (totalLength > 0) {
      return <FieldData>
        <div>{name}</div>
        <div>{bedLength}m x {bedWidth}m</div>
        <div> x {nrOfRotationBlocksForGroup} blokken</div>
        <div> = {totalLength}m</div>
      </FieldData>;
    }
  }

  return <div style={{gridColumn: '1 / span 4'}}/>;
};


export default (props: { fields: FieldsOverview_fields[], type: FieldType, onAddFieldInit: () => void }) => {
  return <Container>
    <div>{props.type === 'OPEN' ? 'Openlucht' : 'Serre'}</div>
    <Button
      label={'+ Veld toevoegen'}
      onClick={() => {
        props.onAddFieldInit();
      }}
    />
    {/*<FieldSummary>*/}
    {/*  <div></div>*/}
    {/*  <div>2022</div>*/}
    {/*  <div>2023</div>*/}

    {/*  {compact(rotationGroupsData?.rotationGroups).map((rotationGroup: RotationGroups_rotationGroups) => {*/}
    {/*    return <>*/}
    {/*      <div>{rotationGroup.name}</div>*/}
    {/*      <div>*/}
    {/*        {*/}
    {/*          props.fields*/}
    {/*            .filter(field => ["Veld A", "Veld B", "Veld C", "Veld D",].includes(field.name))*/}
    {/*            .map(field => (*/}
    {/*              <FieldLog season={Season.S_2022_2023} rotationGroup={rotationGroup} fieldId={field.id}/>))*/}
    {/*        }*/}
    {/*      </div>*/}
    {/*      <div>*/}
    {/*        {*/}
    {/*          props.fields*/}
    {/*            .filter(field => ["Veld A'", "Veld B'", "Veld C'", "Veld D'", "Veld A-bis", "Veld B-bis", "Veld C-bis", "Veld D-bis",].includes(field.name))*/}
    {/*            .map(field => (*/}
    {/*              <FieldLog season={Season.S_2023_2024} rotationGroup={rotationGroup} fieldId={field.id}/>))*/}
    {/*        }*/}
    {/*      </div>*/}
    {/*    </>;*/}
    {/*  })}*/}
    {/*</FieldSummary>*/}
  </Container>
};
