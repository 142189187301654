import React, { MutableRefObject, useEffect, useState } from 'react';
import { ContextMenu } from "primereact/contextmenu";
import { MenuItem } from "primereact/menuitem";
import { Button } from "primereact/button";
import config from "../../../../config";
import { CopyCultivationsToPlot, CopyCultivationsToPlotVariables, CultivationCreateWithoutPlotInput, CultivationDetails, CultivationDetails_cultivation, CultivationVarietyCreateInput, PlantOrSeed } from "../../../../__generated__/types";
import { compact, round } from "lodash";
import AddCultivation, { calculateCultivationDates } from "../../../21/Planning21/components/AddCultivation/AddCultivation";
import { startYear } from "../../../../shared/utils/season.util";
import { CultivationDetailsQuery } from "../../../21/Planning21/components/AddCultivation/AddCultivation.gql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client/react/hooks";
import { CopyCultivationsToPlotMutation } from "../../../21/planning21.gql";
import { plantSchemaForCalculations } from "../../../21/planning21-context";
import { nrOfRows, plantAmount, plantAmountToLength } from "../../../../utils/planting.util";

interface TimelineRowContextMenuProps {
  cropId?: string;
  plotId?: string;
  menuRef: MutableRefObject<ContextMenu | null>;
}


const TimelineRowContextMenu = (props: TimelineRowContextMenuProps) => {
  const [showAddCultivation, setShowAddCultivation] = useState<boolean>(false);
  const items: MenuItem[] = [
    {
      template: () => <Button
        link
        icon='pi pi-plus'
        label={`Teelt toevoegen`}
        onClick={() => {
          setShowAddCultivation(true);
        }}
      />
    },
  ];

  return <>
    <ContextMenu className={'z-5'} model={items} ref={props.menuRef} breakpoint="767px"/>
    {showAddCultivation && <AddCultivation
      crop={props.cropId ? {id: props.cropId} : undefined}
      plotId={props.plotId}
      onClose={() => setShowAddCultivation(false)}/>}
  </>;
};

export default TimelineRowContextMenu;
