import { GardenFilterValues } from "../planning21.interfaces";
import { chain, compact, get, groupBy, isEmpty, keys, max, min, toInteger, without } from "lodash";
import { GroupRow } from "../components/planning21-cultivation-group-row";
import React, { Suspense, useEffect, useState } from "react";
import AddCultivation from "../components/AddCultivation/AddCultivation";
import OutsideAlerter from "../../../../shared/components/outside-alerter";
import styled from "styled-components";
import { Planning21HorizontalGrid, TextButton } from "../styled-components";
import { Planning21Column, PLOT_PLANNING21_COLUMNS, selectionColumn } from "../components/planning21-columns";
import CultivationRow from "../components/CultivationRow";
import { CultivationEventType, CultivationsForCrop_cultivations, CultivationsForPlot, CultivationsForPlot_cultivations, CultivationsForPlot_plot } from "../../../../__generated__/types";
import { useQuery } from "@apollo/client";
import { CultivationsForPlotQuery } from "../../planning21.gql";
import { nameForPlot, prefixedNameForPlot } from "../../../../utils/plot.util";
import config from "../../../../config";
import { calculateSowingOrPlantingWindow } from "../planning21-mutations";
import moment from "moment";
import { asIso8601, asWeekYear, momentFromIso8601 } from "../../../../shared/utils/date.utils";
import CopySelectionButton from "../components/CopySelectionButton";
import MoveSelectionButton from "../components/MoveSelectionButton";
import { CURRENT_SEASON } from "../../../../shared/config";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";

const LocationInfo = styled.div`
  padding: 2px 4px;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  background: var(--surface-a);
  z-index: 1000;
`;

const PlotTimeline = React.lazy(() => import('../components/planning21-plot-timeline'));

const TimelineHolder = styled.div`
  height: 30px;
  background: var(--surface-c);
  border: 1px solid var(--surface-d);
  border-bottom: 0;
  position: relative;
  overflow-x: clip;

  .loading {
    padding: 2px;
    font-size: 0.9rem;
  }

  > .timeline {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

interface PlotRowProps {
  plotId: string;
  filters: GardenFilterValues;
  onSelectForMove: () => void;
}

interface CultivationForPlotRow extends CultivationsForPlot_cultivations {

}

const PlotRow = (props: PlotRowProps) => {
  const {plotId, filters} = props;
  const {data} = useQuery<CultivationsForPlot>(CultivationsForPlotQuery, {
    variables: {
      plotId,
      from: `${asIso8601(moment(min(filters.years), 'YYYY').startOf('y'))}`,
      to: `${asIso8601(moment(max(filters.years), 'YYYY').endOf('y'))}`,
    },
  });

  const [showCultivations, setShowCultivations] = useState<boolean>(props.plotId === 'clceyhycyd3280775hcrhypdi');
  // const [showCultivations, setShowCultivations] = useState<boolean>(false);
  const [allCultivations, setAllCultivations] = useState<CultivationForPlotRow[]>([]);
  const [interplantingCultivations, setInterplantingCultivations] = useState<CultivationForPlotRow[]>([]);
  const [filteredCultivations, setFilteredCultivations] = useState<CultivationForPlotRow[]>([]);
  const [plot, setPlot] = useState<CultivationsForPlot_plot>();
  const [showAllCrops, setShowAllCrops] = useState<boolean>(false);
   const [mode, setMode] = useState<'read' | 'edit' | 'create'>('read');
  //const [mode, setMode] = useState<'read' | 'edit' | 'create'>(props.plotId === 'clceyhycyd3280775hcrhypdi' ? 'create' : 'read');
  const [selectedCultivations, setSelectedCultivations] = useState<string[]>([]);
  const columns = PLOT_PLANNING21_COLUMNS;

  useEffect(() => {
    if (data && data.plot) {
      setPlot(data.plot);

      if (data.cultivations) {
        let cultivations = compact(data.cultivations);

        setAllCultivations(cultivations.filter(cultivation => !cultivation.interPlanting));
        setInterplantingCultivations(cultivations.filter(cultivation => cultivation.interPlanting));
        setFilteredCultivations(chain(cultivations)
          .filter(cultivation => {
            const sowingOrPlantingWindow = calculateSowingOrPlantingWindow(cultivation.cropTiming, cultivation.startDate);

            return isEmpty(filters.weeks) || filters.weeks.includes(sowingOrPlantingWindow.firstDay.isoWeek());
          })
          .filter((cultivation: CultivationForPlotRow) => (showAllCrops || isEmpty(filters.crops)) || (filters.crops.includes(cultivation.crop.id)))
          .orderBy(['startDateUnix'], ['asc'])
          .value()
        )
      }
    }
  }, [data, filters, showAllCrops]);

  if (plot) {
    const {rotationSchema, field} = plot.rotationArea;
    const rotationSchemaForPlanning = rotationSchema && rotationSchema.find(rotationSchema => {
      return rotationSchema.season === config.currentPlanningSeason;
    });

    let cultivationForPlotRowCollectionChain = chain(allCultivations)
      .filter(cultivation => CURRENT_SEASON.startsWith(`${momentFromIso8601(cultivation.startDate).year()}`))
      .orderBy('startDate');

    const firstPlantingDate = cultivationForPlotRowCollectionChain
      .first()
      .value();

    let filteredCultivationsByYear = groupBy(filteredCultivations, (c) => momentFromIso8601(c.startDate).year());
    return <div className="mb-1">
      <div>
        {selectedCultivations.length > 0 &&
          <>
            <CopySelectionButton
              plotId={plot.id}
              selection={filteredCultivations.filter(c => selectedCultivations.includes(c.id))}
              season={config.currentPlanningSeason}
              onSubmit={() => {
                setSelectedCultivations([])
              }}
            />
            <MoveSelectionButton
              plotId={plot.id}
              selection={filteredCultivations.filter(c => selectedCultivations.includes(c.id))}
              season={config.currentPlanningSeason}
              onSubmit={() => {
                setSelectedCultivations([])
              }}
            />
          </>
        }
      </div>
      <GroupRow className={mode === 'create' ? 'p-shadow-3' : ''}
                id={'groupRow'}
                onClick={(e) => {
                  setShowCultivations(!showCultivations);
                }}>
        <Planning21HorizontalGrid columns={[
          {
            width: `${chain(columns)
              .filter(c => c.width.endsWith('px'))
              .map(c => toInteger(c.width.replace('px', '')))
              .sum()
              .value()}px`,
          },
          {
            width: '1fr'
          }
        ]}>
          <LocationInfo className="ml-3">
            <span>
              <span className="font-bold">{prefixedNameForPlot(plot)}</span>
              <span
                className="pl-2 text-sm text-color-secondary">{rotationSchemaForPlanning && rotationSchemaForPlanning.rotationGroup.name}</span>
            </span>
            {showCultivations && <div className="flex flex-column align-items-end">
              <Button
                link
                size="small"
                icon="pi pi-plus-circle"
                label="Teelt toevoegen"
                className="p-0 m-0"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setMode('create');
                }}/>
              {filters.crops.length > 0 && <Button
                link
                size="small"
                icon={showAllCrops ? "pi pi-eye-slash" : "pi pi-eye"}
                label={showAllCrops ? "Verberg andere teelten" : "Toon alle teelten"}
                className="p-0 m-0"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowAllCrops(!showAllCrops);
                }}/>}
            </div>}
          </LocationInfo>
          <TimelineHolder>
            <Suspense fallback={<div className="loading">loading...</div>}>
              <PlotTimeline key={`plot-timeline-${plot.id}`}
                            id={`plot-timeline-${plot.id}`}
                            cultivations={allCultivations.filter(c => {
                              let plantEvent = (c.events || []).find(e => {
                                return (e.type === CultivationEventType.SEED || e.type === CultivationEventType.PLANT);
                              });

                              return !(plantEvent && plantEvent.skipped);

                            })}
                            opacity={1}
                            showTopValue
                            color={'rgb(73, 87, 45)'}
                            maxOccupation={field.bedLength}/>
              <PlotTimeline key={`plot-timeline-inter-${plot.id}`}
                            id={`plot-timeline-inter-${plot.id}`}
                            cultivations={interplantingCultivations}
                            color={'#123812'}
                            inter
                            opacity={1}
                            maxOccupation={field.bedLength}/>
            </Suspense>
          </TimelineHolder>
        </Planning21HorizontalGrid>
      </GroupRow>

      {showCultivations && <>
        {mode === 'create' && <>
          <AddCultivation
            //plotId={'ck5k5rp600ai70774d56fvy7s'}
            plotId={plot.id}
            onClose={() => setMode('read')}/>
        </>
        }
        {keys(filteredCultivationsByYear).map(year =>
          <div className="mb-2 border-round overflow-hidden border-bottom-1 border-100">
            {filteredCultivationsByYear[year].map((cultivation) => {
                return <CultivationRow
                  cultivations={filteredCultivations}
                  selected={selectedCultivations.includes(cultivation.id)}
                  editable={true}
                  plotId={plot.id}
                  columns={columns}
                  groupingColumn={columns[1]}
                  //cultivationId={cultivation.id}
                  cultivation={{...cultivation, plot}}
                  key={`${plot.id}-${cultivation.id}`}
                  onSelectionChange={(selection: boolean) => {
                    setSelectedCultivations(value => selection ? [...value, cultivation.id] : without(value, cultivation.id));
                  }
                  }
                />;
              }
            )}
          </div>
        )}
      </>
      }
    </div>;
  }
  return <div/>;
};

export default PlotRow;
