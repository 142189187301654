import React, { useRef, useState } from 'react';
import { compact, groupBy, map, sum } from "lodash";
import { momentFromIso8601 } from "../../../../shared/utils/date.utils";
import { ScrollingTimelineRow, TIMELINE_VIEW_CONFIG, TimelineGranularity } from "./ScrollingTimeline";
import { InputText } from 'primereact/inputtext';
import TimelineRowHeaderExpansion from "./TimelineRowHeaderExpansion";
import { Button } from 'primereact/button';
import TimelineRowContextMenu from "./TimelineRowContextMenu";
import { ContextMenu } from "primereact/contextmenu";
import TimelineRowHeader from "./TimelineRowHeader";

interface TimelineRowHeadersProps<T> {
  rows: ScrollingTimelineRow<T>[];
  rowHeights: number[];
  expandedRowIds: string[];
  granularity: TimelineGranularity;
  onToggleExpansion: (rowId: string) => void,
  onSearch: (searchValue: string) => void,
}

const TimelineRowHeaders = <T extends {
    id: string,
    cultivations: { id: string, startDate: any, varieties: any[] | null }[] | null
  }, >(props: TimelineRowHeadersProps<T>) => {
    const {detailRowHeight, headerHeight, rowHeaderWidth} = TIMELINE_VIEW_CONFIG;
    const {rowHeights} = props;
    const [searchValue, setSearchValue] = useState<string>('');

    return <div className="flex-auto-0 sticky left-0 z-5 " style={{width: `${rowHeaderWidth}px`}}> {/*left*/}
      <div className="relative min-h-full border-right-2 border-gray-500"> {/*left-inner*/}
        {/*row/column header, search*/}
        <div
          className="left-header w-full border-bottom-2 bg-gray-800 shadow-3 border-400 sticky top-0 z-5 flex align-items-center justify-content-center"
          style={{height: `${headerHeight}px`}}>
          <div>
            <span className="p-input-icon-left">
              <i className="pi pi-search"/>
              <InputText
                value={searchValue}
                placeholder="Zoek"
                className="pl-5 p-inputtext-sm"
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    props.onSearch(searchValue);
                  }
                }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </span>
          </div>
        </div>


        <div className="items relative"
             style={{height: `${sum(rowHeights)}px`}}>
          {props.rows.map((rowData, idx) => {
            return <TimelineRowHeader
              rowData={rowData}
              rowIndex={idx}
              onToggleExpansion={props.onToggleExpansion}
              rowHeights={rowHeights}
              key={`row-header-${rowData.data.id}-${idx}`}
              cultivations={compact(rowData.data.cultivations)}
              isExpanded={props.expandedRowIds.includes(rowData.id)}/>
          })}

        </div>
      </div>
    </div>;
  }
;

export default TimelineRowHeaders;
