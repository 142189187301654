import React, { ReactNode, useState } from 'react';
import styled from "styled-components";
import TimelineViewOptions from "./ScrollingTimeline/components/TimelineViewOptions";
import { Moment } from "moment/moment";
import moment from "moment";
import { ScrollingTimelineRow, TimelineGranularity, TimelineRowType } from "./ScrollingTimeline/components/ScrollingTimeline";
import { CropsWithCultivationIdsQuery, PlotsWithCultivationIdsQuery } from "../21/planning21.gql";
import { CropsWithCultivationIds, CropsWithCultivationIds_crops, PlotsWithCultivationIds, PlotsWithCultivationIds_plots } from "../../__generated__/types";
import { compact } from 'lodash';
import DataBasedTimeline, { RowTypeDataConfig } from "./ScrollingTimeline/components/DataBasedTimeline";
import { nameForPlot } from "../../utils/plot.util";
import { asDayDMmmYy, asDD, asDdMmm, asDdMmYyyy, asMmYy, asMonthShort, asMonthYyyy, asWeekYear, asYyyy, momentFromDdMmYyyy } from "../../shared/utils/date.utils";
import AddCultivation from "../21/Planning21/components/AddCultivation/AddCultivation";

const Container = styled.div`
  height: calc(100vh - var(--header-height));
`;

const rowTypedProperties: {
  [key in TimelineRowType]: RowTypeDataConfig<any, any>
} = {
  crop: {
    query: CropsWithCultivationIdsQuery,
    queryDataSelector: (data: CropsWithCultivationIds | undefined) => compact(data?.crops),
    rowMapper: (crop: CropsWithCultivationIds_crops): ScrollingTimelineRow<CropsWithCultivationIds_crops> => ({
      id: crop.id,
      label: crop.name,
      type: 'crop',
      items: [],
      data: crop,
    }),
  },
  plot: {
    query: PlotsWithCultivationIdsQuery,
    queryDataSelector: (data: PlotsWithCultivationIds | undefined) => compact(data?.plots),
    rowMapper: (plot: PlotsWithCultivationIds_plots) => ({
      id: plot.id,
      label: nameForPlot(plot),
      type: 'plot',
      items: [],
      data: plot,
    }),
  }
};

export interface TimelineColumnConfig {
  code: 'yearly' | 'quarterly'| 'monthly' | 'weekly' | 'daily',
  granularity: TimelineGranularity,
  label: string,
  columnWidth: number,
  columnBody: (date: Moment) => ReactNode,
  isSameAs: (a: Moment | undefined, b: Moment | undefined) => boolean,
}

export const COLUMN_CONFIG: {
  [key in 'yearly' | 'quarterly'| 'monthly' | 'weekly' | 'daily']: TimelineColumnConfig
} = {
  "yearly": {
    code: "yearly",
    granularity: "y",
    label: "Jaar",
    columnWidth: 500,
    isSameAs: (a: Moment | undefined, b: Moment | undefined) => !!a && !!b && a.year() === b.year(),
    columnBody: (date: Moment) => <div className="flex align-items-center justify-content-center">
      {asYyyy(date)}
    </div>,
  },
  "quarterly": {
    code: "quarterly",
    granularity: "M",
    label: "Kwartaal",
    columnWidth: 60,
    isSameAs: (a: Moment | undefined, b: Moment | undefined) => !!a && !!b && a.quarter() === b.quarter(),
    columnBody: (date: Moment) => {
      return <div className="flex align-items-center justify-content-center flex-column">
        <span className="text-base uppercase">{asMonthShort(date)}</span>
        <span className="text-xs text-gray-500">{asMmYy(date)}</span>
      </div>;
    },
  },
  "monthly": {
    code: "monthly",
    granularity: "M",
    label: "Maand",
    columnWidth: 200,
    isSameAs: (a: Moment | undefined, b: Moment | undefined) => !!a && !!b && a.month() === b.month(),
    columnBody: (date: Moment) => {
      return <div className="flex align-items-center justify-content-center flex-column">
        <span className="text-base uppercase">{asMonthShort(date)}</span>
        <span className="text-xs text-gray-500">{asMmYy(date)}</span>
      </div>;
    },
  },
  "weekly": {
    code: "weekly",
    granularity: "w",
    label: "Week",
    columnWidth: 75,
    isSameAs: (a: Moment | undefined, b: Moment | undefined) => !!a && !!b && a.startOf('w').month() === b.startOf('w').month(),
    columnBody: (date: Moment) => <div className="flex align-items-center justify-content-center flex-column line-height-2">
      <span className="text-xs text-gray-100">{asDdMmm(date.clone().startOf('isoWeek'))}</span>
      <span className="text-gray-300 ">
        <span className="text-xs pr-1">w</span>
        <span className="text-sm">{date.isoWeek()}</span>
      </span>
    </div>,
  },
  "daily": {
    code: "daily",
    granularity: "d",
    label: "Dag",
    columnWidth: 75,
    isSameAs: (a: Moment | undefined, b: Moment | undefined) => !!a && !!b && a.startOf('w').isoWeek() === b.startOf('w').isoWeek(),
    columnBody: (date: Moment) => <div className="flex align-items-center justify-content-center flex-column line-height-2">
      <span className="text-sm text-gray-300">{asDD(date)}</span>
      <span className="text-xs text-gray-100">{asDdMmm(date)}</span>
      <span className="text-gray-300 text-sm">{date.year()}</span>
    </div>,
  },
};

const TimelinePage = () => {
  const [alwaysShowMetadata, setAlwaysShowMetadata] = useState<boolean>(true);
  const [columnConfig, setColumnConfig] = useState<TimelineColumnConfig>(COLUMN_CONFIG.quarterly);
  const [rowType, setRowType] = useState<TimelineRowType>("crop");
  const [cropSelection, setCropSelection] = useState<string[]>([]);
  //const [cropSelection, setCropSelection] = useState<string[]>(['ck4e4q13e008rd9626qi3yw3j', 'ck4e4q13i009cd9622yk3va4e', 'ck4e4q136007ud962yggsb3hp', 'ck4e4q12y006zd962icsx1qvc', 'ck4e4q12p0065d962m127u0ue']);
  const [logicalCropGroup, setLogicalCropGroup] = useState<string | undefined>('lettuces');
  const [dateRange, setDateRange] = useState<Moment[]>([
    moment(2023, 'YYYY').startOf('y'),
    moment(2024, 'YYYY').endOf('y')
  ]);

  return <Container className="flex flex-column overflow-hidden border-box bg-gray-800">
    {/*<AddCultivation cultivationId={"clca9i6gocygd077536v3x8fh"} onClose={() => {}}/>*/}
    <div className="flex align-items-center justify-content-between m-3">
      <div className="text-xl font-bold">Planning</div>

      <TimelineViewOptions
        alwaysShowMetadata={alwaysShowMetadata}
        onAlwaysShowMetadataChange={setAlwaysShowMetadata}
        logicalCropGroup={logicalCropGroup}
        dateRange={dateRange}
        rowType={rowType}
        cropSelection={cropSelection}
        granularityCode={columnConfig.code}
        onGranularityChange={(granularity) => setColumnConfig(COLUMN_CONFIG[granularity])}
        onRowTypeChange={setRowType}
        onDateRangeChange={(dateRange) => {
          setDateRange(dateRange);
        }}
        onCropSelectionChange={(crops)=> {
          setCropSelection(crops);
        }}
        onLogicalCropGroupChange={(logicalCropGroup)=> {
          setLogicalCropGroup(logicalCropGroup);
        }}
      />
    </div>

    <DataBasedTimeline<CropsWithCultivationIds, CropsWithCultivationIds_crops>
      alwaysShowMetadata={alwaysShowMetadata}
      dateRange={dateRange}
      cropSelection={cropSelection}
      logicalCropGroup={logicalCropGroup}
      columnConfig={columnConfig}
      rowType={rowType}
      {...rowTypedProperties[rowType]}
    />

  </Container>;
};

export default TimelinePage;
