import { GardenFilterValues } from "../planning21.interfaces";
import React, { Fragment, Suspense, useEffect, useState } from "react";
import styled from "styled-components";
import { CultivationsForCrop, CultivationsForCrop_crop, CultivationsForCrop_cultivations } from "../../../../__generated__/types";
import OutsideAlerter from "../../../../shared/components/outside-alerter";
import { formatAbs } from "../../../../shared/utils/currency.utils";
import { plantAmount } from "../../../../utils/planting.util";
import { Header, Planning21HorizontalGrid, TextButton } from "../styled-components";
import { CROP_PLANNING21_COLUMNS } from "../components/planning21-columns";
import CultivationRow from "../components/CultivationRow";
import AddCultivation from "../components/AddCultivation/AddCultivation";
import { GroupRow } from "../components/planning21-cultivation-group-row";
import { useQuery } from "@apollo/client";
import { CultivationsForCropQuery } from "../../planning21.gql";
import { plantSchemaForCalculations, usePlanning } from "../../planning21-context";
import { chain, max, min, toInteger, without } from "lodash";
import { plotOccupation } from "../components/planning21-crop-timeline";
import { calculateSowingOrPlantingWindow } from "../planning21-mutations";
import { asIso8601 } from "../../../../shared/utils/date.utils";
import moment from "moment";
import config from "../../../../config";
import CopySelectionButton from "../components/CopySelectionButton";
import MoveSelectionButton from "../components/MoveSelectionButton";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import CropDetails from "../../crop-management/crop-details/CropDetails";
import { TabMenu } from "primereact/tabmenu";
import { Button } from "primereact/button";
import CropDetailsView from "../../crop-management/crop-details/CropDetails";

const CropInfo = styled.div`
  padding: 2px 4px;
  display: grid;
  grid-template-columns: 1fr max-content;
`;

const CropTimeline = React.lazy(() => import('../components/planning21-crop-timeline'));

const TimelineHolder = styled.div`
  height: 100%;
  overflow: hidden;
  background: var(--surface-c);
  border: 1px solid var(--surface-d);
  border-bottom: 0;
  position: relative;
  overflow-x: clip;

  .loading {
    padding: 2px;
    font-size: 0.9rem;
  }

  > .timeline {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
 `;

const Yields = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-column-gap: 8px;
  text-align: right;

  .less {
    color: red;
  }

  .more {
    color: var(--primary-color);
  }
`;

const CropData = styled.div`
    display: grid;
    grid-template-areas: 'name add' 'notes notes';
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 8px;
`;

interface CropRowProps {
  cropId: string;
  filters: GardenFilterValues;
}

const columns = CROP_PLANNING21_COLUMNS;
const CropRow = (props: CropRowProps) => {
  const {years} = usePlanning();

  const [showCultivations, setShowCultivations] = useState<boolean>(true);
  const [allCultivations, setAllCultivations] = useState<CultivationsForCrop_cultivations[]>([]);
  const [filteredCultivations, setFilteredCultivations] = useState<CultivationsForCrop_cultivations[]>([]);
  const [selectedCultivations, setSelectedCultivations] = useState<string[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);

//    const [editNotes, setEditNotes] = useState<string | null>(null);
//     const [updateCropNotes] = useMutation<UpdateCropNotes>(UpdateCropNotesMutation);

  const {cropId, filters} = props;

  const {data} = useQuery<CultivationsForCrop>(CultivationsForCropQuery, {
    variables: {
      fields: filters.fields,
      cropId,
      from: `${asIso8601(moment(min(filters.years), 'YYYY').startOf('y'))}`,
      to: `${asIso8601(moment(max(filters.years), 'YYYY').endOf('y'))}`,
    },
  });

  const [crop, setCrop] = useState<CultivationsForCrop_crop>();

  useEffect(() => {
    if (data) {

      if (data.crop) {
        setCrop(data.crop);

        if (data.cultivations) {
          const cultivations = chain(data.cultivations)
            .compact()
            .value();

          setAllCultivations(cultivations);
          setFilteredCultivations(cultivations);
        }
      }
    }
  }, [data, filters]);

  const [mode, setMode] = useState<'read' | 'edit' | 'create'>('read');

  if (crop) {
    const {name} = crop;
    const yearlyYields = years.map((year) => {
      const totals = allCultivations
        .filter(cultivation => {
          let plantOrSeedEvent = (cultivation.events || []).find(e => e.type === 'PLANT' || e.type === 'SEED');
          if (plantOrSeedEvent && plantOrSeedEvent.skipped) {
            return false;
          }
          return true;
        })
        .filter(cultivation => {
          // if (isEmpty(filters.weeks)) {
          //   return true;
          // }

          const sowingOrPlantingWindow = calculateSowingOrPlantingWindow(cultivation.cropTiming, cultivation.startDate);

          return sowingOrPlantingWindow.firstDay.year() === year || filters.weeks.includes(sowingOrPlantingWindow.firstDay.isoWeek());
        })
        .reduce((totals, cultivation) => {
          let plantSchema = plantSchemaForCalculations({...cultivation, crop});
          let plantSchemaForParent = cultivation.parent && plantSchemaForCalculations({...cultivation.parent, crop: cultivation.parent.crop});
          return ({
            totalLength: totals.totalLength + cultivation.length,
            nrOfPlants: totals.nrOfPlants + (plantSchema
              ? plantAmount(plantSchema.plantSchema, cultivation.length, cultivation.plot.rotationArea.field.bedWidth, plantSchemaForParent?.plantSchema)
              : 0),
          });
        }, {totalLength: 0, nrOfPlants: 0});
      return {
        year,
        ...totals
      }
    }).map((yearlyYield, idx, arr) => {
      return {
        ...yearlyYield,
        relativeAmountToPreviousSeason: idx === 0 ? undefined : yearlyYield.nrOfPlants / arr[idx - 1].nrOfPlants,
      };
    });

    const maxLength = max(plotOccupation(filteredCultivations, years).map(o => o.length));

    const template = (options: any) => {
      const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
      const className = `${options.className} justify-content-start`;
      const style = { fontSize: '1.25rem' };

      return (
        <div className={className}>
          <button className={options.togglerClassName} onClick={options.onTogglerClick}>
            <span className={toggleIcon}></span>
            <Ripple />
          </button>
          <span className={`${options.titleClassName} ml-2 text-primary text-xl font-medium`}>{name}</span>
        </div>
      );
    };

    const items = [
      {label: 'Teelten', icon: 'pi pi-fw pi-home', className: 'ml-6'},
      {label: 'Gewasinfo', icon: 'pi pi-fw pi-calendar'},
    ];

    return <Panel
      pt={{
        content: { className: 'p-0' },
      }}
      className="shadow-1 my-2 border-1 border-300"
      collapsed={true}
      headerTemplate={template}
      toggleable={true}
    >
      <TabMenu className="pt-3 pb-2" model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
      {activeIndex === 1 && <div>
        <CropDetailsView cropId={props.cropId}/>
      </div>}
      {activeIndex === 0 && <div>
        <div>
          {selectedCultivations.length > 0 &&
            <>
              <CopySelectionButton
                selection={filteredCultivations.filter(c => selectedCultivations.includes(c.id))}
                season={config.currentPlanningSeason}
                onSubmit={() => {
                  setSelectedCultivations([])
                }}
              />
              <MoveSelectionButton
                selection={filteredCultivations.filter(c => selectedCultivations.includes(c.id))}
                season={config.currentPlanningSeason}
                onSubmit={() => {
                  setSelectedCultivations([])
                }}
              />
            </>
          }
        </div>
        <GroupRow className={mode === 'create' ? 'p-shadow-3' : ''}
                  onClick={() => setShowCultivations(!showCultivations)}>
          <Planning21HorizontalGrid columns={[
            {
              width: `${chain(columns)
                .filter(c => c.width.endsWith('px'))
                .map(c => toInteger(c.width.replace('px', '')))
                .sum()
                .value()}px`,
            },
            {
              width: '1fr'
            }
          ]}>
            <CropInfo>
              <CropData>
                <Header style={{gridArea: 'name'}}>{name}</Header>
              </CropData>
              <Yields columns={yearlyYields.length}>
                {yearlyYields.map((yearlyYield, idx) => <Fragment key={`${yearlyYield.year}-${crop.id}`}>
                  <span>{yearlyYield.year}</span>
                  <span>{yearlyYield.totalLength}m</span>
                  <span>{yearlyYield.nrOfPlants && `${yearlyYield.nrOfPlants}pl`}</span>
                  <span className={1 > (yearlyYield.relativeAmountToPreviousSeason || 0) ? 'less' : 'more'}>
                    {idx > 0 && `${formatAbs(100 * (yearlyYield.relativeAmountToPreviousSeason || 0))}%`}
                  </span>
                </Fragment>)}
              </Yields>
            </CropInfo>
            <div>
              <TimelineHolder>
                <Suspense fallback={<div className="loading">loading...</div>}>
                  <CropTimeline key={`crop-timeline-${crop.id}`} cropId={crop.id}
                                cultivations={filteredCultivations.filter(cultivation => {
                                  let plantOrSeedEvent = (cultivation.events || []).find(e => e.type === 'PLANT' || e.type === 'SEED');
                                  if (plantOrSeedEvent && plantOrSeedEvent.skipped) {
                                    return false;
                                  }
                                  return true;
                                })}
                                maxOccupation={maxLength}/>
                </Suspense>
              </TimelineHolder>
            </div>
          </Planning21HorizontalGrid>
        </GroupRow>

        {showCultivations && <>
          {filteredCultivations.map((cultivation) =>
            <CultivationRow
              selected={selectedCultivations.includes(cultivation.id)}
              editable={true}
              plotId={cultivation.plot.id}
              columns={columns}
              groupingColumn={columns[3]}
              cultivation={cultivation}
              key={`${cultivation.plot.id}-${cultivation.id}`}
              onSelectionChange={(selection: boolean) =>
                setSelectedCultivations(value => selection ? [...value, cultivation.id] : without(value, cultivation.id))
              }
            />)}
          {mode === 'create' && <AddCultivation crop={crop} onClose={() => setMode('read')}/>}

          <Button
            link
            size="small"
            icon="pi pi-plus-circle"
            label="Teelt toevoegen"
            className="p-0 m-2"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setMode('create');
            }}/>
        </>
        }
      </div>}

    </Panel>;
  } else {
    return <div/>;
  }
};

export default CropRow;
