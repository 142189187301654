import React, { useEffect, useState } from 'react';
import { compact, first, flatten, get, last, map, times, without } from "lodash";
import { Moment } from "moment";
import moment from "moment/moment";
import TimelineContent from "./TimelineContent";
import { asDdMmYyyy, asMonthYyyy, asWeekYear, asYyyy } from "../../../../shared/utils/date.utils";
import TimelineColumnHeaders from "./TimelineColumnHeaders";
import TimelineRowHeaders from "./TimelineRowHeaders";
import { TimelineColumnConfig } from "../../TimelinePage";
import { Gridder } from "../../../../__generated__/types";

interface ScrollingTimelineProps<T> {
  alwaysShowMetadata: boolean;
  columnConfig: TimelineColumnConfig;
  rowType: TimelineRowType;
  values: T[];
  rowMapper: (value: T) => ScrollingTimelineRow<T>;
  dateRange: Moment[];
  cropSelection: string[];
}

export const TIMELINE_VIEW_CONFIG = {
  rowHeaderWidth: 330,
  headerHeight: 50,
  rowHeight: 40,
  detailRowHeight: 40,
  cultivationBarHeight: 20,
  cropTotalsBarHeight: 30,
};

export interface ScrollingTimelineRow<T> {
  id: string,
  type: 'plot' | 'crop',
  label: string,
  items: number[][],
  data: T,
}

export interface ScrollingTimelineColumn {
  date: Moment,
  startDate: Moment,
  endDate: Moment,
  highlighted: boolean;
}

export const relativeColumnStartPosition = (idx: number, totalColumns: number, columnWidth: number) => (columnWidth * idx) / (totalColumns * columnWidth) * 100;

export const calculateRelativePositionBoundaries = (fromIdx: number, toIdx: number, nrOfColumns: number, columnWidth: number) => ({
  left: `${relativeColumnStartPosition(fromIdx, nrOfColumns, columnWidth)}%`,
  right: `${100 - relativeColumnStartPosition(toIdx, nrOfColumns, columnWidth)}%`
});
export const calculateNrTimelineOfColumns = (startDate: Moment, endDate: Moment, granularity: TimelineGranularity) => {
  let number = endDate.diff(startDate, granularity) + 1;
  return number;
};

export type TimelineGranularity = 'y' | 'Q' | 'M' | 'w' | 'd';
export type TimelineRowType = 'crop' | 'plot';

const columnFromDate = (startDate: Moment, offset: number, granularity: TimelineGranularity): ScrollingTimelineColumn => {
  let columnDate = startDate.clone().add(offset, granularity);
  return ({
    date: columnDate,
    startDate: columnDate.clone().startOf(granularity),
    endDate: columnDate.clone().endOf(granularity),
    highlighted: columnDate.isSame(moment(), granularity),
  });
};

const ScrollingTimeline = <T extends {
  id: string,
  cultivations: {
    id: string,
    startDate: any,
    varieties: any[] | null;
    events: any[] | null;
    cropTiming: {
      startWeekNumberFrom: number,
      startWeekNumberUntil: number,
      endWeekNumberFrom: number,
      endWeekNumberUntil: number,
    },
    plantSchema: {
      plantSchema: { distanceInRow: number, distanceBetweenRows: number, gridder?: Gridder | null }
    } | null,
    crop: {
      plantSchemas: {
        plantSchema: { distanceInRow: number, distanceBetweenRows: number, gridder?: Gridder | null },
        default: boolean
      }[] | null
    },
    plot: { rotationArea: { field: { bedWidth: number } } },
    length: number,
    harvestEndDate: any,
  }[] | null
}, >(props: ScrollingTimelineProps<T>) => {
  const {columnConfig} = props;
  const [rows, setRows] = useState<ScrollingTimelineRow<T>[]>([]);
  const [allRows, setAllRows] = useState<ScrollingTimelineRow<T>[]>([]);
  const [columns, setColumns] = useState<ScrollingTimelineColumn[]>([]);
  const [rowHeights, setRowHeights] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);

  const {rowHeight, detailRowHeight} = TIMELINE_VIEW_CONFIG;

  useEffect(() => {
    const startDate = first(props.dateRange);
    const endDate = last(props.dateRange);

    if (startDate && endDate) {
      const nrOfColumns = calculateNrTimelineOfColumns(startDate, endDate, columnConfig.granularity);
      let scrollingTimelineColumns = times(nrOfColumns).map(n => columnFromDate(startDate, n, columnConfig.granularity));
      setColumns(scrollingTimelineColumns);
    }

  }, [columnConfig, props.dateRange]);

  useEffect(() => {
    if (props.values) {
      let rows = props.values.map(props.rowMapper);
      setAllRows(rows);
    }
  }, [props.values, props.rowType]);

  useEffect(() => {
    let rows = allRows
    if (searchValue) {
      rows = allRows.filter(row => row.label.toLowerCase().includes(searchValue.toLowerCase()));
    }
    if (props.cropSelection.length > 0) {
      rows = allRows.filter(row => props.cropSelection.includes(row.data.id))
    }
    setRows(rows);

    setRowHeights(rows.map(row => {
      const nrOfRowsForCultivation = compact(flatten(map(compact(row.data.cultivations), "varieties"))).length || 1;
      const rowHeightTotal = rowHeight + (expandedRowIds.includes(row.id)
        ? nrOfRowsForCultivation * detailRowHeight
        : 0);

      return rowHeightTotal;
    }));
  }, [expandedRowIds, allRows, searchValue, props.cropSelection]);

  return <div className="relative flex-auto">
    <div className="absolute top-0 bottom-0 left-0 right-0 overflow-hidden bg-gray-800">
      <div className="flex absolute overflow-scroll inset-1 border-1 border-gray-900">
        <div className="flex">
          <TimelineRowHeaders<T>
            rows={rows}
            rowHeights={rowHeights}
            granularity={columnConfig.granularity}
            expandedRowIds={expandedRowIds}
            onSearch={setSearchValue}
            onToggleExpansion={(rowId) => {
              if (expandedRowIds.includes(rowId)) {
                setExpandedRowIds(without(expandedRowIds, rowId));
              } else {
                const updatedExpandedRows = [...expandedRowIds, rowId];
                setExpandedRowIds(updatedExpandedRows);
              }
            }}
          />

          {/* MAIN TIMELINE*/}
          <div className="flex-auto-0 relative" style={{width: `${columns.length * columnConfig.columnWidth}px`}}>
            <div className="timeline-inner min-h-full top-0 absolute z-1 w-full">
              <TimelineColumnHeaders columns={columns} config={columnConfig}/>
              <TimelineContent<T>

                alwaysShowMetadata={props.alwaysShowMetadata}
                columnConfig={columnConfig}
                rowType={props.rowType}
                rows={rows}
                columns={columns}
                dateRange={props.dateRange}
                rowHeights={rowHeights}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default ScrollingTimeline;
