import React from 'react';
import { useUserObject } from "../../context/UserContext";
import FarmSelector from "./components/FarmSelector/FarmSelector";

interface HomePageProps {

}

const HomePage = (props: HomePageProps) => {
  const {user, activeFarm} = useUserObject();

  return <div>
    {!activeFarm && <FarmSelector/>}
  </div>;
};

export default HomePage;
