import XLSX from "xlsx";
import { CreateOrderItemVariables, OrderItemCreateInput } from "../../../__generated__/types";
import { CreateOrderItemMutation } from "./orders.gql";

export const handleOrderXls = (file: File, farmId: string | undefined, createOrderItem: (options: {variables: CreateOrderItemVariables}) => any) => {
  /* Boilerplate to set up FileReader */
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e: any) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, {type: rABS ? 'binary' : 'array'});
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data: string[][] = XLSX.utils.sheet_to_json(ws, {header: 1});
    /* Update state */
    let orders = data.slice(12, data.length);
    Promise.all(orders.map((order: string[]) => {
      const variables: CreateOrderItemVariables = {
        data: {
          code: order[0],
          cropName: order[1],
          name: order[2],
          amount: parseInt(order[7], 10),
          soilBlockType: order[3],
          filename: file.name,
          deliveryWeek: parseInt(order[8], 10),
          deliveryDay: parseInt(order[9], 10),
          deliveryYear: parseInt(order[10], 10),
          farm: {
            connect: {
              id: farmId,
            }
          }
        }
      };
      return createOrderItem({
        variables
      });
    })).then(values => {

    });
  };
  if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);

};
