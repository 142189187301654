import React from 'react';
import { render } from 'react-dom';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import moment from "moment";

import App from './App';

import 'moment/min/locales'
import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';
// import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
//import './theme-overwrite.css';
import 'index.css';
import { addLocale, PrimeReactProvider } from "primereact/api";
import { nl } from "./shared/utils/date.utils";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithHistory from "./shared/Auth0ProviderwithHistory";

moment.locale(['nl']);
addLocale('nl', nl);


const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
  credentials: 'same-origin',
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Cultivation: {
        fields: {
          clientFields: {
            read(_, x) {
              return "value";
            }
          }
        }
      }
    }
  }),
  link: httpLink,
});

const WrappedApp = (
  <PrimeReactProvider>
    <ApolloProvider client={client}>
      <BrowserRouter basename={'/'}>
        <Auth0ProviderWithHistory>
          <App/>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </ApolloProvider>
  </PrimeReactProvider>
);

render(WrappedApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
