import React, { useState } from 'react';
import { CultivationTask, iconForType, readable } from "../Tasks";
import { chain, groupBy, keys } from "lodash";
import { asDdMmm } from "../../../../shared/utils/date.utils";
import moment from "moment/moment";
import TaskOverviewItem from "./TaskOverviewItem";
import { CultivationEventType } from "../../../../__generated__/types";
import IconWithTooltip from "../../planning21-icon-with-tooltip";
import classNames from "classnames";
import TaskOverviewItem2 from "./TaskOverviewItem2";

interface TaskOverviewProps {
  tasks: CultivationTask[];
}

const TaskOverview = (props: TaskOverviewProps) => {
  const [grid, setGrid] = useState<boolean>(true);

  const x = chain(props.tasks)
    //.filter(t => t.id === "clca93amxcxaj077585y5sysi")
    .orderBy(['taskDate', 'plot.order'], ['asc', 'asc'])
    .groupBy('taskWeekNumber')
    .value();

  return <div className={'ml-2 mr-2'}>
    {keys(x).map(taskWeekNumber => {
      const week = moment().isoWeek(parseInt(taskWeekNumber));
      const tasksByTaskType = groupBy(x[taskWeekNumber],'taskType');
      return <div key={`tasks-week-${taskWeekNumber}`} className='mb-3'>
        <div className={'flex justify-content-between align-items-center mb-1 surface-50 p-2'}>
          <div className='font-bold text-xl white-space-nowrap'>
            <div>Week {taskWeekNumber}</div>
            <div className="text-xs text-800">
              {asDdMmm(week.clone().startOf('isoWeek'))} <br/>{asDdMmm(week.clone().endOf('isoWeek'))}
            </div>
          </div>
          <div className="flex flex-wrap white-space-nowrap flex-column">
            {[
              {type: CultivationEventType.PLANT, label: "Planten"},
              {type: CultivationEventType.PRESEED, label: "Voorzaaien"},
              {type: CultivationEventType.FLAMEWEED, label: "Flameweeden"},
              {type: CultivationEventType.SEED, label: "Zaaien"},
            ].map(item => {
              const icon = iconForType(item.type);
              let length = x[taskWeekNumber].filter(task => task.taskType === item.type).length;
              if (length > 0) {
                return <div key={item.type} className="md:mr-4 lg:mr-6 flex align-items-center justify-content-end">
                  <div className="mr-1 text-base">{item.label}</div>
                  <div className="pr-2 text-lg text-left font-bold w-2rem">{length}</div>
                  <div className="pr-4 flex align-items-center min-w-2rem w-2rem">{icon &&
                    <IconWithTooltip size={20} name={icon} text={readable(item.type)} id={`icon_${item.type}`}/>}
                  </div>
                </div>;
              }
              return undefined;
            })}
          </div>
        </div>

        {/*<div className="grid">*/}
        {/*  {keys(tasksByTaskType).map(taskType => {*/}
        {/*    return <div key={`${taskWeekNumber}_${taskType}`} className="col-12 md:col-6 lg:col-4">*/}
        {/*      <div className="font-bold mt-3 pb-2border-bottom-1 border-100">{taskType}</div>*/}
        {/*      {tasksByTaskType[taskType].map(task => {*/}
        {/*        return <div key={`${taskWeekNumber}_${taskType}_${task.id}`}>*/}
        {/*          <TaskOverviewItem2 task={task}/>*/}
        {/*        </div>*/}
        {/*      })}*/}
        {/*    </div>*/}

        {/*  })}*/}
        {/*</div>*/}

        <div className={classNames("md:mx-2",
          {"grid": grid},
          {"flex flex-column": !grid},
        )}>
          {x[taskWeekNumber].map((task, idx) => {
            return <div key={`task-${taskWeekNumber}-${task.id}-${idx}`} className={
              classNames("flex align-items-stretch",
                {"col-12 md:col-6 xl:col-4":grid})
            }><TaskOverviewItem task={task} asRow={!grid}/>
            </div>
          })}
        </div>
      </div>;
    })}
  </div>;
};

export default TaskOverview;
