import React, { useState } from 'react';
import { CropDetails_crop, CropDetails_crop_varieties } from "../../../../../../__generated__/types";
import styled from "styled-components";
import VarietiesList from "./VarietiesList";
import AddVariety from "./AddVariety";
import { compact } from 'lodash';

interface VarietiesProps {
  crop: CropDetails_crop;
}

const Container = styled.div`
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: max-content;
  grid-gap: 16px;

  > div {
    background-color: var(--surface-c);
  }
`;

export const AddButtonBlock = styled.div`
  margin: 16px;
  border: 4px dashed var(--text-color-secondary);
  color: var(--text-color-secondary);
  display: grid;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
`;

const AddVarietyContainer = styled.div`
  align-self: stretch;
  display: grid;
  min-height: 250px;
`;

const Varieties = (props: VarietiesProps) => {
  const [mode, setMode] = useState<'read' | 'edit' | 'create'>('read');

  const [variety, setVariety] = useState<CropDetails_crop_varieties>();

  const varieties = compact(props.crop.varieties);
  return <Container>
    <AddVarietyContainer>
      {mode === 'read' &&
        <AddButtonBlock onClick={() => setMode('create')}>+ Vari&euml;teit toevoegen</AddButtonBlock>
      }
      {(mode === 'create' || mode === 'edit') &&
        <div style={{alignSelf: 'start'}}>
          <AddVariety
            onClose={() => {
              setMode('read');
              setVariety(undefined);
            }}
            cropId={props.crop.id}
            variety={variety}
          />
        </div>
      }
    </AddVarietyContainer>
    <div>
      <VarietiesList
        varieties={varieties}
        onClick={(varietyId: string) => {
          setMode('edit');
          setVariety(varieties.find(v => v.id === varietyId));
        }}
      />
    </div>

  </Container>;
};

export default Varieties;
