import React, { FunctionComponent } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import { Routes } from "react-router";
import Planning21 from "./pages/21/Planning21/Planning21";
import styled, { createGlobalStyle } from "styled-components";
import CropManagement from 'pages/21/crop-management/CropManagement';
import Planning21Orders from "./pages/21/orders/planning21-orders";
import { PlanningProvider } from "./pages/21/planning21-context";
import PlantSchemaPage from "./pages/21/PlantSchemaPage/PlantSchemaPage";
import Tasks from "./pages/21/Tasks/Tasks";
import SeedsPage from "./pages/21/Seeds/SeedsPage";
import { px2vw } from "./utils/px2vw.util";
import { Menubar } from "primereact/menubar";
import FieldManagement from "./pages/21/FieldManagement/FieldManagement";
import TimelinePage from "./pages/TimelinePage/TimelinePage";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "primereact/button";
import UserMenu from "./shared/components/UserMenu/UserMenu";
import appConfig from "./config.app";
import { UserProvider, useUserObject } from "./context/UserContext";
import HomePage from "./pages/HomePage/HomePage";
import FarmSwitch from "./shared/components/FarmSwitch";
import FarmSelector from "./pages/HomePage/components/FarmSelector/FarmSelector";
import UnauthorizedAccessPage from "./pages/UnauthorizedAccessPage";
import { compact } from "lodash";
import { hasPermission, hasPlannerPermission } from "./utils/permission.utils";
import { Permission } from "./__generated__/types";
import { Sidebar } from "primereact/sidebar";

const Global = createGlobalStyle`
  //* {
  //  margin: 0;
  //  padding: 0;
  //  box-sizing: border-box;
  //}

  // :root {
  //     font-size: ${px2vw(24)};
  //
  //     @media (min-width: 768px) {
  //       font-size: ${px2vw(18)};
  //     }
  //
  //     @media (min-width: 1024px) {
  //       font-size: ${px2vw(16)};
  //     }
  //   }
`;

const ROUTES = [
  {
    path: "/accessDenied",
    component: UnauthorizedAccessPage,
    hidden: true,
  },
  {
    path: "/",
    side: "left",
    component: HomePage,
    label: 'Home',
    menu: {
      title: 'Home',
    },
    needsActiveFarm: false,
    requiredPermission: Permission.PLANNER_FARM_ADMIN,
  },
  {
    path: "/planning",
    side: "left",
    component: Planning21,
    label: '2021 planning',
    menu: {
      title: '2021 planning',
    },
    needsActiveFarm: true,
    requiredPermission: Permission.PLANNER_FARM_ADMIN,
  },
  {
    path: "/timeline",
    side: "left",
    component: TimelinePage,
    needsActiveFarm: true,
  },
  {
    path: "/tasks",
    side: "left",
    component: Tasks,
    label: 'Taken',
    menu: {
      title: 'Taken',
    },
    needsActiveFarm: true,
    requiredPermission: Permission.PLANNER_FARM_ADMIN,
  },
  // {
  //   path: "/workList",
  //   side: "left",
  //   component: WorkList,
  //   label: 'Taken 2.0',
  //   menu: {
  //     title: 'Taken 2.0',
  //   },
  // },
  {
    path: "/cropManagement",
    side: "left",
    component: CropManagement,
    label: 'Gewasbeheer',
    menu: {
      title: 'Gewasbeheer',
    },
    needsActiveFarm: true,
    requiredPermission: Permission.PLANNER_FARM_ADMIN,
  },
  {
    path: "/orders",
    side: "left",
    component: Planning21Orders,
    label: 'Bestellingen',
    menu: {
      title: 'Bestellingen',
    },
    needsActiveFarm: true,
    requiredPermission: Permission.PLANNER_FARM_ADMIN,
  },
  {
    path: "/seeds",
    side: "left",
    component: SeedsPage,
    label: 'Zaden',
    menu: {
      title: 'Zaden',
    },
    needsActiveFarm: true,
    requiredPermission: Permission.PLANNER_FARM_ADMIN,
  },
  {
    path: "/plantSchema",
    side: "left",
    component: PlantSchemaPage,
    label: 'Plant schema\'s',
    menu: {
      title: 'Plant schema\'s',
    },
    needsActiveFarm: true,
    requiredPermission: Permission.PLANNER_FARM_ADMIN,
  },
  // {
  //   path: "/harvestList",
  //   side: "left",
  //   component: HarvestList,
  //   label: "Oogstlijst",
  //   menu: {
  //     title: "Oogstlijst",
  //   }
  // },
  {
    path: "/fieldManagement",
    side: "left",
    component: FieldManagement,
    label: "Veldbeheer",
    menu: {
      title: "Veldbeheer",
    },
    needsActiveFarm: true,
    requiredPermission: Permission.PLANNER_FARM_ADMIN,
  },
  // {
  //   path: "/endDate",
  //   side: "left",
  //   component: EndDatePage,
  //   label: "Einddatum",
  //   menu: {
  //     title: "Einddatum",
  //   }
  // },
  // {
  //   path: "/harvestCalendar",
  //   side: "left",
  //   component: HarvestCalendar,
  //   label: "Oogstkalender",
  //   menu: {
  //     title: "Oogstkalender",
  //   }
  // },
];

const AppHeader = styled.div`
  height: var(--header-height);
  box-shadow: 0 0 4px rgba(0, 0, 0, .25);
  border-bottom: 1px solid var(--surface-d);
  display: grid;
  margin-bottom: 10px;
  grid-template-columns: 1fr max-content;
  align-items: center;
  background-color: var(--surface-a);
  color: var(--text-color);
`;

const Body = styled.div`
  //.p-inputtext, .p-togglebutton {
  //  padding: 2px 4px;
  //}

  //.p-selectbutton {
  //  //display: grid;
  //  //grid-template-columns: repeat(2,1fr);
  //}

  //.p-selectbutton .p-button.p-highlight {
  //  background-color: var(--primary-color);
  //}

  //.p-button {
  //  padding: 4px;
  //}

  //h1, h2, h3, h4, h5, h6 {
  //  color: var(--text-color);
  //}
`;

const App: FunctionComponent = () => {
  const navigate = useNavigate();
  const {user: auth0User, loginWithRedirect, logout} = useAuth0();
  const {user, activeFarm} = useUserObject();

  if (!auth0User) {
    return <div className="fixed top-0 left-0 bottom-0 right-0 flex align-items-center justify-content-center">
      <Button label={"Aanmelden"} onClick={loginWithRedirect}/>
    </div>;
  } else if (user) {
    if (!activeFarm) {
      return <FarmSelector/>;
    }
  }

  let routes = ROUTES
    .filter(route => route.requiredPermission === undefined || (route.requiredPermission && hasPermission(user, route.requiredPermission)))
    .filter(route => !route.needsActiveFarm || (route.needsActiveFarm && activeFarm));
  return (
    <>
      <Global/>

      <AppHeader id="header">
        <Menubar
          start={<FarmSwitch/>}
          end={<div className="flex align-items-center">
            <UserMenu items={[]} redirectUrl={appConfig.redirectUrl}/>
          </div>
          }
          model={routes.filter(route => !route.hidden)
            .map(route => ({
              ...route,
              command: () => navigate(route.path),
            }))}/>
        {/*<Menu>*/}
        {/*  <i className="pi pi-bars overlayMenu"/>*/}
        {/*  <div className="menu">*/}
        {/*    {leftRoutes.map(({menu, path}, idx) =>*/}
        {/*      <MenuItem active={activeLeftIdx === idx} key={idx}>*/}
        {/*        <NavLink to={path}>*/}
        {/*          <span>{menu.title}</span>*/}
        {/*        </NavLink>*/}
        {/*      </MenuItem>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</Menu>*/}
      </AppHeader>
      {/*<Body id="body" style={{display: 'grid', gridTemplateColumns: '1fr', paddingTop: '16px', overflow: 'scroll'}}>*/}
      <Body id="body" style={{overflow: 'scroll'}}>
        <Routes>
          {routes.map(({component: Component, path, requiredPermission}) => {
            if (!hasPlannerPermission(user) ||
              (requiredPermission === null || (requiredPermission && !hasPermission(user, requiredPermission)))
            ) {
              return <Route key={path} path={path} element={<UnauthorizedAccessPage/>}/>;
            }

            return <Route key={path} path={path} element={<Component/>}/>;
            }
          )}
        </Routes>
      </Body>
      <div className={'footer'}></div>
    </>
  );
};

const RoutedApp = () => <UserProvider><PlanningProvider><App/></PlanningProvider></UserProvider>;

export default RoutedApp;
