import React from 'react';
import { ReactComponent as IconPlant } from './plant.svg';
import { ReactComponent as IconSeed } from './seed.svg';
import { ReactComponent as IconFlame } from './flame.svg';
import styled from "styled-components";
import { prop } from "styled-tools";

export interface IconProps {
  name: string;
  size?: number;
  id?: string;
}

const ICONS = [
  {name: 'plant', svg: <IconPlant/>, color: 'yellow'},
  {name: 'seed', svg: <IconSeed/>, color: 'orange'},
  {name: 'flame', svg: <IconFlame/>, color: 'red'},
];

const IconWrapper = styled.div<{color: string, size?: number}>`
  > svg {
    width: ${prop('size', 12)}px;
    height: ${prop('size', 12)}px;
    fill: ${props => props.color};
  }
`;

const Icon = (props: IconProps) => {
  const icon = ICONS.find(icon => icon.name === props.name);
  return icon ? <IconWrapper id={props.id} size={props.size} color={icon.color}>{icon.svg}</IconWrapper> : <div/>;
};
export default Icon;
