import React, { Fragment } from 'react';
import styled from "styled-components";
import { usePlanning } from "../../planning21-context";
import { chain, findIndex, get, max, min, set, sum } from "lodash";
import moment from "moment";
import { CultivationForCropTimeline } from "./planning21-crop-timeline";
import { calculateHarvestWindow, calculateSowingOrPlantingWindow } from "../planning21-mutations";
import { Tooltip } from 'primereact/tooltip';

interface PlotTimelineProps {
  maxOccupation: number | undefined;
  cultivations: CultivationForCropTimeline[];
  color: string;
  id: string;
  opacity?: number;
  showTopValue?: boolean;
  inter?: boolean;
}

const Container = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Occupation = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

`;

const plotOccupation = (cultivations: CultivationForCropTimeline[], years: number[]) => {
  const lastDayInTimeline = moment(max(years), 'YYYY').endOf('y');
  const firstDayInTimeline = moment(min(years), 'YYYY').startOf('y');

  // [day]: [start, end value of that single day, e.g.: 20,0]
  const message = chain(cultivations)
    .orderBy(['startDateUnix'], ['asc'])
    .reduce((occupation: { day: number, length: number }[], cultivation: CultivationForCropTimeline) => {
      let updatedOccupation = occupation;
      const sowingOrPlantingWindow = calculateSowingOrPlantingWindow(cultivation.cropTiming, cultivation.startDate);
      const harvestWindow = calculateHarvestWindow(cultivation.cropTiming, cultivation.startDate, cultivation.harvestEndDate);

      if (cultivation
        && cultivation.length
      ) {
        const firstDay = sowingOrPlantingWindow.firstDay.clone();
        let lastDay = harvestWindow.lastDay.clone();


        while (lastDay.diff(firstDay, 'days') >= 0) {
          if (firstDay.isBetween(firstDay, lastDay, 'd', '[]') &&
            firstDay.isBetween(firstDay, lastDayInTimeline, 'd', '[]')
          ) {
            const day = firstDay.diff(firstDayInTimeline, 'd');

            const occupationIdxForDay = findIndex(updatedOccupation, ['day', day]);
            if (occupationIdxForDay > -1) {
              const updatedLength = updatedOccupation[occupationIdxForDay].length + cultivation.length;
              updatedOccupation = set(updatedOccupation, `[${occupationIdxForDay}].length`, updatedLength);
            } else {
              updatedOccupation = [
                ...updatedOccupation,
                {
                  day,
                  length: cultivation.length,
                }
              ];
            }
          }
          firstDay.add(1, 'days')
        }
      }
      return updatedOccupation;
    }, [])
    .value();

  const convertToWindows = (data: { day: number, length: number }[]) => {
    let result: { start: number, end: number, length: number }[] = [];
    data.forEach(v => {
      let rl = result.length;
      if (rl === 0 || result[rl - 1].end + 1 !== v.day || result[rl - 1].length !== v.length) {
        result.push({start: v.day, end: v.day, length: v.length});
      } else result[rl - 1].end += 1;
    });
    return result;
  };

  return convertToWindows(message);
};

const PlotTimeline = (props: PlotTimelineProps) => {
  const {years} = usePlanning();

  const daysInTimeline = sum(years.map(year => moment(year, 'YYYY').endOf('y').dayOfYear()));

  let plotOccupationValues = plotOccupation(props.cultivations, years);
  let actualOccupationMax = chain(plotOccupationValues).map('length').max().value();
  return <Container>
    {plotOccupationValues.map((occupation, idx) => {
      const startPercentage = `${occupation.start / daysInTimeline * 100}%`;
      const endPercentage = `${(occupation.end - occupation.start + 1) / daysInTimeline * 100}%`;

      const occupationRatio = props.maxOccupation ? occupation.length / props.maxOccupation : 1;

      return <Fragment key={`plot-timeline-wrapper-${get(props.cultivations, '[0].plot.id')}-${startPercentage}`}>
        {occupationRatio > 1 && <Occupation
          style={{
            cursor: 'pointer',
            left: startPercentage,
            width: endPercentage,
            top: `-${(occupationRatio - 1) * 100}%`,
            bottom: '100%',
            backgroundColor: 'red',
            opacity: 0.7,
          }}
        />}
        <Tooltip
          target={`#${props.id}${idx}`} content={`${occupation.length}/${props.maxOccupation}`}
          position={"top"}
        />
        <Occupation
          id={props.id + idx}
          style={{
            left: startPercentage,
            width: endPercentage,
            top: `${(1 - (occupationRatio <= 1 ? occupationRatio : 1)) * 100}%`,
            backgroundColor: props.color,
            opacity: props.opacity || 0.3,
            borderTop: '0px solid rgb(76, 255,0)',
            zIndex: props.inter ? 100 : actualOccupationMax === occupation.length ? 99 :1,
            borderWidth: (occupationRatio - 1) * 100 === 0 ? '4px' : '0px',
          }}
        >
          {props.showTopValue && actualOccupationMax === occupation.length && `${(((occupationRatio) * 100).toFixed(0))}%`}
        </Occupation>
      </Fragment>;
    })}
    {/*<Chart type="line" data={basicData} options={options} height={'20px'}/>*/}
  </Container>;
};

export default PlotTimeline;
