export const nameForPlot = (plot: {
  rotationArea: { number: number, field: { name: string } },
} | undefined | null) => {
  return `${plot?.rotationArea.field.name}-${plot?.rotationArea.number}`;
};

export const prefixedNameForPlot = (plot: {
  rotationArea: { number: number, field: { prefix: string } },
} | undefined | null) => {
  return `${plot?.rotationArea.field.prefix}-${plot?.rotationArea.number}`;
};
