const prod = {
  environment: "production",
  graphQLHost: 'https://data.groentegeweld.be/',
  redirectUrl: 'https://planning.groentegeweld.be/',
  token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InNlcnZpY2UiOiJkZWZhdWx0QGRlZmF1bHQiLCJyb2xlcyI6WyJhZG1pbiJdfSwiaWF0IjoxNjE4ODY2ODcwLCJleHAiOjE2MTk0NzE2NzB9.vVhnXu0evyhIjZU1NWFYXMS0vp4PbCq026tJmAQ6SqI',
};

const dev = {
  environment: "development",
  // graphQLHost: 'http://localhost:4466',
  // token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InNlcnZpY2UiOiJkZWZhdWx0QGRlZmF1bHQiLCJyb2xlcyI6WyJhZG1pbiJdfSwiaWF0IjoxNjE4ODY2ODcwLCJleHAiOjE2MTk0NzE2NzB9.vVhnXu0evyhIjZU1NWFYXMS0vp4PbCq026tJmAQ6SqI',
  token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InNlcnZpY2UiOiJkZWZhdWx0QGRlZmF1bHQiLCJyb2xlcyI6WyJhZG1pbiJdfSwiaWF0IjoxNjE4ODY2ODcwLCJleHAiOjE2MTk0NzE2NzB9.vVhnXu0evyhIjZU1NWFYXMS0vp4PbCq026tJmAQ6SqI',
  graphQLHost: 'http://134.209.195.213:4466/',
  redirectUrl: 'http://localhost:3000/',
};

const appConfig = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default appConfig;
