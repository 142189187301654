import { Season } from "./__generated__/types";
import { sortedLastIndex } from "lodash";

export default {
  currentPlanningSeason: Season.S_2024_2025,
  currentPlanningYear: 2024,
};

export const soilBlockSizes = [
    {label: "4cm (+/-150pl.)", value: "B4", nrOfPlants: 150},
    {label: "7,5cm (+/-40pl.)", value: "B7.5", nrOfPlants: 40},
    {label: "3cm (+/-216pl.)", value: "B3", nrOfPlants: 216},
    {label: "2cm (+/-384pl.)", value: "B340", nrOfPlants: 384},
    {label: "10cm (24pl.)", value: "B10", nrOfPlants: 24},
    {label: "10cm geënt (24pl.)", value: "GB10", nrOfPlants: 24},
];


export const getSoilBlockSize = (value: string) => soilBlockSizes.find(soilBlockSize => soilBlockSize.value === value);
