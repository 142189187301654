import React, { useEffect, useState } from 'react';
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { AllSuppliers, AllSuppliers_suppliers, CropDetails_crop_varieties, DeleteVariety, PlantOrSeed, UpsertVarietyForCrop, UpsertVarietyForCropVariables } from "../../../../../../__generated__/types";
import { Dropdown } from "primereact/dropdown";
import styled from "styled-components";
import { Button } from "primereact/button";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { AllSuppliersQuery, DeleteVariety21 } from "../../../../planning21.gql";
import { compact } from 'lodash';
import { UpsertVarietyForCropQuery } from "./AddVariety.gql";
import { soilBlockSizes } from "../../../../../../config";
import { InputNumber } from "primereact/inputnumber";

interface AddVarietyProps {
  cropId: string;
  variety?: CropDetails_crop_varieties;
  onClose: () => void;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 4px;
  padding: 8px 16px;

  > div {
    display: grid;
    align-items: stretch;
  }
`;

export const Actions = styled.div`
  grid-column: 1 / span 2;
  display: grid;
  grid-template-columns: 1fr max-content;
  margin-top: 16px;

  > div {
    button {
      margin-right: 4px;
    }
  }
`;

const AddVariety = (props: AddVarietyProps) => {
  const {data: suppliersData} = useQuery<AllSuppliers>(AllSuppliersQuery);
  const [suppliers, setSuppliers] = useState<AllSuppliers_suppliers[]>();
  const [upsertVariety] = useMutation<UpsertVarietyForCrop>(UpsertVarietyForCropQuery);
  const [deleteVariety] = useMutation<DeleteVariety>(DeleteVariety21);

  const [upsertVarietyVariables, setUpsertVarietyVariables] = useState<Partial<UpsertVarietyForCropVariables>>({});

  useEffect(() => {
    if (suppliersData && suppliersData.suppliers) {
      setSuppliers(compact(suppliersData.suppliers));
    }
  }, [suppliersData]);

  useEffect(() => {
    let cropId = props.cropId;
    if (props.variety) {
      setUpsertVarietyVariables({
        id: props.variety.id,
        cropId,
        supplierId: props.variety.supplier ? props.variety.supplier.id : undefined,
        alternativeCropName: props.variety.alternativeCropName,
        code: props.variety.code,
        name: props.variety.name,
        pricePerPlant: props.variety.pricePerPlant,
        type: props.variety.type,
        orderWeekFrom: props.variety.orderWeekFrom,
        orderWeekUntil: props.variety.orderWeekUntil,
        soilBlockType: props.variety.soilBlockType,
      });
    } else {
      setUpsertVarietyVariables(state => ({...state, cropId, id: 'new'}));
    }
  }, [props.cropId, props.variety]);
  return <Container>
    <span>Subgewas</span>
    <div>
      <InputText value={upsertVarietyVariables.alternativeCropName || ''} onChange={(e: any) => {
        setUpsertVarietyVariables({...upsertVarietyVariables, alternativeCropName: e.target.value});
      }}/>
    </div>

    <span>Ras</span>
    <div>
      <InputText value={upsertVarietyVariables.name || ''} onChange={(e: any) => {
        setUpsertVarietyVariables({...upsertVarietyVariables, name: e.target.value});
      }}/>
    </div>

    <span>Code</span>
    <div>
      <InputText value={upsertVarietyVariables.code || ''} onChange={(e: any) => {
        setUpsertVarietyVariables({...upsertVarietyVariables, code: e.target.value});
      }}/>
    </div>

    <span>Plant/zaad</span>
    <div>
      <SelectButton
        options={[{value: PlantOrSeed.PLANT, label: 'Plantgoed'}, {value: PlantOrSeed.SEED, label: 'Zaadgoed'}]}
        value={upsertVarietyVariables.type || ''} onChange={(e: any) => {
        setUpsertVarietyVariables({...upsertVarietyVariables, type: e.value});
      }}/>
    </div>

    <span>Leverancier</span>
    <div>
      <Dropdown optionLabel={'name'} optionValue={'id'} options={suppliers}
                value={upsertVarietyVariables.supplierId || ''} onChange={(e: any) => {
        setUpsertVarietyVariables({...upsertVarietyVariables, supplierId: e.value});
      }}/>
    </div>
    <span>Verpakking</span>
    <div>
      <Dropdown options={soilBlockSizes}
                    value={upsertVarietyVariables.soilBlockType || ''} onChange={(e: any) => {
        setUpsertVarietyVariables({...upsertVarietyVariables, soilBlockType: e.value});
      }}/>
    </div>
    <span>Zaadgewicht per rij per meter (gram)</span>
    <div>
      <InputNumber
        locale={'nl-NL'}
        maxFractionDigits={3}
        value={upsertVarietyVariables.gramsPerRowMeter}
        onChange={(e) => {
          if(e.value) {
            setUpsertVarietyVariables({...upsertVarietyVariables, gramsPerRowMeter: e.value});
          }
        }}
      />
    </div>
    <Actions>
      <div>
        <Button
          icon="pi pi-trash"
          iconPos="right"
          className={'p-button-danger p-button-rounded p-button-outlined'}
          onClick={() => {
            if (upsertVarietyVariables.id) {
              deleteVariety({variables: {id: upsertVarietyVariables.id}})
                .then(props.onClose);
            }
          }}
        />
      </div>
      <div>
        <Button
          label={'Annuleren'}
          className={'p-button-text p-button-secondary'}
          onClick={() => {
            props.onClose();
          }}
        />
        <Button label={`Variëteit ${props.variety ? 'wijzigen' : 'toevoegen'}`} onClick={() => {
          upsertVariety({variables: upsertVarietyVariables}).then(props.onClose);
        }}/>
      </div>
    </Actions>
  </Container>;
};

export default AddVariety;
