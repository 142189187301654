import gql from "graphql-tag";

export const UpsertVarietyForCropQuery = gql`
mutation UpsertVarietyForCrop(
    $id: ID,
  	$cropId: ID!,
    $name: String!,
    $alternativeCropName: String,
    $pricePerPlant: Float,
    $code: String,
    $supplierId: ID!,
    $type: PlantOrSeed,
    $orderWeekFrom: Int,
    $orderWeekUntil: Int,
    $soilBlockType: String,
    $gramsPerRowMeter: Float,
    ) {
    updateCrop(
      where: {id: $cropId}
      data: {
        varieties: {
          upsert: [
            {
              where: {
                id: $id
              }
              update: {
                supplier: {connect: {
                  id: $supplierId
                }}
                name: $name
                alternativeCropName: $alternativeCropName
                pricePerPlant: $pricePerPlant
                code: $code
                type: $type
                orderWeekFrom: $orderWeekFrom
                orderWeekUntil: $orderWeekUntil
                soilBlockType: $soilBlockType
                gramsPerRowMeter: $gramsPerRowMeter
              }
              create: {
                supplier: {connect: {
                  id: $supplierId
                }}
                name: $name
                alternativeCropName: $alternativeCropName
                pricePerPlant: $pricePerPlant
                code: $code
                type: $type
                orderWeekFrom: $orderWeekFrom
                orderWeekUntil: $orderWeekUntil
                soilBlockType: $soilBlockType
                gramsPerRowMeter: $gramsPerRowMeter
              }
            }
          ]
        }
      }
    ) {
      id
     varieties {
        id
        name
        type
        code
        alternativeCropName
        orderWeekFrom
        orderWeekUntil
        gramsPerRowMeter
        soilBlockType
        supplier {
          id
          name
        }
        pricePerPlant
      }
    }
}`;
