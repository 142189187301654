import React, { Fragment } from 'react';
import { CultivationTask } from "../Tasks";
import { CultivationEventType } from "../../../../__generated__/types";
import { ceil, compact } from "lodash";
import { gridderNrOfDiscs, plantAmounts } from "../../../../utils/planting.util";
import { formatDoubleDigit } from "../../../../shared/utils/currency.utils";
import PlantSchemaDrawing from "../../../../components/PlantSchemaDrawing/PlantSchemaDrawing";
import { plantSchemaForCalculations } from "../../planning21-context";
import cultivationRow from "../../Planning21/components/CultivationRow";

interface TaskOverviewItemDetailProps {
  task: CultivationTask;
}

const TaskOverviewItemDetail = (props: TaskOverviewItemDetailProps) => {
  const {task} = props;

  const plantSchemaForCalculationsValue = plantSchemaForCalculations(task);
  const plantSchemaForParentForCalculationsValue = task.parent && plantSchemaForCalculations(task.parent);
  const plantingAmounts = plantSchemaForCalculationsValue
    ? plantAmounts(plantSchemaForCalculationsValue.plantSchema,
      task.length,
      task.plot.rotationArea.field.bedWidth,
      plantSchemaForParentForCalculationsValue?.plantSchema)
    : undefined;

  let nrOfPlants = plantingAmounts ? plantingAmounts.totalPlants : NaN;

  const nrOfPlantsValue = (nrOfPlants && nrOfPlants > 0) ? `${nrOfPlants}pl` : '-';

  let varieties = compact(task.varieties);
  return <div className='flex align-items-center'>
    <div className="col grid ml-2 mt-2 text-xs sm:text-sm">
      {(task.taskType === CultivationEventType.SEED || task.taskType === CultivationEventType.PLANT) && <>
        <div className='col-5 text-right py-0 text-400 text-xs sm:text-sm'>Lengte</div>
        <div className='col-7 font-bold py-0'>{task.length && `${task.length}m`}</div>
      </>}

      {(task.taskType === CultivationEventType.PLANT || task.taskType === CultivationEventType.PRESEED) && <>
        <div className='col-5 text-right py-0 text-400 text-xs sm:text-sm'># planten</div>
        <div className="col-7 py-0">
          <div className={'font-bold'}>
            {nrOfPlantsValue}
          </div>
          {nrOfPlants && <div className="text-xs">({ceil(nrOfPlants / 150, 0)} bakken)</div>}
        </div>
      </>}
      {(task.taskType === CultivationEventType.SEED || task.taskType === CultivationEventType.PLANT)
        && plantSchemaForCalculationsValue
        && <>
          <div className="col-5 text-right py-0 text-400 text-xs sm:text-sm">Gridder</div>
          <div
            className="col-7 py-0 font-bold">{gridderNrOfDiscs(plantSchemaForCalculationsValue.plantSchema.gridder)}</div>
        </>}

      {(task.taskType === CultivationEventType.SEED || task.taskType === CultivationEventType.PLANT) && <>
        <div className='col-5 text-right py-0 text-400 text-xs sm:text-sm'># rijen</div>
        <div className='col-7 font-bold py-0'>{plantingAmounts?.nrOfRows} rijen</div>
      </>}

      {task.taskType === CultivationEventType.PRESEED && <>
        <div className='col-5 text-right py-0 pb-3 text-400 text-xs sm:text-sm'>Trays</div>
        <div className={'col-7 font-bold py-0 pb-3'}>
          {nrOfPlants && <div>{formatDoubleDigit(nrOfPlants / 77)} trays</div>}
        </div>
        {varieties.length > 1 && varieties.map(variety =>
          <Fragment key={`task-item-detail-${task.id}-${variety.id}`}>
            <div className="col-5 text-right text-xs py-0">{variety.variety.name}</div>
            <div className="col-7 py-0 text-xs">{ceil(nrOfPlants * variety.percentage)} planten</div>
          </Fragment>
        )}
      </>}


    </div>
    <div className="col flex mr-2 justify-content-end max-h-8rem">
      {(task.taskType === CultivationEventType.SEED || task.taskType === CultivationEventType.PLANT)
        && plantSchemaForCalculationsValue
        &&
        <PlantSchemaDrawing
          size={"100%"}
          plantSchema={plantSchemaForCalculationsValue.plantSchema}
          bedWidth={task.plot.rotationArea.field.bedWidth}
          hideNrOfPlants
        />}
    </div>
  </div>
    ;
};

export default TaskOverviewItemDetail;
