import React from 'react';
import { GetFarm } from "../../../__generated__/types";
import { compact } from 'lodash';
import { useQuery } from "@apollo/client";
import { GetFarmQuery } from './FieldManagement.gql';
import styled from "styled-components";
import FieldsList from "./components/FieldsList";
import config from "../../../config";
import { useUserObject } from "../../../context/UserContext";

interface FieldManagementProps {

}

const Container = styled.div`
  margin: 0 2vw;
`;


const FieldManagement = (props: FieldManagementProps) => {
  const {activeFarm} = useUserObject();
  const {data: farmData} = useQuery<GetFarm>(GetFarmQuery, {variables: {id: activeFarm?.id}});

  return <Container>
    <h1>Veldbeheer {farmData?.farm?.name}</h1>
    {compact(farmData?.farm?.locations).map(location =>
      <div>
        <h2>Locatie: {location.name}</h2>
        <FieldsList location={location.id}/>
      </div>
    )}
  </Container>;
};


export default FieldManagement;
