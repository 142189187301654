import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { InputText } from "primereact/inputtext";
import { AllCropFamilies, AllCropFamilies_cropFamilies, AllCropGroups, AllCropGroups_cropGroups, CreateCrop, CreateCropVariables } from "../../../__generated__/types";
import { CreateCropMutation } from "../planning21.gql";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { AllCropFamiliesQuery, AllCropGroupsQuery } from "./CropManagement.gql";
import { compact } from 'lodash';

interface AddCropProps {
  onClose: () => void;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 4px;
  padding: 8px 16px;

  > div {
    display: grid;
    align-items: stretch;
  }
`;

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  margin-top: 16px;

  > div {
    button {
      margin-right: 4px;
    }
  }
`;

const AddCrop = (props: AddCropProps) => {
  const [createCrop] = useMutation<CreateCrop>(CreateCropMutation);
  const [createCropVariables, setCreateCropVariables] = useState<Partial<CreateCropVariables>>({name: ''});
  const {data: cropFamiliesData} = useQuery<AllCropFamilies>(AllCropFamiliesQuery);
  const {data: cropGroupsData} = useQuery<AllCropGroups>(AllCropGroupsQuery);
  const [cropFamilies, setCropFamilies] = useState<AllCropFamilies_cropFamilies[]>();
  const [cropGroups, setCropGroups] = useState<AllCropGroups_cropGroups[]>();

  useEffect(() => {
    if (cropFamiliesData && cropFamiliesData.cropFamilies) {
      setCropFamilies(compact(cropFamiliesData.cropFamilies));
    }
  }, [cropFamiliesData]);

  useEffect(() => {
    if (cropGroupsData && cropGroupsData.cropGroups) {
      setCropGroups(compact(cropGroupsData.cropGroups));
    }
  }, [cropGroupsData]);

  return <Container>
    <span>Naam</span>
    <div>
      <InputText value={createCropVariables.name} onChange={(e: any) => {
        setCreateCropVariables({...createCropVariables, name: e.target.value});
      }}/>
    </div>
    <span>Groep</span>
    <div>
      <Dropdown options={cropGroups} optionLabel={'name'} optionValue={'id'} value={createCropVariables.groupId || ''}
                onChange={(e: any) => {
                  setCreateCropVariables({...createCropVariables, groupId: e.value});
                }}/>
    </div>
    <span>Familie</span>
    <div>
      <Dropdown options={cropFamilies} optionLabel={'name'} optionValue={'id'}
                value={createCropVariables.familyId || ''} onChange={(e: any) => {
        setCreateCropVariables({...createCropVariables, familyId: e.value});
      }}/>
    </div>
    <Actions>
      <div>
      </div>
      <div>
        <Button
          label={'Annuleren'}
          className={'p-button-text p-button-secondary'}
          onClick={props.onClose}
        />
        <Button label={`Gewas toevoegen`} onClick={() => {
          createCrop({variables: createCropVariables})
            .then(props.onClose);
        }}/>
      </div>
    </Actions>
  </Container>
};

export default AddCrop;
