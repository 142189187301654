import gql from "graphql-tag";

export const CultivationsForTasksQuery = gql`
query CultivationsForTasks($farm: ID!, $season: Season!) {
  cultivations(
    where: {
      season: $season
      plot: {rotationArea: {field: {location: {farm: {id: $farm}}}}}
    }
  ) {
    remark
    id
    plot {
      number
      rotationArea {
        number
        field {
          name
          prefix
          bedWidth
          bedLength
        }
      }
    }
    startDate
    harvestEndDate
    pinned
    length
    varieties {
      id
      percentage
      variety {
        code
        id
        name
        alternativeCropName
        supplier {
          name
        }
      }
    }
    cropTiming {
      startWeekNumberFrom
      startWeekNumberUntil
      endWeekNumberFrom
      endWeekNumberUntil
      type
    }
    events {
      type
      date
      note {
        text
      }
    }
    parent {
      id
      plantSchema {
        plantSchema {
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
      crop {
        plantSchemas {
          plantSchema {
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
          default
        }
      }
    }
    plantSchema {
      plantSchema {
        distanceInRow
        distanceBetweenRows
        offset
        gridder
      }
    }
    crop {
      name
      plantSchemas {
        id
        default
        plantSchema {
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
    }
    transplant
  }
}
`;
