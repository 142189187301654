import React, { FunctionComponent } from "react";
import { flatMap, range } from "lodash";
import { plantAmount } from "../../utils/planting.util";

type PlantSchema = {
  distanceBetweenRows: number;
  distanceInRow: number;
  offset?: boolean;
};

type PlantSchemaDrawingProps = {
  plantSchema: PlantSchema;
  size?: string;
  bedLength?: number;
  bedWidth?: number;
  hideNrOfPlants?: boolean;
};

const DRAWING_CONFIG = {
  width: 100,
  height: 85,
};

const PlantSchemaDrawing: FunctionComponent<PlantSchemaDrawingProps> = ({
                                                                          size,
                                                                          hideNrOfPlants,
                                                                          plantSchema,
                                                                          bedLength,
                                                                          bedWidth
                                                                        }) => {
  const {distanceBetweenRows, distanceInRow, offset} = plantSchema;

  const height = bedWidth ? bedWidth * 100 : DRAWING_CONFIG.height;
  const width = DRAWING_CONFIG.width;

  const rows = (height / distanceBetweenRows < 2 && height / distanceBetweenRows > 1.5) ? Math.ceil(height / distanceBetweenRows) : Math.floor(height / distanceBetweenRows);
  const paddingHeight = (height - ((rows - 1) * distanceBetweenRows)) / 2;
  const columns = Math.floor((width) / distanceInRow);
  const paddingWidth = (width - ((columns - 1) * distanceInRow)) / 2;

  const points = flatMap(range(rows), row =>
    range(columns).map(column => ({
      x: (offset && row % 2 === 1)
        ? (paddingWidth + (column * distanceInRow) + (distanceInRow / 2))
        : (paddingWidth + (column * distanceInRow)),
      y: (paddingHeight + (row * distanceBetweenRows)),
      color: 'blue',
    })));

  const nrOfPlants = plantAmount(plantSchema, bedLength || 1, bedWidth || 1, undefined);
  const nrOfPlantsValue = (nrOfPlants && nrOfPlants > 0) ? `${nrOfPlants}pl` : '-';

  return <div>
    <div className="relative" style={{width: size, height: size}}>
      <svg version="1.1"
           className={"w-full h-full"}
           viewBox={`0 0 ${width + 20} ${height + 20}`}
           width={width + 20}
           height={height + 20}
           xmlns="http://www.w3.org/2000/svg"
        //         className="absolute"
      >
        <rect width={width} height={height} fill="var(--surface-d)"
              x={20} y={20}
        />
        <line x1={20} x2={width + 20} y1={10} y2={10} stroke={'var(--surface-d)'}/>
        <text x={width / 2 + 20} y={14} textAnchor={'middle'}
              fill={'var(--text-color)'}>{width / 100}m
        </text>
        <line x1={20} x2={20} y1={10} y2={14} stroke={'var(--surface-d)'}/>
        <line x1={width + 19} x2={width + 19} y1={10} y2={14} stroke={'var(--surface-d)'}/>


        <line x1={10} x2={10} y1={20} y2={height + 20} stroke={'var(--surface-d)'}/>
        <line x1={10} x2={14} y1={20} y2={20} stroke={'var(--surface-d)'}/>
        <line x1={10} x2={14} y1={height + 20} y2={height + 19}
              stroke={'var(--surface-d)'}/>
        <text x={10} y={height / 2 + 20} textAnchor={'middle'} fill={'var(--text-color)'}
              transform="rotate(90 7,55)">{height / 100}m
        </text>

        {points.map(({x, y}) => <circle key={`${x}-${y}`} cx={x + 20} cy={y + 20} r={5}
                                        fill="var(--primary-color)"/>)}
      </svg>
    </div>
    <div className="text-center">{`${!hideNrOfPlants ? nrOfPlantsValue : ''} ${plantSchema && `${plantSchema.distanceBetweenRows}cm x ${plantSchema.distanceInRow}cm`}`}</div>
  </div>
};

export default PlantSchemaDrawing;
