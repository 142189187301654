import { CultivationVarietyCreateManyInput, CultivationVarietyUpdateManyInput } from "../__generated__/types";

export const calculateVarietiesCreate = (varieties: { varietyId: string, percentage: number }[]): CultivationVarietyCreateManyInput => {
  return {
    create: varieties.map(variety => ({
      variety: {connect: {id: variety.varietyId}},
      percentage: variety.percentage,
    }))
  };
};

export const calculateVarietiesUpdate = (
  varieties: { varietyId: string, percentage: number }[],
  existingVarietyIds: string[] | null,
): CultivationVarietyUpdateManyInput => {
  let varietyUpdateInput: CultivationVarietyUpdateManyInput = {
    ...calculateVarietiesCreate(varieties),
  };

  if (existingVarietyIds) {
    varietyUpdateInput = {
      ...varietyUpdateInput,
      deleteMany: [{id_in: existingVarietyIds}],
    }
  }

  return varietyUpdateInput;
};
