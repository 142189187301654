import React, { useEffect, useState } from 'react';
import { compact, orderBy } from "lodash";
import PlotRow from "../rows/PlotRow";
import { useQuery } from "@apollo/client";
import { Fields, Fields_fields } from "../../../../__generated__/types";
import { FieldsQuery } from "../../planning21.gql";
import { GardenFilterValues } from "../planning21.interfaces";
import styled from "styled-components";
import config from "../../../../config";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";

interface LocationBasedPlanningProps {
  gardenFilters: GardenFilterValues;
}

const RotationArea = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  //background-color: var(--surface-a);
`;

const FieldHeader = styled.h2`
  background-color: var(--surface-b);
  padding-bottom: 0.5em;
`;

const LocationBasedPlanning = (props: LocationBasedPlanningProps) => {
  const {data: fieldsData} = useQuery<Fields>(FieldsQuery, {
    variables: {
      ids: props.gardenFilters.fields
    }
  });
  const [fields, setFields] = useState<Fields_fields[]>([]);

  useEffect(() => {
    if (fieldsData) {
      setFields(compact(fieldsData.fields));
    }
  }, [fieldsData]);

  return <>
    {orderBy(fields, 'type', 'desc').map(field => {
      const template = (options: any) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;
        const style = { fontSize: '1.25rem' };

        return (
          <div className={className}>
            <button className={options.togglerClassName} onClick={options.onTogglerClick}>
              <span className={toggleIcon}></span>
              <Ripple />
            </button>
            <span className={`${options.titleClassName} ml-2 text-primary text-xl font-medium`}>{field.name}</span>
          </div>
        );
      };

      return <Panel
        collapsed={true}
        pt={{
          content: { className: 'p-0' },
        }}
        className="shadow-1 my-2 border-1 border-300"
        headerTemplate={template} key={field.id} toggleable>
        {/*<FieldHeader>{field.name}</FieldHeader>*/}
        <div>
          {
            compact(field.rotationAreas)
              .filter(rotationArea => {
                if (rotationArea.rotationSchema) {
                  const rotationSchema = rotationArea.rotationSchema.find(rotationSchema => rotationSchema.season === config.currentPlanningSeason);
                  if (rotationSchema) {
                    return props.gardenFilters.rotationGroups.includes(rotationSchema.rotationGroup.id);
                  }
                }
                return false;
              })
              .map(rotationArea => {
                return <RotationArea className="p-0" key={rotationArea.id}>
                  {/*<RotationAreaNumber>*/}
                  {/*  <span>{rotationArea.number}</span>*/}
                  {/*</RotationAreaNumber>*/}
                  <div>
                    {compact(rotationArea.plots).map(plot => {
                      return <PlotRow
                        key={plot.id}
                        plotId={plot.id}
                        onSelectForMove={() => {
                          // if (plotToMove.source) {
                          //   if (plotToMove.source !== plot.id) {
                          //     setPlotToMove({...plotToMove, destination: plot.id});
                          //   }
                          // } else {
                          //   setPlotToMove({source: plot.id});
                          // }
                        }}
                        filters={props.gardenFilters}/>
                    })}
                  </div>
                </RotationArea>;
              })
          }
        </div>
      </Panel>
    })}
  </>;
};

export default LocationBasedPlanning;
