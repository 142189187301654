import React from 'react';
import { Cultivations_cultivations } from "../../../../__generated__/types";
import { compact } from "lodash";
import { plantSchemaForCalculations } from "../../../21/planning21-context";
import { nrOfRows, plantAmount } from "../../../../utils/planting.util";

interface CultivationInfoProps {
  cultivation: Cultivations_cultivations;
  cultivationVarietyId?: string;
  varietyIdx: number;
}

const CultivationInfo = (props: CultivationInfoProps) => {

  const {varieties, id, length, crop} = props.cultivation;
  let varietiesForCultivation = compact(varieties);
  const cultivationVariety = varietiesForCultivation.find(cultivationVariety => cultivationVariety.id === props.cultivationVarietyId);

  let width = props.cultivation.plot.rotationArea.field.bedWidth;

  const plantSchema = plantSchemaForCalculations(props.cultivation);
  let parentPlantSchema = props.cultivation.parent ? plantSchemaForCalculations(props.cultivation.parent) : undefined;
  let nrOfPlants = plantSchema ? plantAmount(plantSchema.plantSchema, length, width, parentPlantSchema?.plantSchema) : undefined;

  const nrOfPlantsValue = (nrOfPlants && nrOfPlants > 0) ? `${nrOfPlants}pl` : '-';
  let rows = plantSchema ? nrOfRows(width, plantSchema.plantSchema.distanceBetweenRows) : 0;

  return <div className="flex-grow-0 overflow-hidden flex justify-content-between overflow-hidden text-overflow-ellipsis" key={`cultivation-info-${cultivationVariety?.id || id}`}>
    <div className="overflow-hidden">
      <div className="mr-1 font-bold text-overflow-ellipsis overflow-hidden white-space-nowrap">
        {crop && (cultivationVariety && cultivationVariety.variety.alternativeCropName
          ? cultivationVariety.variety.alternativeCropName
          : cultivationVariety
            ? cultivationVariety.variety?.name
            : <span className="text-red-500 font-bold">Geen ras geselecteerd</span>)}
      </div>
      {cultivationVariety?.variety.alternativeCropName && <div
        className="italic text-overflow-ellipsis overflow-hidden white-space-nowrap text-xs text-color-secondary">
        {cultivationVariety
          ? cultivationVariety.variety?.name
          : <span className="text-red-500 font-bold">Geen ras geselecteerd</span>}
      </div>}
    </div>

    {props.varietyIdx === 0 && <div key={`Lengths-${id}`}
          className="w-6rem flex align-items-center justify-content-end text-right white-space-nowrap text-overflow-ellipsis">
      <div>
        <span>{length && `${length}m`}</span>
        <span className="text-xs text-color-secondary"> {nrOfPlantsValue}</span>
        <br/>
        <span> {plantSchema && `${rows} rij${rows > 0 ? 'en' : ''}`}</span>
      </div>
      {/*<div>*/}
      {/*  <div>{plantSchema && `${rows} rij${rows > 0 ? 'en' : ''}`}</div>*/}
      {/*  <div*/}
      {/*    className="text-xs text-color-sceondary">{plantSchema && `${plantSchema.plantSchema.distanceBetweenRows}cm x ${plantSchema.plantSchema.distanceInRow}cm`}</div>*/}
      {/*</div>*/}
    </div>}

  </div>;
};

export default CultivationInfo;
