import { TimelineData, TimelineWindow } from "./planning21.interfaces";
import moment from "moment";
import { momentFromIso8601 } from "../../../shared/utils/date.utils";

export const calculateSowingOrPlantingWindow = (
  cropTiming: {
    startWeekNumberFrom: number,
    startWeekNumberUntil: number,
  },
  startDate: string | undefined,
  year?: number | undefined,
): TimelineWindow => {
  const {startWeekNumberFrom, startWeekNumberUntil} = cropTiming;

  const startDateMoment = startDate ? momentFromIso8601(startDate) : undefined;

  const firstDayOfYear = (startDateMoment || moment(year, 'YYYY') || moment()).clone().startOf('y');
  const startOfFirstWeekInYear = firstDayOfYear.clone().startOf('isoWeek');
  // if January 1st is on monday
  const nrOfWeeksToAdd = firstDayOfYear.isoWeek() === 1 ? -1 : 0;
  let sowingOrPlantingFirstDay;
  if (startDateMoment) {
    sowingOrPlantingFirstDay = startDateMoment;
  } else {
    sowingOrPlantingFirstDay = startOfFirstWeekInYear.clone().add(startWeekNumberFrom + nrOfWeeksToAdd, 'w');
  }

  const sowingOrPlantingLastDay = startOfFirstWeekInYear.clone().add(startWeekNumberUntil + nrOfWeeksToAdd, 'w').endOf('isoWeek');

  return {
    firstDay: sowingOrPlantingFirstDay,
    lastDay: sowingOrPlantingLastDay,
  };
};

export const calculateHarvestWindow = (
  cropTiming: {
    startWeekNumberFrom: number,
    endWeekNumberFrom: number,
    endWeekNumberUntil: number,
  },
  startDate: string | undefined,
  endDate: string | undefined,
  year?: number | undefined,
): TimelineWindow => {
  const {endWeekNumberFrom, endWeekNumberUntil, startWeekNumberFrom} = cropTiming;

  const startDateMoment = startDate ? momentFromIso8601(startDate) : undefined;

  const firstDayOfYear = (startDateMoment || moment(year, 'YYYY') || moment()).clone().startOf('y');
  const startOfFirstWeekInYear = firstDayOfYear.clone().startOf('isoWeek');
  // if January 1st is on monday
  const nrOfWeeksToAdd = firstDayOfYear.isoWeek() === 1 ? -1 : 0;

  const harvestStartDate = startOfFirstWeekInYear.clone().add(endWeekNumberFrom + nrOfWeeksToAdd, 'w');
  if (endWeekNumberFrom < startWeekNumberFrom) {
    harvestStartDate.add(1, 'y');
  }

  let harvestEndDate;
  if (endDate) {
    harvestEndDate = momentFromIso8601(endDate);
  } else {
    harvestEndDate = startOfFirstWeekInYear.clone().add(endWeekNumberUntil + nrOfWeeksToAdd, 'w').endOf('isoWeek');

    if ((endWeekNumberUntil < startWeekNumberFrom)
      || (startWeekNumberFrom > endWeekNumberFrom)
      || (endWeekNumberFrom > endWeekNumberUntil)) {
      harvestEndDate.add(1, 'y');
    }
  }

  return {
    firstDay: harvestStartDate,
    lastDay: harvestEndDate,
  };
};

export const cropTimingToTimelineData = (
  cropTiming: {
    startWeekNumberFrom: number,
    startWeekNumberUntil: number,
    endWeekNumberFrom: number,
    endWeekNumberUntil: number,
  },
  startDate: string | undefined,
  endDate: string | undefined,
  year?: number | undefined,
): TimelineData => {
  return {
    sowingOrPlantingWindow: calculateSowingOrPlantingWindow(cropTiming, startDate, year),
    harvestWindow: calculateHarvestWindow(cropTiming, startDate, endDate, year)
  };
};
