import moment, {Moment} from 'moment';
import {compact, range, set, times} from "lodash";

export const FORMAT_ISO_8601 = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const FORMAT_DD_MM_YYYY = 'DD/MM/YYYY';
export const FORMAT_YYYY_W = 'YYYY-W';
export const FORMAT_YYYY_W_E = 'YYYY-W E';
export const FORMAT_DD_MM_YY = 'DD/MM/YY';
export const FORMAT_DD_MM_YYYY_DASHED = 'DD-MM-YYYY';
export const FORMAT_YYYY_MM_DD_DASHED = 'YYYY-MM-DD';
export const FORMAT_MONTH_YYYY = 'MMMM YYYY';
export const FORMAT_MM_YY = 'MM-YY';
export const FORMAT_MONTH_SHORT = 'MMM';
export const FORMAT_YYYY = 'YYYY';
export const FORMAT_MM_YYYY = 'MM-YYYY';
export const FORMAT_DAY_DD_MM_YYYY = 'dd DD/MM/YYYY';
export const FORMAT_DAY_D_MMM_YY = 'dd D MMM YY';
export const FORMAT_DD = 'dd';
export const FORMAT_DAY_FULL_D_MMM = 'dddd D MMM';
export const FORMAT_DD_MMM = 'DD-MMM';
export const FORMAT_DAY_DD_MMM = '[w]W DD-MMM';
export const FORMAT_WEEK_YEAR = '[w]W-YY';
export const FORMAT_DAY_FULL_DD_MM_YYYY = 'dddd DD/MM/YYYY';
export const FORMAT_DD_MM_YYYY_HH_MM = 'DD/MM/YYYY HH:mm';
export const nl = {
  firstDayOfWeek: 1,
  dayNames: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
  dayNamesShort: ["zon", "maa", "din", "woe", "don", "vrij", "zat"],
  dayNamesMin: ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
  monthNames: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
  monthNamesShort: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  today: 'Vandaag',
  clear: 'Clear',
  dateFormat: 'dd/mm/yy',
  weekHeader: ''
};


export const asIso8601 = (moment: Moment) => moment.format(FORMAT_ISO_8601);
export const asYyyyMmDd = (moment: Moment | null) => moment ? moment.format(FORMAT_YYYY_MM_DD_DASHED) : undefined;
export const asDdMmYyyy = (moment: Moment | string | null) => {
  let realMoment = typeof moment === 'string' ? momentFromIso8601(moment) : moment;
  return realMoment ? realMoment.format(FORMAT_DD_MM_YYYY) : undefined;
};
export const asDdMmYy = (moment: Moment | null) => moment ? moment.format(FORMAT_DD_MM_YY) : undefined;
export const asDdMmYyyyNonNull = (moment: Moment) => moment.format(FORMAT_DD_MM_YYYY);
export const asDayDdMmYyyy = (moment: Moment | null) => moment ? moment.format(FORMAT_DAY_DD_MM_YYYY) : undefined;
export const asDayDMmmYy = (moment: Moment | null) => moment ? moment.format(FORMAT_DAY_D_MMM_YY) : undefined;
export const asDD = (moment: Moment | null) => moment ? moment.format(FORMAT_DD) : undefined;

export const asDdMmm = (moment: Moment | null) => moment ? moment.format(FORMAT_DD_MMM) : undefined;
export const asYyyyW = (moment: Moment | null) => moment ? moment.format(FORMAT_YYYY_W) : undefined;
export const asYyyyWE = (moment: Moment | null) => moment ? moment.format(FORMAT_YYYY_W_E) : undefined;
export const iso8601_DdMmm = (iso8601: string) => momentFromIso8601(iso8601).format(FORMAT_DD_MMM);

export const asWeekDdMmm = (moment: Moment | null) => moment ? moment.format(FORMAT_DAY_DD_MMM) : undefined;

export const asWeekYear = (moment: Moment | null) => moment ? moment.format(FORMAT_WEEK_YEAR) : undefined;

export const iso8601_DdMmYyyy = (iso8601: string) => momentFromIso8601(iso8601).format(FORMAT_DD_MM_YYYY);
export const iso8601_DayFullDdMmm = (iso8601: string) => momentFromIso8601(iso8601).format(FORMAT_DAY_FULL_D_MMM);
export const iso8601_YyyyW = (iso8601: string) => momentFromIso8601(iso8601).format(FORMAT_YYYY_W);
export const iso8601_YyyyWE = (iso8601: string) => momentFromIso8601(iso8601).format(FORMAT_YYYY_W_E);

export const YyyyW_DayFullDdMmm = (yyyyW: string) => momentFromYyyyW(yyyyW).format(FORMAT_DAY_FULL_D_MMM);
export const YyyyW_DayShortDd = (yyyyW: string) => momentFromYyyyW(yyyyW).format('dd DD');
export const YyyyW_MmYyyy = (yyyyW: string) => momentFromYyyyW(yyyyW).format(FORMAT_MM_YYYY);

export const asDayFullDdMmYyyy = (moment: Moment | null) => moment ? moment.format(FORMAT_DAY_FULL_DD_MM_YYYY) : undefined;
export const asYyyy = (moment: Moment | null) => moment ? moment.format(FORMAT_YYYY) : undefined;

export const asMonthShort = (moment: Moment | null) => moment ? moment.format(FORMAT_MONTH_SHORT) : undefined;
export const asMonthYyyy = (moment: Moment | null) => moment ? moment.format(FORMAT_MONTH_YYYY) : undefined;
export const asMmYy = (moment: Moment | null) => moment ? moment.format(FORMAT_MM_YY) : undefined;
export const asDdMmYyyyHHmm = (moment: Moment | null) => moment ? moment.format(FORMAT_DD_MM_YYYY_HH_MM) : undefined;
export const momentFromIso8601 = (string: string) => moment(string, FORMAT_ISO_8601);

//DEPRECATED use calculateAgeAtStartOfSubscription
export const calculateAgeAtStartOfSeason = (dateOfBirth: string, startOfSeason: Moment) => startOfSeason.diff(moment(dateOfBirth, [FORMAT_ISO_8601, FORMAT_DD_MM_YYYY]), 'y');
export const momentFromDdMmYyyy = (string: any) => moment(string, [FORMAT_DD_MM_YYYY, FORMAT_DD_MM_YY, FORMAT_DD_MM_YYYY_DASHED]);
export const momentFromMmYyyy = (string: any) => moment(string, [FORMAT_MM_YYYY]);
export const momentFromYyyyW = (string: any) => moment(string, [FORMAT_YYYY_W]);
export const momentFromYyyyWE = (string: any) => moment(string, [FORMAT_YYYY_W_E]);

export const calculateAgeAtStartOfSubscription = (dateOfBirth: string, startOfSubscription: Moment) => {
  return startOfSubscription.diff(moment(dateOfBirth, [FORMAT_ISO_8601, FORMAT_DD_MM_YYYY]), 'y');
};

export const upcomingWeekdaysForNextNumberOfDays = (moment: Moment, isoWeekDays: number[], daysBeforeStart: number, daysFromStart: number): Moment[] => {
  let numbers = range(1, daysFromStart);
  let daysAfterStart = numbers.reduce((days: Moment[], t: number) => {
    const day = moment.clone().add(t, 'days');
    if (isoWeekDays.includes(day.isoWeekday())) {
      return [...days, day];
    }

    return days;
  }, []);

  let beforeStart = times(daysBeforeStart).reduce((days: Moment[], t: number) => {
    const day = moment.clone().subtract(t, 'days');
    if (isoWeekDays.includes(day.isoWeekday())) {
      return [...days, day];
    }

    return days;
  }, []);

  return [...beforeStart, ...daysAfterStart];
};


export const allDaysInMonth = (month: Moment, isoWeekDay: number) => {
  let startOfMonth = month.clone().startOf('month');

  return compact(times(startOfMonth.daysInMonth()).map(t => {
    let nextDay = startOfMonth.clone().add(t, 'day');
    return nextDay.isoWeekday() === isoWeekDay ? nextDay : undefined;
  }));
};

export const weekdaysBetween = (startDate: Moment, endDate: Moment, isoWeekday: number) => {
  const dailyInfo = set([false, false, false, false, false, false, false], `[${isoWeekday}]`, true);
  let totalDays: { days: Moment[], length: number } = {
    days: [],
    length: 0,
  };

  dailyInfo.forEach((info, index) => {
    if (info === true) {
      let current = startDate.clone();
      if (current.isoWeekday() <= index) {
        current = current.isoWeekday(index);
      } else {
        current.add(1, 'weeks').isoWeekday(index);
      }
      while (current.isSameOrBefore(endDate)) {
        current.day(7 + index);
        totalDays = {
          ...totalDays,
          length: totalDays.length + 1,
          days: [...totalDays.days, current.clone()],
        };
      }
    }
  });
  return totalDays;
};

export const startOfIsoWeek = (moment?: Moment | null | undefined ) => moment ? asIso8601(moment.clone().startOf('isoWeek')) : null;
export const endOfIsoWeek = (moment?: Moment | null | undefined ) => moment ? asIso8601(moment.clone().endOf('isoWeek')) : null;
