import React, { useState } from 'react';
import { Dialog } from "primereact/dialog";
import { CreateField, CreateFieldVariables, FieldType, GetFarm, GetLastFieldOrderNumber, RotationAreaCreateManyWithoutFieldInput, Target } from "../../../../__generated__/types";
import { CultivationField } from "../../Planning21/components/AddCultivation/AddCultivation";
import { Dropdown } from "primereact/dropdown";
import { useLazyQuery, useQuery } from "@apollo/client";
import { CreateFieldMutation, GetFarmQuery, GetLastFieldOrderNumberQuery } from "../FieldManagement.gql";
import config from "../../../../config";
import { compact, range } from "lodash";
import { SelectButton } from "primereact/selectbutton";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useMutation } from "@apollo/client/react/hooks";
import { useUserObject } from "../../../../context/UserContext";

interface UpsertFieldDialogProps {
  onHide: () => void;
  field: {
    id?: string,
    locationId?: string,
    type?: FieldType,
    name?: string,
    bedLength?: number,
    nrOfRotationAreas?: number,
    orderNumber?: number,
  };
}

const UpsertFieldDialog = (props: UpsertFieldDialogProps) => {
  const {activeFarm} = useUserObject();
  const {data: farmData} = useQuery<GetFarm>(GetFarmQuery, {variables: {id: activeFarm?.id}});
  const [createField] = useMutation<CreateField>(CreateFieldMutation, {
    update(cache, {data}) {
      cache.modify({
        fields: {
          fields: (previous) => {
            return data ? [...previous, data.createField] : previous;
          }
        }
      })
    }
  });

  const [upsertingField, setUpsertingField] = useState<{
    name?: string,
    //fallback to name, prefix is not used anymore
    prefix?: string,
    location?: string,
    type?: FieldType,
    bedLength?: number,
    maxRotationAreas?: number,
    //nr of plots in rotation area, defaults to 1, multiple plots aren't used anymore
    rotationAreaSize: 1,
    target: Target.MIXED,
    rotationAreas: RotationAreaCreateManyWithoutFieldInput,
    active: boolean,
    orderNumber?: number,
  }>({
    active: true,
    bedLength: props.field.bedLength,
    type: props.field.type,
    location: props.field.locationId,
    maxRotationAreas: props.field.nrOfRotationAreas,
    //nr of plots in rotation area, defaults to 1, multiple plots aren't used anymore
    rotationAreaSize: 1,
    //fallback to name, prefix is not used anymore
    prefix: props.field.name,
    target: Target.MIXED,
    orderNumber: props.field.orderNumber,
    rotationAreas: {
      create: []
    },
    name: props.field.name,
  });

  return <Dialog
    header={`Veld ${props.field.id ? 'wijzigen' : 'toevoegen'}`}
    visible onHide={() => props.onHide()}
    footer={() => <div>
      <Button className={'p-button-text'} label={'Annuleren'} onClick={props.onHide}/>
      <Button
        label={props.field.id ? 'Wijzigen' : 'Toevoegen'}
        onClick={async () => {
          if (upsertingField.name && upsertingField.prefix && upsertingField.location && upsertingField.type && upsertingField.bedLength && upsertingField.maxRotationAreas) {
            const variables: CreateFieldVariables = {
              createData: {
                active: true,
                target: Target.MIXED,
                rotationAreaSize: 1,
                bedLength: upsertingField.bedLength,
                type: upsertingField.type,
                location: {connect: {id: upsertingField.location}},
                maxRotationAreas: upsertingField.maxRotationAreas,
                prefix: upsertingField.name,
                rotationAreas: {
                  create: range(1, upsertingField.maxRotationAreas + 1).map((idx) => ({
                    active: true,
                    number: idx,
                    plots: {
                      create: [{
                        number: 1,
                        active: true
                      }]
                    }
                  }))
                },
                name: upsertingField.name,
                orderNumber: upsertingField.orderNumber,
              },
            };
            createField({variables});
            props.onHide();
          }
        }}
      />
    </div>}
  >

    <CultivationField label={'Naam'}>
      <InputText value={upsertingField.name || ''}
                 onChange={(e) => setUpsertingField({
                   ...upsertingField,
                   name: e.target.value,
                   prefix: e.target.value
                 })}/>
    </CultivationField>
    <CultivationField label={'Locatie'}>
      <Dropdown
        options={compact(farmData?.farm?.locations)}
        optionValue={'id'}
        optionLabel={'name'}
        value={upsertingField.location}
        onChange={(e) => setUpsertingField((value) => ({...value, location: e.value}))}
      />
    </CultivationField>

    <CultivationField label={'Type'}>
      <SelectButton
        options={[
          {value: FieldType.OPEN, label: 'Openlucht'},
          {value: FieldType.GREENHOUSE, label: 'Serre'}
        ]}
        value={upsertingField.type}
        onChange={(e) => setUpsertingField((value) => ({...value, type: e.value}))}
      />
    </CultivationField>
    <CultivationField label={'Bedlengte'}>
      <InputNumber value={upsertingField.bedLength || 0}
                   onChange={(e) => setUpsertingField((value) => ({...value, bedLength: e.value || 0}))}/>
    </CultivationField>
    <CultivationField label={'Aantal blokken (teeltwisseling)'}>
      <InputNumber value={upsertingField.maxRotationAreas || 0}
                   onChange={(e) => setUpsertingField((value) => ({...value, maxRotationAreas: e.value || 0}))}/>
    </CultivationField>
  </Dialog>;
};

export default UpsertFieldDialog;
