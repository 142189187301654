import React, { Fragment } from 'react';
import { asDdMmYyyy, momentFromIso8601 } from "../../../../shared/utils/date.utils";
import moment, { Moment } from "moment";
import { chain, compact, findIndex, first, last, map, max, min, set, sum } from "lodash";
import { calculateRelativePositionBoundaries, ScrollingTimelineColumn, ScrollingTimelineRow, TIMELINE_VIEW_CONFIG } from "./ScrollingTimeline";
import { TimelineColumnConfig } from "../../TimelinePage";
import { calculateHarvestWindow, calculateSowingOrPlantingWindow } from "../../../21/Planning21/planning21-mutations";
import { plantAmount } from "../../../../utils/planting.util";
import { plantSchemaForCalculations } from "../../../21/planning21-context";
import { Gridder } from "../../../../__generated__/types";
import { CultivationForCropTimeline } from "../../../21/Planning21/components/planning21-crop-timeline";
import TimelineCellHolder from "./TimelineCellHolder";
import TimelineOccupation from "./TimelineOccupation";
import CultivationRowTimeline from "./CultivationRowTimeline";


export const cropHarvestWindow = (cultivations: CultivationForCropTimeline[], years: Moment[]): {
  start: number, end: number, length: number,
}[] => {
  // if (years.length !== 2) {
  //   return undefined;
  // }
  const firstDayInTimeline = years[0].clone();
  const lastDayInTimeline = years[1].clone();

  // [day]: [start, end value of that single day, e.g.: 20,0]
  const message = chain(cultivations)
    .orderBy(['startDateUnix'], ['asc'])
    .reduce((occupation: { day: number, length: number }[], cultivation: CultivationForCropTimeline) => {
      let updatedOccupation = occupation;

      const harvestWindow = calculateHarvestWindow(cultivation.cropTiming, cultivation.startDate, cultivation.harvestEndDate);

      if (cultivation && cultivation.length
      ) {
        const firstDay = harvestWindow.firstDay.clone();
        let lastDay = harvestWindow.lastDay.clone().endOf('isoWeek');

        while (lastDay.diff(firstDay, 'days') >= 0) {
          if (firstDay.isBetween(firstDay, lastDay, 'd', '[]') &&
            firstDay.isBetween(firstDay, lastDayInTimeline, 'd', '[]')
          ) {
            const day = firstDay.diff(firstDayInTimeline, 'd');

            const occupationIdxForDay = findIndex(updatedOccupation, ['day', day]);
            if (occupationIdxForDay > -1) {
              const updatedLength = updatedOccupation[occupationIdxForDay].length + cultivation.length;
              updatedOccupation = set(updatedOccupation, `[${occupationIdxForDay}].length`, updatedLength);
            } else {
              updatedOccupation = [
                ...updatedOccupation,
                {
                  day,
                  length: cultivation.length,
                }
              ];
            }
          }
          firstDay.add(1, 'days')
        }
      }
      return updatedOccupation;
    }, [])
    .value();

  const convertToWindows = (data: { day: number, length: number }[]) => {
    let result: { start: number, end: number, length: number }[] = [];
    data.forEach(v => {
      let rl = result.length;
      if (rl === 0 || result[rl - 1].end + 1 !== v.day || result[rl - 1].length !== v.length) {
        result.push({start: v.day, end: v.day, length: v.length});
      } else result[rl - 1].end += 1;
    });
    return result;
  };

  return convertToWindows(message);
};


interface TimelineRowProps<T> {
  alwaysShowMetadata: boolean;
  columnConfig: TimelineColumnConfig;
  idx: number;
  columns: ScrollingTimelineColumn[];
  value: ScrollingTimelineRow<T>;
  dateRange: Moment[];
  rowHeights: number[];
}

const TimelineRow = <T extends {
  id: string,
  cultivations: {
    id: string,
    startDate: string,
    varieties: any[] | null,
    events: any[] | null,
    cropTiming: {
      startWeekNumberFrom: number,
      startWeekNumberUntil: number,
      endWeekNumberFrom: number,
      endWeekNumberUntil: number,
    },
    plantSchema: {
      plantSchema: { distanceInRow: number, distanceBetweenRows: number, gridder?: Gridder | null }
    } | null,
    crop: {
      plantSchemas: {
        plantSchema: { distanceInRow: number, distanceBetweenRows: number, gridder?: Gridder | null },
        default: boolean
      }[] | null
    },
    plot: { rotationArea: { field: { bedWidth: number } } },
    length: number,
    harvestEndDate: string
  }[] | null
}, >(props: TimelineRowProps<T>) => {

  const timelineStart = first(props.columns)?.startDate;
  const timelineEnd = last(props.columns)?.endDate;
  if (!timelineStart || !timelineEnd) {
    return null;
  }

  let nrOfDaysInTimeline = timelineEnd.diff(timelineStart, "d") + 1;

  const {rowHeight, detailRowHeight} = TIMELINE_VIEW_CONFIG;
  const {idx} = props;

  const nrOfColumns = props.columns.length;

  let maxNrOfPlants = 0;
  let cultivations = compact(props.value.data.cultivations).map(cultivation => {
    const width = cultivation.plot.rotationArea.field.bedWidth;
    const {length} = cultivation;
    const plantSchema = plantSchemaForCalculations(cultivation);
    //let parentPlantSchema = rowItem.parent ? plantSchemaForCalculations(rowItem.parent) : undefined;
    let parentPlantSchema = undefined;
    // let nrOfPlants = plantSchema ? plantAmount(plantSchema.plantSchema, length, width, parentPlantSchema?.plantSchema) : undefined;
    let nrOfPlants = plantSchema ? plantAmount(plantSchema.plantSchema, length, width, undefined) : undefined;

    if (nrOfPlants && maxNrOfPlants < nrOfPlants) {
      maxNrOfPlants = nrOfPlants;
    }

    return {
      ...cultivation,
      harvestWindow: calculateHarvestWindow(cultivation.cropTiming, cultivation.startDate, cultivation.harvestEndDate),
      sowingOrPlantingWindow: calculateSowingOrPlantingWindow(cultivation.cropTiming, cultivation.startDate),
      nrOfPlants,
    };
  })


  let plotOccupationForCrop: { start: number, end: number, length: number }[] = [];
  if (timelineStart && timelineEnd) {
    plotOccupationForCrop = cropHarvestWindow(cultivations, [timelineStart, timelineEnd]);
  }

  const maxOccupation = max(map(plotOccupationForCrop, 'length')) || 0;

  const cultivationTopPositions = cultivations.reduce((cultivationTops, cultivation, idx) => {

    if (idx === 0) {
      return [rowHeight];
    }
    const prevCultivation = cultivations[idx - 1];
    const varieties = compact(prevCultivation.varieties);

    return [...cultivationTops, cultivationTops[idx - 1] + (detailRowHeight * (varieties.length || 1))]

    return cultivationTops;
  }, [] as number[]);

  return <div
    className={`flex bg-black-alpha-${idx % 2 ? 1 : 2}0 overflow-hidden absolute w-full`}
    style={{top: `${sum(props.rowHeights.slice(0, idx))}px`, height: `${props.rowHeights[idx]}px`}}
  >
    {props.columns.map((column, idx) =>
      <TimelineCellHolder
        key={`timeline-cell-${column}-${idx}`}
        column={column}
        columnIdx={idx}
        totalColumns={nrOfColumns}
        columnWidth={props.columnConfig.columnWidth}/>
    )}

    {plotOccupationForCrop.map((occupation, occupationIdx) => {
      return <TimelineOccupation
        key={`crop-occupation-${occupationIdx}`}
        maxOccupation={maxOccupation}
        nrOfDaysInTimeline={nrOfDaysInTimeline}
        maxBarHeight={TIMELINE_VIEW_CONFIG.cropTotalsBarHeight}
        occupation={occupation}
      />;
    })}

    {cultivations.map((cultivation, cultivationIdx) => {
        return <CultivationRowTimeline
          alwaysShowMetadata={props.alwaysShowMetadata}
          key={`cultivation-detail-line-${cultivationIdx}`}
          style={{top: `${cultivationTopPositions[cultivationIdx]}px`}}
          timelineRange={[timelineStart, timelineEnd]}
          cultivation={cultivation}
        ></CultivationRowTimeline>;
      }
    )}
  </div>;
};

export default TimelineRow;
