import React from 'react';
import { asIso8601 } from "../../../../shared/utils/date.utils";
import { DocumentNode, TypedDocumentNode, useQuery } from "@apollo/client";
import ScrollingTimeline, { ScrollingTimelineRow, TimelineGranularity, TimelineRowType } from "./ScrollingTimeline";
import { Moment } from "moment";
import { TimelineColumnConfig } from "../../TimelinePage";
import { useUserObject } from "../../../../context/UserContext";
import { Gridder } from "../../../../__generated__/types";

export interface RowTypeDataConfig<Q, T> {
  query: DocumentNode | TypedDocumentNode;
  rowMapper: (value: T) => ScrollingTimelineRow<T>;
  queryDataSelector: (data: Q | undefined) => T[];
}

interface DataBasedTimelineProps<Q, T> extends RowTypeDataConfig<Q, T> {
  alwaysShowMetadata: boolean;
  columnConfig: TimelineColumnConfig;
  rowType: TimelineRowType;
  dateRange: Moment[];
  cropSelection: string[];
  logicalCropGroup: string | undefined;
}

const DataBasedTimeline = <Q, T extends {
  id: string,
  cultivations: {
    id: string,
    startDate: any,
    varieties: any[] | null;
    events: any[] | null;
    cropTiming: {
      startWeekNumberFrom: number,
      startWeekNumberUntil: number,
      endWeekNumberFrom: number,
      endWeekNumberUntil: number,
    },
    plantSchema: { plantSchema: { distanceInRow: number, distanceBetweenRows: number, gridder?: Gridder | null  } } | null,
    crop: { plantSchemas: { plantSchema: { distanceInRow: number, distanceBetweenRows: number, gridder?: Gridder | null }, default: boolean }[] | null },
    plot: { rotationArea: {field: {bedWidth: number}}},
    length: number,
    harvestEndDate: any
  }[] | null }, >(props: DataBasedTimelineProps<Q, T>) => {
  const {activeFarm} = useUserObject();

  const {data} = useQuery<Q>(props.query, {
    variables: {
      farm: `${activeFarm?.id}`,
      from: `${asIso8601(props.dateRange[0])}`,
      to: `${asIso8601(props.dateRange[1])}`,
    },
  });

  return <ScrollingTimeline<T>
    {...props}
    alwaysShowMetadata={props.alwaysShowMetadata}
    values={props.queryDataSelector(data)}
  />;
};

export default DataBasedTimeline;
