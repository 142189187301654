import React from 'react';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import styled from "styled-components";
import { CropDetails_crop_varieties } from "../../../../../../__generated__/types";
import { sortBy } from "lodash";
import Icon from "../../../../planning21-icon";
import { soilBlockSizes } from "../../../../../../config";

interface VarietiesListProps {
  varieties: CropDetails_crop_varieties[];
  onClick: (varietyId: string) => void;
}

const VarietyNaming = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  span:first-child {
    color: var(--text-color-secondary);
    font-size: 0.75em;
  }
`;

const GroupHeader = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: #748F56;
`;

const VarietiesList = (props: VarietiesListProps) => {
  return <DataTable
    size={"small"}
    value={sortBy(props.varieties, ['supplier.id','alternativeCropName'], ['asc', 'asc'])}
    groupRowsBy={'supplier'}
    rowGroupMode={'subheader'}
    rowGroupHeaderTemplate={(variety) => {
      return <GroupHeader>{variety.supplier?.name}</GroupHeader>;
    }}

    className={'p-datatable-sm'}
    editMode="row"
    dataKey="id"
    selectionMode={'single'}
    onRowSelect={({data}) => {
      props.onClick(data.id);
    }}
  >
    <Column field={'alternativeCropName'} header={'Variant'}/>
    <Column field={'code'} header={'Code'}/>
    <Column field={'name'} header={'Naam'}/>
    <Column field={'type'} header={'Plant/zaad'} body={(variety: CropDetails_crop_varieties) => {
      return variety.type && <Icon size={30} name={variety.type.toLowerCase()}/>;
    }}/>
    <Column field={'soilBlockType'} header={'Persblok'} body={(variety: CropDetails_crop_varieties) => {
      let label = soilBlockSizes.find(s => s.value === variety.soilBlockType)?.label;
      return <div>{label}</div>;
    }}/>
    <Column field={'gramsPerRowMeter'} header={'Zaadgewicht/rij/meter'} body={(variety: CropDetails_crop_varieties) => {
      return variety.gramsPerRowMeter ? <div>{variety.gramsPerRowMeter}gram</div> : null;
    }}/>
  </DataTable>;
};

export default VarietiesList;
