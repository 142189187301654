import React, { useEffect, useState } from 'react';
import { GardenFilterValues } from "../planning21.interfaces";
import { useQuery } from "@apollo/client";
import { Crops, Crops_crops } from "../../../../__generated__/types";
import { CropsQuery } from 'pages/21/planning21.gql';
import { chain, isEmpty } from "lodash";
import CropRow from "../rows/CropRow";

interface CropBasedPlanningProps {
  gardenFilters: GardenFilterValues;
}

const CropBasedPlanning = (props: CropBasedPlanningProps) => {
  const {data} = useQuery<Crops>(CropsQuery);
  const [crops, setCrops] = useState<Crops_crops[]>([]);

  useEffect(() => {
    if (data) {
      let value = chain(data.crops)
        .compact()
        .filter(crop => isEmpty(props.gardenFilters.crops) || (props.gardenFilters.crops.includes(crop.id)))
        .orderBy(['crop.name',
          // (c) => {
          //   return c.varieties && c.varieties.length > 0 ? c.varieties[0].variety.name : c.crop.name
          // },
          'startDateUnix'], ['asc', 'asc'])
        .value();
      setCrops(value);
    }
  }, [data, props.gardenFilters]);
  return <>
    {crops.map(crop => <CropRow key={crop.id} cropId={crop.id} filters={props.gardenFilters}/>)}
  </>;
};

export default CropBasedPlanning;
