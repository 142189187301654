import gql from "graphql-tag";



export const UserQuery = gql`
query User($where: UserWhereUniqueInput!) {
  user(where: $where) {
    id
    auth0userId
    name
    firstName

    email
    secondaryEmail

    street
    streetNumber
    city
    cityPostalCode
    phone
    dateOfBirth
    permissions
    farms {
      id
      name
      logo
    }
  }
}
`;


export const UserDetailsQuery = gql`
query UserDetails($id: ID) {
  user(where: {id: $id}) {
    id
    auth0userId
    name
    firstName

    email
    secondaryEmail

    street
    streetNumber
    city
    cityPostalCode
    phone
    dateOfBirth
  }
}
`;
