import React, { useState } from 'react';
import PlantSchemaDrawing from "../../../../../components/PlantSchemaDrawing/PlantSchemaDrawing";
import { chain, isEmpty } from "lodash";
import { notEmpty } from "../../../../../shared/utils/stream.utils";
import { AddCropPlantSchema, CropDetails_crop, DeleteCropPlantSchema, PlanningData_plantSchemas, UpdateDefaultCropPlantSchema } from "../../../../../__generated__/types";
import styled from "styled-components";
import { Dropdown } from "primereact/dropdown";
import { usePlanning } from "../../../planning21-context";
import { useMutation } from "@apollo/client/react/hooks";
import { AddCropPlantSchemaMutation, DeleteCropPlantSchemaMutation, UpdateDefaultCropPlantSchemaMutation } from "../../../planning21.gql";
import { Button } from "primereact/button";

interface PlantSchemasProps {
  crop: CropDetails_crop;
}

const PlantSchemaContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: var(--surface-c);
  grid-gap: 16px;
  padding: 16px;
  margin-bottom: 32px;

  div.default {
    border: 1px solid var(--primary-color);
  }
`;

const PlantSchemaAddContainer = styled.div`
  background-color: var(--surface-c);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  font-size: 24px;
  height: 120px;
`;

const PlantSchemaAddSelect = styled(PlantSchemaAddContainer)`
  background-color: var(--surface-c);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  font-size: 24px;
  height: 120px;
`;

const PlantSchemaListItem = styled.div`
  padding: 4px;
  cursor: pointer;
  position: relative;

  .deleteButton {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }

  &:hover {
  .deleteButton {
    display: block;
  }
  }
`;

const PlantSchemas = (props: PlantSchemasProps) => {
  const [adding, setAdding] = useState<boolean>(false);

  const {plantSchemas} = usePlanning();
  const [createCropPlantSchema] = useMutation<AddCropPlantSchema>(AddCropPlantSchemaMutation);
  const [updateDefaultCropPlantSchema] = useMutation<UpdateDefaultCropPlantSchema>(UpdateDefaultCropPlantSchemaMutation);
  const [deleteCropPlantSchema] = useMutation<DeleteCropPlantSchema>(DeleteCropPlantSchemaMutation);

  const plantSchemaOptionTemplate = (plantSchema: PlanningData_plantSchemas) =>
    <PlantSchemaDrawing plantSchema={plantSchema} bedLength={1}/>;

  return <div>
    <PlantSchemaContainer>
      {chain(props.crop.plantSchemas)
        .filter(notEmpty)
        .map(cropPlantSchema => {
          return <PlantSchemaListItem
            key={cropPlantSchema.id}
            className={cropPlantSchema.default ? 'default' : ''}
            onClick={() => {
              updateDefaultCropPlantSchema(
                {
                  variables: {
                    cropId: props.crop.id,
                    cropPlantSchemaId: cropPlantSchema.id
                  }
                }
              ).then(() => {
              });
            }}>
            <Button
              className={'deleteButton p-button-rounded p-button-danger p-button-text'}
              icon={'pi pi-trash'}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                return deleteCropPlantSchema({variables: {plantSchemaId: cropPlantSchema.id}})
                  .then(() => {
                  });
              }
              }
            />
            <PlantSchemaDrawing plantSchema={cropPlantSchema.plantSchema} bedLength={1}/>
          </PlantSchemaListItem>;
        })
        .value()
      }
      {!adding && <PlantSchemaAddContainer onClick={() => setAdding(true)}>
        +
      </PlantSchemaAddContainer>}
      {adding && <PlantSchemaAddSelect>
        <Dropdown
          options={plantSchemas}
          itemTemplate={plantSchemaOptionTemplate}
          optionValue={'id'}
          onChange={(e) => {
            createCropPlantSchema(
              {
                variables: {
                  cropId: props.crop.id,
                  plantSchemaId: e.value,
                  default: isEmpty(props.crop.plantSchemas),
                }
              }
            )
              .then(() => {
              });
          }}
        />
      </PlantSchemaAddSelect>}
    </PlantSchemaContainer>
  </div>
};
export default PlantSchemas
