import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { useQuery } from "@apollo/client";
import { ActiveFields, ActiveFields_fields_rotationAreas_plots, Season } from "../../../../../../../__generated__/types";
import { ActiveFieldsQuery } from "../../AddCultivation.gql";
import { chain, compact, last } from "lodash";
import { useUserObject } from "../../../../../../../context/UserContext";

interface LocationDropDownProps {
  value: string | undefined;
  season: Season | undefined;
  onChange: (plotId: string) => void;
}

const LocationDropDown = (props: LocationDropDownProps) => {
  const {activeFarm} = useUserObject();
  const {data: fieldsData} = useQuery<ActiveFields>(ActiveFieldsQuery, {variables: {farm: activeFarm?.id}});
  const [plotOptions, setPlotOptions] = useState<ActiveFields_fields_rotationAreas_plots[]>([]);

  useEffect(() => {
    setPlotOptions(!fieldsData ? [] : chain(fieldsData.fields)
      .compact()
      .sortBy(['type'], ['DESC'])
      .map(field => {
          const rotationAreas = compact(field.rotationAreas);
          return rotationAreas
            .map(r => {
                let rotationSchema = last(r.rotationSchema);
                if (props.season) {
                  rotationSchema = r.rotationSchema?.find(rs => rs.season === props.season);
                }

                return compact(r.plots).map(plot => {
                  return ({
                    ...plot,
                    name: `${field.name}-${r.number}`,
                    nameWithGroup: `${field.name}-${r.number} (${rotationSchema?.rotationGroup.name})`,
                    rotationGroupName: `${rotationSchema?.rotationGroup.name}`,
                  });
                });
              }
            );
        }
      )
      .flattenDeep()
      .value());
  }, [fieldsData, props.season]);


  const selectedPlotTemplate = (option: any, props: DropdownProps) => {
    if (option) {
      return (
        <div className="">
          <div>{option.name}</div>
          <div className="text-xs text-color-secondary">{option.rotationGroupName}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  return <Dropdown
    className="w-full"
    optionLabel={'nameWithGroup'}
    optionValue={'id'}
    value={props.value}
    valueTemplate={selectedPlotTemplate}
    filter
    filterBy={'name'}
    // className="max-w-10rem"
    options={plotOptions}
    onChange={(e) => {
      const plotId = e.value;
      props.onChange(plotId);
    }}
  />;
};

export default LocationDropDown;
