import React from 'react';
import { CultivationsForCrop_cultivations, CultivationsForPlot_cultivations } from "../../../../../__generated__/types";
import { Checkbox } from "primereact/checkbox";
import styled from "styled-components";

interface SelectionColumnProps {
  cultivation: CultivationsForCrop_cultivations | CultivationsForPlot_cultivations;
  onChange?: (selected: boolean) => void;
  selected?: boolean | undefined;
}

const Container = styled.div`
  display: grid;
  align-items: center;
  padding: 2px;
`;

const SelectionColumn = (props: SelectionColumnProps) => {
  return <Container className="pl-2">
    <Checkbox
      disabled={props.cultivation.pinned}
      onChange={e => {
        e.preventDefault();
        e.stopPropagation();
        props.onChange && props.onChange(!!e.checked);
      }}
      checked={props.selected || false}
    />
  </Container>;
};

export default SelectionColumn;
