import React, { useEffect, useState } from 'react';
import { Moment } from "moment";
import { sum } from "lodash";
import { calculateRelativePositionBoundaries, ScrollingTimelineColumn, ScrollingTimelineRow, TIMELINE_VIEW_CONFIG, TimelineRowType } from "./ScrollingTimeline";
import TimelineRow from "./TimelineRow";
import { TimelineColumnConfig } from "../../TimelinePage";
import { Gridder } from "../../../../__generated__/types";

interface TimelineContentProps<T> {
  alwaysShowMetadata: boolean;
  columnConfig: TimelineColumnConfig;
  rowType: TimelineRowType;
  rows: ScrollingTimelineRow<T>[];
  columns: ScrollingTimelineColumn[];
  dateRange: Moment[];

  rowHeights: number[];
}

const TimelineContent = <T extends {
  id: string,
  cultivations: {
    id: string,
    startDate: string,
    varieties: any[] | null,
    events: any[] | null,
    cropTiming: {
      startWeekNumberFrom: number,
      startWeekNumberUntil: number,
      endWeekNumberFrom: number,
      endWeekNumberUntil: number,
    },
    plantSchema: {
      plantSchema: { distanceInRow: number, distanceBetweenRows: number, gridder?: Gridder | null }
    } | null,
    crop: {
      plantSchemas: {
        plantSchema: { distanceInRow: number, distanceBetweenRows: number, gridder?: Gridder | null },
        default: boolean
      }[] | null
    },
    plot: { rotationArea: { field: { bedWidth: number } } },
    length: number,
    harvestEndDate: string,
  }[] | null
}, >(props: TimelineContentProps<T>) => {
  const {headerHeight} = TIMELINE_VIEW_CONFIG;
  const nrOfColumns = props.columns.length;
  const [columnsMeta, setColumnsMeta] = useState<{ color: number, borderColor: number }[]>([]);

  useEffect(() => {
    let currentColor = 7;
    setColumnsMeta(props.columns.reduce((acc: { color: number, borderColor: number }[], column, idx) => {
      if (idx > 0 && !props.columnConfig.isSameAs(props.columns[idx - 1].date.clone(), column.date.clone())) {
        currentColor = currentColor === 7 ? 8 : 7;
      }
      return [...acc, {
        color: currentColor,
        borderColor: props.columnConfig.isSameAs(props.columns[idx - 1]?.date.clone(), column.date.clone()) ? 8 : 5
      }];
    }, []));
  }, [props.columns]);

  return <>
    <div className={`content relative overflow-hidden bg-gray-800`}
         style={{height: `${sum(props.rowHeights)}px`}}>
      {props.rows.map((rowData, idx) =>
        <TimelineRow<T>
          alwaysShowMetadata={props.alwaysShowMetadata}
          columnConfig={props.columnConfig}
          key={`content-${rowData.data.id}-${idx}`}
          idx={idx}
          value={rowData}
          columns={props.columns}
          dateRange={props.dateRange}
          rowHeights={props.rowHeights}
        />)
      }
    </div>

    <div className="timeline-lines absolute left-0 w-full"
         style={{top: `${headerHeight}px`, height: `calc(100% - ${headerHeight}px)`}}>
      <div className="flex top-0 absolute w-full h-full">
        {props.columns.map((column, idx) =>
          <div
            key={`content-lines-${column.date.unix()}-${idx}`}
            style={calculateRelativePositionBoundaries(idx, idx + 1, nrOfColumns, props.columnConfig.columnWidth)}
            className={`absolute top-0 bottom-0 flex-auto-0 border-left-1 border-gray-${columnsMeta[idx]?.borderColor}00`}
          >
            {column.highlighted && <div
              className="absolute top-0 h-full bg-black-alpha-20 border-left-1 border-right-1 border-gray-500"
              style={{left: '-1px', right: '-1px'}}></div>}
          </div>
        )}
      </div>
    </div>
  </>;
};

export default TimelineContent;
