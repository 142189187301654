import React from 'react';
import Icon, { IconProps } from './planning21-icon';
import { Tooltip } from "primereact/tooltip";

interface IconWithTooltipProps extends IconProps {
 text: string;
 id: string;
}

const IconWithTooltip = (props: IconWithTooltipProps) => {
  return <div>
    <Tooltip target={'#'+props.id} content={props.text} />
    <Icon {...props} id={props.id}/>
  </div>;
};

export default IconWithTooltip;
